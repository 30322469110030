<!-- 美妆活动页 -->
<template>

  <div class="activity_body">
    <img class="head_top1" :src="$imgUrl + '/static/i/2023/08/16/5503526373232218630.jpg'" @click="toUrl('/goodsList?catagoryId=45')" />
    <img class="head_top1" :src="$imgUrl + '/static/hd_img/02.gif'" />
    <!-- 店铺导航 -->
    <div class="nav_top_body">
      <div class="nav_head">
        <img v-lazy="$imgUrl +'/static/web_img/activity/1.png'" >
      </div>
      <div class="nav2">
        <div class="nav_img">
          <img v-lazy="$imgUrl + imgUrlList[0].img_url" @click="toUrl(imgUrlList[0].to_url)"/>
          <img v-lazy="$imgUrl + imgUrlList[1].img_url" @click="toUrl(imgUrlList[1].to_url)"/>
        </div>
      </div>
      <div class="nav3">
        <div class="nav_img">
          <img v-lazy="$imgUrl + imgUrlList[2].img_url" @click="toUrl(imgUrlList[2].to_url)" />
          <img :src="$imgUrl + imgUrlList[3].img_url" @click="toUrl(imgUrlList[3].to_url)" />
        </div>
        <div class="nav_img">
          <img v-lazy="$imgUrl + imgUrlList[4].img_url" @click="toUrl(imgUrlList[4].to_url)" />
          <img v-lazy="$imgUrl + imgUrlList[5].img_url" @click="toUrl(imgUrlList[5].to_url)" />
        </div>
      </div>
    </div>
    <!-- 分类 -->
    <div class="nav_ico">
      <div class="ico_body" v-for="item in imgUrlList" v-if="item.type==1.2" @click="toUrl(item.category_id)">
        <div class="ico_left">
          <img v-lazy="$imgUrl + item.img_url" />
        </div>
        <div class="ico_right">
          <div class="text1">{{item.text1[0]}}</div>
          <div class="text2">{{item.text1[1]}}</div>
        </div>
      </div>
    </div>
    <!-- 新品尝鲜 专属礼遇 -->
    <div class="new_head">
      <img  class="top_img" src="https://static.ripinhui.shop/static/i/2023/08/21/5505341830448812300.png" alt="">
        <!-- <div class="text1">
            新品尝鲜 专属礼遇
        </div>
        <div class="text2">
            新製品は新鮮な専属礼遇を味わう
        </div> -->
        <div class="tab_btn">
            <van-tabs type="card">
            <van-tab title="新品预售">
              <div class="goods_body">
                <div class="goods_info" v-for="(item,index) in goodsList.xinpin[1]" @click="toGoods(item.goods_id)">
                  <img v-lazy="$imgUrl +item.img_url" />
                  <div class="hx"></div>
                  <div class="money_body">
                    <div class="money_left">
                    <div>￥</div>
                    <div class="money_text">到手价</div>
                    </div>
                    <div class="money_right">
                      <div class="integer">{{item.price.integer}}</div>
                      <div class="decimal" v-if="item.price.decimal!=-1">{{item.price.decimal}}</div>
                    </div>
                  </div>
                </div>
              </div>
              
            </van-tab>
            <van-tab title="限 定 款">
              <div class="goods_body">
                <div class="goods_info" v-for="(item,index) in goodsList.xinpin[2]" @click="toGoods(item.goods_id)">
                  <img v-lazy="$imgUrl +item.img_url" />
                  <div class="hx"></div>
                  <div class="money_body">
                    <div class="money_left">
                    <div>￥</div>
                    <div class="money_text">到手价</div>
                    </div>
                    <div class="money_right">
                      <div class="integer">{{item.price.integer}}</div>
                      <div class="decimal" v-if="item.price.decimal!=-1">{{item.price.decimal}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </van-tab>
            <van-tab title="套 装 款">
              <div class="goods_body">
                <div class="goods_info" v-for="(item,index) in goodsList.xinpin[3]" @click="toGoods(item.goods_id)">
                  <img v-lazy="$imgUrl +item.img_url" />
                  <div class="hx"></div>
                  <div class="money_body">
                    <div class="money_left">
                    <div>￥</div>
                    <div class="money_text">到手价</div>
                    </div>
                    <div class="money_right">
                      <div class="integer">{{item.price.integer}}</div>
                      <div class="decimal" v-if="item.price.decimal!=-1">{{item.price.decimal}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </van-tab>
            </van-tabs>
        </div>
    </div>
    <!-- 好物推荐 -->
    <div class="hdtj_head">
      <img :src="$imgUrl + '/static/hd_img/06hwtj.jpg'" />
    </div>
    <div class="hdtj">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" >
        <van-swipe-item v-for="(item2,index) in hwtj_bg">
          <div class="banner_bg" :style="item2" @click="toGoods(item2.goods_id)">
            <div class="price" :style="clzb_price">
              <div class="price_text">
                {{item2.integer}}
                <div v-if="item2.decimal!=-1" class="decimal">{{item2.decimal}}</div>
              </div>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 明星大牌 -->
    <div class="brand">
        <img class="brand_top_img" src="https://static.ripinhui.shop/static/i/2023/08/21/5505346136249996972.png" alt="">
        <div class="brand_logo">
            <div class="logo_list" @click="btnBrandLogo(1)">
                <img v-if="logo_click_id!=1" :src="$imgUrl + '/static/web_img/activity/bradn_logo01.png'" alt="">
                <img v-if="logo_click_id==1" :src="$imgUrl + '/static/web_img/activity/bradn_logo07.png'" alt="">
              
            </div>
            <div class="logo_list" @click="btnBrandLogo(2)">
                <img v-if="logo_click_id!=2" :src="$imgUrl + '/static/web_img/activity/bradn_logo02.png'" alt="">
                <img v-if="logo_click_id==2" :src="$imgUrl + '/static/web_img/activity/bradn_logo08.png'" alt="">
            </div>
            <div class="logo_list" @click="btnBrandLogo(3)">
                <img v-if="logo_click_id!=3" :src="$imgUrl + '/static/web_img/activity/bradn_logo03.png'" alt="">
                <img v-if="logo_click_id==3" :src="$imgUrl + '/static/web_img/activity/bradn_logo09.png'" alt="">
            </div>
            <div class="logo_list" @click="btnBrandLogo(4)">
                <img v-if="logo_click_id!=4" :src="$imgUrl + '/static/web_img/activity/bradn_logo04.png'" alt="">
                <img v-if="logo_click_id==4" :src="$imgUrl + '/static/web_img/activity/bradn_logo10.png'" alt="">
            </div>
            <div class="logo_list" @click="btnBrandLogo(5)">
                <img v-if="logo_click_id!=5" :src="$imgUrl + '/static/web_img/activity/bradn_logo05.png'" alt="">
                <img v-if="logo_click_id==5" :src="$imgUrl + '/static/web_img/activity/bradn_logo11.png'" alt="">
            </div>
            <div class="logo_list" @click="btnBrandLogo(6)">
                <img v-if="logo_click_id!=6" :src="$imgUrl + '/static/web_img/activity/bradn_logo06.png'" alt="">
                <img v-if="logo_click_id==6" :src="$imgUrl + '/static/web_img/activity/bradn_logo12.png'" alt="">
            </div>
        </div>
       
    </div>
     <div class="brand_goods" :style="brandBg">
        <div class="brand_goods_list">
        <div class="goods_border">
            <div class="goods_body" v-for="(item,index) in brandGoodsList" @click="toGoods(item.id)">
                <div class="goods_img">
                    <img v-lazy="$imgUrl +item.img_url" >
                </div>
                <div class="goods_title" >
                    {{item.title}}
                </div>
                <div class="divider"></div>
                <div class="price">
                    <div class="price_left">
                        <div class="price_ico">￥</div>
                        <div >到手价</div>
                    </div>
                    <div class="money">
                        <div>{{item.price.integer}}</div>
                        <div class="decimal">{{item.price.decimal}}</div>
                    </div>
                </div>
            </div>


        </div>
        <div class="brand_more">
            ···· 滑动查看更多 ····
        </div>
           
        </div>
    </div>
    <!-- 明星大牌结束 -->

    <!-- 彩妆顶部 -->
    <div class="cz">
        <div class="cz_head">
            <img v-lazy="$imgUrl + '/static/hd_img/09cz.jpg'" />
        </div>
        <!-- 彩妆轮播 -->
        <div class="hdtj">
          <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
            <van-swipe-item v-for="(item2,index) in czlb_bg">
          <div class="banner_bg" :style="item2" @click="toGoods(item2.goods_id)">
            <div class="price" :style="clzb_price">
              <div class="price_text">
                {{item2.integer}}
                <div v-if="item2.decimal!=-1" class="decimal">{{item2.decimal}}</div>
              </div>
            </div>
          </div>
        </van-swipe-item>


          </van-swipe>
        </div>
    </div>
    
    <!-- 彩妆结束 -->
    <!-- 彩妆推荐底部分类 -->
    <div class="nav_ico">
      <div class="ico_body" v-for="item in imgUrlList" v-if="item.type==4.2" @click="toUrl(item.category_id)">
        <div class="ico_left">
          <img v-lazy="$imgUrl + item.img_url" />
        </div>
        <div class="ico_right">
          <div class="text1">{{item.text1[0]}}</div>
          <div class="text2">{{item.text1[1]}}</div>
        </div>
      </div>


    </div>
    <!-- 彩妆商品列表 -->
    <div class="goods_list_body">
      <img class="goods_list_top_img" src="https://static.ripinhui.shop/static/i/2023/09/20/5516253196319726910.png" alt="">
      <div class="goods_list" >
        <div class="goods_body" v-if="index % 2 == 0" v-for="(item,index) in  goodsList.caizhuang" @click="toGoods(item.id)">
          <img
            class="goods_img"
            v-lazy="$imgUrl+item.img_url"
          />
          <div class="goods_title">
            {{item.title}}
          </div>
          <div class="price_body">
            <div class="price_text1">
              <div class="money_ico">￥</div>
              <div class="money_title">到手价</div>
            </div>
            <div class="price">{{item.price.integer}}<div class="decimal" v-if="item.price.decimal!=-1">{{item.price.decimal}}</div></div>
            <div class="border"></div>
            <div class="price_link">点击购买></div>
          </div>
        </div>

        <div class="goods_body3">
          <div class="goods_body " v-if="index % 2 != 0" v-for="(item,index) in  goodsList.caizhuang" @click="toGoods(item.id)">
          <img
            class="goods_img"
            v-lazy="$imgUrl+item.img_url"
          />
          <div class="goods_title">
            {{item.title}}
          </div>
          <div class="price_body" >
            <div class="price_text1">
              <div class="money_ico">￥</div>
              <div class="money_title">到手价</div>
            </div>
            <div class="price">{{item.price.integer}}  <div class="decimal" v-if="item.price.decimal!=-1">{{item.price.decimal}}</div></div>
           
            <div class="border"></div>
            <div class="price_link">点击购买></div>
          </div>
        </div>
        </div>
 

      </div>
      <img class="more" @click="toUrl(47)" src="https://static.ripinhui.shop/static/i/2023/08/27/5507607658829452904.gif" alt="">
      <!-- <div class="more" @click="toUrl(47)">···· 逛逛更多宝贝 ····</div> -->
    </div>
    <!-- 彩妆商品列表结束 -->
    <!-- 护肤 -->
    <div class="hf" >
        <div class="hf_head">
            <img :src="$imgUrl + '/static/hd_img/13hufu.jpg'" />
        </div>
        <!-- 护肤大图 -->
        <div class="hf_hd_img" v-for="(item,index) in imgUrlList" v-if="item.type==5.1" @click="toGoods(item.goods_id)">
            <img :src="$imgUrl+item.img_url" alt="" >
            <div class="hf_hd_bottom">
                <div class="left_text">
                    日本直邮到手价：
                </div>
                <div class="mone_ico">
                    ￥
                </div>
                <div class="price_text">
                    {{item.price.integer}}
                    <div class="decimal" v-if="item.price.decimal!=-1">{{item.price.decimal}}</div>
                </div>
                <div class="mone_ico2">
                    |
                </div>
                <div class="to_link">
                    点击购买>
                </div>
            </div>
        </div>
    </div>
    <!-- 护肤商品列表 -->
        <div class="goods_list_body">
          <img class="goods_list_top_img" src="https://static.ripinhui.shop/static/i/2023/09/20/5516254180739653183.png" alt="">
        <div class="goods_list" >
        <div class="goods_body" v-if="index % 2 == 0" v-for="(item,index) in  goodsList.hufuGoodsList" @click="toGoods(item.id)">
          <img
            class="goods_img"
            v-lazy="$imgUrl+item.img_url"
          />
          <div class="goods_title">
            {{item.title}}
          </div>
          <div class="price_body">
            <div class="price_text1">
              <div class="money_ico">￥</div>
              <div class="money_title">到手价</div>
            </div>
            <div class="price">{{item.price.integer}}<div class="decimal" v-if="item.price.decimal!=-1">{{item.price.decimal}}</div></div>
            <div class="border"></div>
            <div class="price_link">点击购买></div>
          </div>
        </div>

        <div class="goods_body3">
          <div class="goods_body " v-if="index % 2 != 0" v-for="(item,index) in  goodsList.caizhuang" @click="toGoods(item.id)">
          <img
            class="goods_img"
            v-lazy="$imgUrl+item.img_url"
          />
          <div class="goods_title">
            {{item.title}}
          </div>
          <div class="price_body" >
            <div class="price_text1">
              <div class="money_ico">￥</div>
              <div class="money_title">到手价</div>
            </div>
            <div class="price">{{item.price.integer}}<div class="decimal" v-if="item.price.decimal!=-1">{{item.price.decimal}}</div></div>
            <div class="border"></div>
            <div class="price_link">点击购买></div>
          </div>
        </div>
        </div>
 

      </div>
      <img class="more" @click="toUrl(46)" src="https://static.ripinhui.shop/static/i/2023/08/27/5507607658829452904.gif" alt="">
      <!-- <div class="more" @click="toUrl(46)">···· 逛逛更多宝贝 ····</div> -->
    </div>
    <!-- 护肤商品列表结束 -->
    <!-- 护肤模块结束 -->
    <!-- 面膜 -->
    <div class="mianmo">
        <div class="mianmo_head">
            <img v-lazy="$imgUrl + '/static/hd_img/16mianmo.jpg'" />
        </div>
        <!-- 面膜大图 -->
        <div class="hf_hd_img" v-for="(item,index) in imgUrlList" v-if="item.type==6.1" @click="toGoods(item.goods_id)">
            <img v-lazy="$imgUrl+item.img_url" alt="" >
            <div class="hf_hd_bottom">
                <div class="left_text">
                    日本直邮到手价：
                </div>
                <div class="mone_ico">
                    ￥
                </div>
                <div class="price_text">
                    {{item.price.integer}}
                    <div class="decimal" v-if="item.price.decimal!=-1">{{item.price.decimal}}</div>
                </div>
                <div class="mone_ico2">
                    |
                </div>
                <div class="to_link">
                    点击购买>
                </div>
            </div>
        </div>
          <div class="tab_btn mianmotab">
            <van-tabs type="card" >
            <van-tab title="C O C O C H I">
              <div class="mianmo_content">
                 <img class="head_img" src="https://static.ripinhui.shop/static/i/2023/08/23/5506042919401824098.jpg" >
                 <div class="goods">
                  <div class="goods_body" v-for="(item,index) in goodsList.ppmm[0]" @click="toGoods(item.id)">
                      <img v-lazy="$imgUrl+item.img_url" alt="">
                      <div class="goods_title">{{item.title}}</div>
                      <div class="goods_hx"></div>
                      <div class="goods_bottom">
                        <div class="price_left">
                          <div>￥</div>
                          <div>到手价</div>
                        </div>
                        <div class="price_right">
                          <div>{{item.price.integer}}</div>
                          <div class="decimal">{{item.price.decimal}}</div>
                        </div>
                      </div>
                  </div>
                
                  
                 </div>
              </div>
             
              
            </van-tab>
            <van-tab title="M E T A T R O N">
              <template #title> <img class="tab2_img" src="https://static.ripinhui.shop/static/web_img/activity/LOGO-black2.png">
              </template>
              <div class="mianmo_content">
                 <img class="head_img" src="https://static.ripinhui.shop/static/i/2023/08/23/5506043684719695943.jpg" >
                 <div class="goods">
                  <div class="goods_body" v-for="(item,index) in goodsList.ppmm[1]" @click="toGoods(item.id)">
                      <img v-lazy="$imgUrl+item.img_url" alt="">
                      <div class="goods_title">{{item.title}}</div>
                      <div class="goods_hx"></div>
                      <div class="goods_bottom">
                        <div class="price_left">
                          <div>￥</div>
                          <div>到手价</div>
                        </div>
                        <div class="price_right">
                          <div>{{item.price.integer}}</div>
                          <div class="decimal">{{item.price.decimal}}</div>
                        </div>
                      </div>
                  </div>
                 
                 
                 </div>
              </div>
            </van-tab>
            </van-tabs>
          
            
           
        </div>
    </div>
      <!-- 面膜瀑布流列表 -->
        <div class="goods_list_body">
          <img class="goods_list_top_img" src="https://static.ripinhui.shop/static/i/2023/09/20/5516254598672683380.png" alt="">
          <div class="goods_list" >
          <div class="goods_body" v-if="index % 2 == 0" v-for="(item,index) in  goodsList.mianmoGoodsList" @click="toGoods(item.id)">
            <img
              class="goods_img"
              v-lazy="$imgUrl+item.img_url"
            />
             <div class="goods_title">
            {{item.title}}
            </div>
            <div class="price_body">
              <div class="price_text1">
                <div class="money_ico">￥</div>
                <div class="money_title">到手价</div>
              </div>
              <div class="price">{{item.price.integer}}<div class="decimal" v-if="item.price.decimal!=-1">{{item.price.decimal}}</div></div>
            <div class="border"></div>
              <div class="price_link">点击购买></div>
            </div>
          </div>

          <div class="goods_body3">
          <div class="goods_body " v-if="index % 2 != 0" v-for="(item,index) in  goodsList.mianmoGoodsList" @click="toGoods(item.id)">
          <img
            class="goods_img"
            v-lazy="$imgUrl+item.img_url"
          />
           <div class="goods_title">
            {{item.title}}
          </div>
          <div class="price_body" >
            <div class="price_text1">
              <div class="money_ico">￥</div>
              <div class="money_title">到手价</div>
            </div>
            <div class="price">{{item.price.integer}}<div class="decimal" v-if="item.price.decimal!=-1">{{item.price.decimal}}</div></div>
            <div class="border"></div>
            <div class="price_link">点击购买></div>
          </div>
        </div>
        </div>
        </div>
        <img class="more" @click="toUrl(285)" src="https://static.ripinhui.shop/static/i/2023/08/27/5507607658829452904.gif" alt="">
      <!-- <div class="more" @click="toUrl(285)">···· 逛逛更多宝贝 ····</div> -->
    </div>
     <!-- 面膜瀑布流结束 -->
    <!-- 面膜模块结束 -->
    <!-- 美容仪 -->
    <div class="mry">
        <img class="head_img" v-lazy="$imgUrl + '/static/hd_img/20mry.jpg'" />
        <!-- 美容仪大图 -->
        <div class="hf_hd_img" v-for="(item,index) in imgUrlList" v-if="item.type==7.1" @click="toGoods(item.goods_id)">
            <img v-lazy="$imgUrl+item.img_url" alt="" >
            <div class="hf_hd_bottom">
                <div class="left_text">
                    日本直邮到手价：
                </div>
                <div class="mone_ico">
                    ￥
                </div>
                <div class="price_text">
                    {{item.price.integer}}
                    <div class="decimal" v-if="item.price.decimal!=-1">{{item.price.decimal}}</div>
                </div>
                <div class="mone_ico2">
                    |
                </div>
                <div class="to_link">
                    点击购买>
                </div>
            </div>
        </div>
        <!-- 美容仪瀑布流 -->
      <div class="goods_list_body">
        <img class="goods_list_top_img" src="https://static.ripinhui.shop/static/i/2023/09/20/5516254896208222779.png" alt="">
          <div class="goods_list" >
          <div class="goods_body" v-if="index % 2 == 0" v-for="(item,index) in  goodsList.meirongyiGoodsList" @click="toGoods(item.id)">
            <img
              class="goods_img"
              v-lazy="$imgUrl+item.img_url"
            />
             <div class="goods_title">
            {{item.title}}
            </div>
            <div class="price_body">
              <div class="price_text1">
                <div class="money_ico">￥</div>
                <div class="money_title">到手价</div>
              </div>
              <div class="price">{{item.price.integer}}<div class="decimal" v-if="item.price.decimal!=-1">{{item.price.decimal}}</div></div>
            <div class="border"></div>
              <div class="price_link">点击购买></div>
            </div>
          </div>

          <div class="goods_body3">
          <div class="goods_body " v-if="index % 2 != 0" v-for="(item,index) in  goodsList.meirongyiGoodsList" @click="toGoods(item.id)">
          <img
            class="goods_img"
            v-lazy="$imgUrl+item.img_url"
          />
           <div class="goods_title">
            {{item.title}}
          </div>
          <div class="price_body" >
            <div class="price_text1">
              <div class="money_ico">￥</div>
              <div class="money_title">到手价</div>
            </div>
            <div class="price">{{item.price.integer}}<div class="decimal" v-if="item.price.decimal!=-1">{{item.price.decimal}}</div></div>
            <div class="border"></div>
            <div class="price_link">点击购买></div>
          </div>
        </div>
        </div>
        </div>
        <img class="more" @click="toUrl(299)" src="https://static.ripinhui.shop/static/i/2023/08/27/5507607658829452904.gif" alt="">
      <!-- <div class="more" @click="toUrl(299)">···· 逛逛更多宝贝 ····</div> -->
    </div>
    <!-- 美容仪瀑布流结束 -->
    </div>
    <!-- 美容仪结束 -->
    <!-- 旅行套装 -->
    <div class="lxtz">
        <img class="head_img" src="https://static.ripinhui.shop/static/i/2023/08/16/5503489433606820732.png" alt="">
        <div class="goods">
            <div class="goods_left">
                <div class="left_img_thumbnail" v-for="(item,index) in goodsList.lxtz">
                  <img @click="lxtzClick(index)" v-if="lxtzSelectIndex!=index"  v-lazy="$imgUrl+item.thumbnail" alt="">
                  <img @click="lxtzClick(index)" v-if="lxtzSelectIndex==index" class="img_select" v-lazy="$imgUrl+item.selectThumbnail" alt="">
                  </div>
        

            </div>
            <div class="goods_right" @click="toGoods(goodsList.lxtz[lxtzSelectIndex].goodsId)">
              <img v-lazy="$imgUrl+lxtzBgImg" alt="">
              <div class="price">
                  <span class="price_logo">￥</span>
                  <span>{{goodsList.lxtz[lxtzSelectIndex].price.integer}}</span>
                <span class="decimal">{{goodsList.lxtz[lxtzSelectIndex].price.decimal}}</span>
                </div>
            </div>
        </div>
    </div>
    <!-- 旅行套装结束 -->
    <!-- 日常护理 -->
    <div class="rchl">
      <!-- 日常护理顶部 -->
      <img class="head_img" v-lazy="$imgUrl + '/static/hd_img/24rchl.jpg'" />
        <!-- 日常护理大图 -->
          <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white"   >
            <van-swipe-item v-for="(item2,index) in rchl_bg">
              <div class="banner_bg" :style="item2" @click="toGoods(item2.goods_id)">
                <div class="price" :style="rchl_prcie">
                  
                  <div class="price_text">
                    <div class="rmb_ico">￥</div>
                    {{item2.integer}}
                <div v-if="item2.decimal!=-1" class="decimal">{{item2.decimal}}</div>
                    </div>
                </div>
              </div>
            </van-swipe-item>

          </van-swipe>
      <!-- 日常护理瀑布流 -->
  <div class="goods_list_body">
    <img class="goods_list_top_img" src="https://static.ripinhui.shop/static/i/2023/09/20/5516255038650982266.png" alt="">
      <div class="goods_list" >
          <div class="goods_body" v-if="index % 2 == 0" v-for="(item,index) in  goodsList.rchlGoodsList" @click="toGoods(item.id)">
            <img
              class="goods_img"
              v-lazy="$imgUrl+item.img_url"
            />
            <div class="price_body">
              <div class="price_text1">
                <div class="money_ico">￥</div>
                <div class="money_title">到手价</div>
              </div>
              <div class="price">{{item.price.integer}}<div class="decimal" v-if="item.price.decimal!=-1">{{item.price.decimal}}</div></div>
            <div class="border"></div>
              <div class="price_link">点击购买></div>
            </div>
          </div>

          <div class="goods_body3">
          <div class="goods_body " v-if="index % 2 != 0" v-for="(item,index) in  goodsList.caizhuang" @click="toGoods(item.id)">
          <img
            class="goods_img"
            v-lazy="$imgUrl+item.img_url"
          />
          <div class="price_body" >
            <div class="price_text1">
              <div class="money_ico">￥</div>
              <div class="money_title">到手价</div>
            </div>
            <div class="price">{{item.price.integer}}<div class="decimal" v-if="item.price.decimal!=-1">{{item.price.decimal}}</div></div>
            <div class="border"></div>
            <div class="price_link">点击购买></div>
          </div>
        </div>
        </div>
        </div>
      <img class="more" @click="toUrl(286)" src="https://static.ripinhui.shop/static/i/2023/08/27/5507607658829452904.gif" alt="">
      <!-- <div class="more" @click="toUrl(286)">···· 逛逛更多宝贝 ····</div> -->
    </div>
       <!-- 日常护理瀑布流结束 -->
       <!-- 底部商品列表 -->
       <div class="goods_list_body">
         <img class="goods_list_top_img" src="https://static.ripinhui.shop/static/i/2023/10/08/5522834893081415871.png" alt="">
        <div class="goods_list" >
          <van-list
              v-model="loading"
              :finished="finished"
              offset="100"
              :immediate-check="false"
              @load="onLoad"
              >
            <div class="goods_body" v-if="index % 2 == 0" v-for="(item,index) in  bottomGoodsListAll" @click="toGoods(item.id)">
              <img
                class="goods_img"
                v-lazy="$imgUrl+item.img_url"
              />
              <div class="price_body">
                <div class="price_text1">
                  <div class="money_ico">￥</div>
                  <div class="money_title">到手价</div>
                </div>
                <div class="price">{{item.price.integer}}<div class="decimal" v-if="item.price.decimal!=-1">{{item.price.decimal}}</div></div>
            <div class="border"></div>
                <div class="price_link">点击购买></div>
              </div>
            </div>

            <div class="goods_body3">
              <div class="goods_body " v-if="index % 2 != 0" v-for="(item,index) in bottomGoodsListAll" @click="toGoods(item.id)">
              <img
                class="goods_img"
                v-lazy="$imgUrl+item.img_url"
              />
              <div class="price_body" >
                <div class="price_text1">
                  <div class="money_ico">￥</div>
                  <div class="money_title">到手价</div>
                </div>
                <div class="price">{{item.price.integer}}<div class="decimal" v-if="item.price.decimal!=-1">{{item.price.decimal}}</div></div>
            <div class="border"></div>
                <div class="price_link">点击购买></div>
              </div>
            </div>
            </div>
            </van-list>
          </div>

        </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      clzb_price: {
        backgroundImage:
          "url(https://static.ripinhui.shop/static/i/2023/08/21/5505346604070083163.png)",
          
      },
      rchl_bg:[],
      rchl_prcie:{
        backgroundImage:
          "url(https://static.ripinhui.shop/static/web_img/activity/activity_07.png)",
      },
      // 好物推荐轮播背景图
      hwtj_bg:[],
      // 彩妆轮播 
      czlb_bg:[],
      brand_cover_img_url:'https://api.ripinhui.shop/static/hd_img/brand_bg/1.jpg',
      logo_click_id:1, //大牌logo选中id
 
      lxtzSelectIndex:0, //旅行套装选中ID
      lxtzBgImg:'',//旅行套装选中大图
      imgUrlList:[], //全部图片列表
      goodsList:[], //全部商品列表
      loading: false,//设置为 true，表示分页处于加载状态
      finished: false,//	是否已加载完成，加载完成后不再触发load事件
      page: 1, //页码
      bottomGoodsListAll:[], //
      brandGoodsList:[] //品牌商品列表
    };
  },

  created() {
    this.getImgUrl()
    this.getGoodsList()
    this.getALLgoodsList()
    this.getBrandGoodsList()
  },

  methods: {
    onLoad() {
            this.loading = true;
            this.getALLgoodsList()
    },
    // 获取品牌列表
    getBrandGoodsList()
    {
      let res = {
                brand_id: this.logo_click_id,
                token: this.$store.state.token
            }
      let that = this
      that.$request('/api/brandGoodsList', res)
          .then(res => {
              console.log(res.data.data)
              this.brandGoodsList = res.data.data

          })
    },
    getALLgoodsList()
    {
      let res = {
                page: this.page ,
                token: this.$store.state.token
            }
      let that = this
      that.$request('/api/hd1/getALLgoodsList', res)
          .then(res => {
            // console.log(res.data.data,'===')
            //         return
             this.loading = false;
                if (Object.keys(that.bottomGoodsListAll).length===0) {
                    that.bottomGoodsListAll = res.data.data
                }else{
                    that.bottomGoodsListAll = that.bottomGoodsListAll.concat(res.data.data)
                }
                if(res.data.data.length==0){
                    this.finished = true
                }
                that.page = that.page+1

          })
    },
    toGoods(id){
      this.$router.push('/goods?goodsId='+id);
    },
    toUrl(id){
      this.$router.push('/goodsList?catagoryId='+id);
    },
    lxtzClick(index){
      this.lxtzSelectIndex = index
      // this.lxtzBgImg = this.lxtzGoodsList[index].bgImg
      this.lxtzBgImg = this.goodsList.lxtz[index].bgImg
    },
    btnBrandLogo(id){
        this.logo_click_id = id
        this.brand_cover_img_url = "https://api.ripinhui.shop/static/hd_img/brand_bg/"+id+".jpg"
        this.getBrandGoodsList()
    },
    getImgUrl(){
      let that = this
      that.$request('/api/hd1/getImgUrl', { token: this.$store.state.token })
          .then(res => {
              this.imgUrlList = res.data.data
              let hwtj_bg_arr = []
              let czlb_bg_arr = []
              let rchl_bg_arr = []
              this.imgUrlList.forEach((item,index)=>{
                // 3好物推荐轮播 4.1彩妆轮播url
                if(item['type']==3){
                  var newBg2Data = {
                      backgroundImage: 'url(' + this.$imgUrl+item.img_url + ')',
                      integer:item.price.integer,
                      decimal:item.price.decimal,
                      goods_id:item.goods_id
                  };
                  hwtj_bg_arr.push(newBg2Data)
                  this.hwtj_bg = hwtj_bg_arr
                }
                if(item['type']==4.1){
                  var newBg2Data = {
                      backgroundImage: 'url(' + this.$imgUrl+item.img_url + ')',
                      integer:item.price.integer,
                      decimal:item.price.decimal,
                      goods_id:item.goods_id
                  };
                  czlb_bg_arr.push(newBg2Data)
                    this.czlb_bg = czlb_bg_arr
                }
                if(item['type']==9.1){
                  var newBg2Data = {
                      backgroundImage: 'url(' + this.$imgUrl+item.img_url + ')',
                      integer:item.price.integer,
                      decimal:item.price.decimal,
                      goods_id:item.goods_id
                  };
                  rchl_bg_arr.push(newBg2Data)
                    this.rchl_bg = rchl_bg_arr
                }
              })
          })
    },
    getGoodsList(){
      let that = this
      that.$request('/api/hd1/getGoodsList', { token: this.$store.state.token })
          .then(res => {
              this.goodsList = res.data.data
              // console.log(res.data.data.lxtz[0].bgImg,'+++++')
              this.lxtzBgImg = res.data.data.lxtz[0].bgImg
          })
    },
  },
  computed:{
    brandBg(){
        return {
            backgroundImage: 'url(' + this.brand_cover_img_url + ')'
        }
    }
  }
};
</script>
<style src="./activity1.css" scoped>
</style>

<style  scoped>
@font-face {
  font-family: 'AlibabaPuHuiTi';
  src: url('https://static.ripinhui.shop/static/font/AlibabaPuHuiTi-2-75-SemiBold.otf') format('truetype');

}
/deep/ img {
  font-size: 0px;
  vertical-align: top;
}
/deep/ p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
/deep/ .new_head .van-tabs__nav {
  background: linear-gradient(135deg, #fee9cf, #fbcb9f);
  border-radius: 30px;
}
/deep/ .new_head .van-tab {
  border-radius: 30px;
}
/deep/ .new_head .van-tabs__nav--card .van-tab {
  color: #562900;
}
/deep/ .new_head .van-tabs__nav--card .van-tab.van-tab--active {
  color: #fbcea5;
  background: linear-gradient(135deg, #ce2c2c, #a31d1c);
  border: none;
  font-family: AlibabaPuHuiTi;
  font-weight: 800;
}
/deep/ .new_head .van-tabs__nav--card .van-tab{
  border-right:none;
}
/deep/ .new_head .van-tab__text--ellipsis{
  font-size: 31px;
  color: #562900;
  font-family: AlibabaPuHuiTi;
  font-weight: 600;
}
/deep/ .new_head .van-tab--active .van-tab__text--ellipsis{
  font-size: 34px;
  font-weight: bold;
  background-image: linear-gradient(-64deg, #FBCBA0 0%, #FEE7CD 99.6337890625%);
	color: transparent;
	-webkit-background-clip: text;
  font-family: AlibabaPuHuiTi;
}
/deep/ .new_head .van-tabs__nav--card{
  border:0.5px solid #FCD3AB;
}




/deep/ .mianmo .van-tabs__nav {
  background: linear-gradient(135deg, #fee9cf, #fbcb9f);
  border-radius: 30px;
}
/deep/ .mianmo .van-tab {
  border-radius: 30px;
}
/deep/ .mianmo .van-tabs__nav--card .van-tab {
  color: #562900;
}
/deep/ .mianmo .van-tabs__nav--card .van-tab.van-tab--active {
  color: #fbcea5;
  background: linear-gradient(135deg, #ce2c2c, #a31d1c);
  border: none;
  font-family: AlibabaPuHuiTi;
  font-weight: 800;
}
/deep/ .mianmo .van-tabs__nav--card .van-tab{
  border-right:none;
}
/deep/ .mianmo .van-tab__text--ellipsis{
  font-size: 31px;
  color: #562900;
  font-family: AlibabaPuHuiTi;
  font-weight: 600;
}
/deep/ .mianmo .van-tab--active .van-tab__text--ellipsis{
  font-size: 34px;
  font-weight: bold;
  background-image: linear-gradient(-64deg, #FBCBA0 0%, #FEE7CD 99.6337890625%);
	color: transparent;
	-webkit-background-clip: text;
}
/deep/ .mianmo .van-tabs__nav--card{
  border:0.5px solid #FCD3AB;
}
</style>