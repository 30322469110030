<template>
    <div class="calculator">
        <head-top title="汇率计算" left_ico="1"></head-top>
        <div class="tool_body">
             <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
            非实时汇率，计算汇率仅供参考
            </van-notice-bar>
            <van-cell-group>
                <van-field class="border_radius" v-model="money" label="兑换金额：" placeholder="请输入兑换金额" />
            </van-cell-group>
            <van-cell-group>
                <van-field v-model="value1Name" readonly label="持有货币：" @click="selectCurrency(1)" placeholder="点击此处选择货币类型" />
            </van-cell-group>
            <van-cell-group>
                <van-field v-model="value2Name" readonly label="兑换货币：" @click="selectCurrency(2)" placeholder="点击此处选择货币类型" />
            </van-cell-group>
            <van-cell-group>
                <van-field v-model="result" readonly label="计算结果：" />
            </van-cell-group>
            <van-button type="primary" block color="#4c83ff" @click="jpyToCny">计算</van-button>
        </div>
        <div class="explain">
           汇率计算说明：
        </div>
        <van-popup v-model="show" position="bottom" :style="{ height: '42%' }">
            <van-picker title="标题" show-toolbar :columns="columns" @confirm="onConfirm" @cancel="onCancel"  />
        </van-popup>
        
    </div>
</template>

<script>
import HeadTop from "@/components/HeadTop.vue";
export default {
    name: "calculator",
    components: {
        HeadTop,
    },
    data() {
        return {
            money: "",//输入金额
            value1: '',//选项1
            value2: '',//选项2
            value1Name:'',
            value2Name:'',
            show: false,
            columns: ['人民币(CNY)', '日元(JPY)'],
            type:1,
            result:''//计算结果
        };
    },
    methods: {
        selectCurrency(type) {
            this.show = true;
            this.type =type
        },
        onConfirm(value, index) {
            // let key = 'value'+this.tpye
            if(this.type==1){
                if(index==0){
                    this.value1 = 'CNY'
                }
                if(index==1){
                    this.value1 = 'JPY'
                }
                this.value1Name = value 
            }
            if(this.type==2){
                if(index==0){
                    this.value2 = 'CNY'
                }
                if(index==1){
                    this.value2 = 'JPY'
                }
                this.value2Name = value 
            }
            this.show = false
            // this.$toast(`当前值：${value}, 当前索引：${index}`);
        },
        onCancel() {
            this.show = false
            // this.$toast('取消');
        },
        jpyToCny(){
            if(this.value1==this.value2){
                this.$toast('请正确选择货币类型')
                return;
            }
            // let data ={
            //     'money':this.money,
            //     'value1':this.value1,
            //     'value2':this.value2,
            // }
            this.$request('/api/jpyToCny', {
                'money':this.money,
                'value1':this.value1,
                'value2':this.value2,
            })
                .then(res => {
                    this.result = res.data.data.money
                })
        }
    }
};
</script>

<style src="./calculator.css" scoped>
</style>
<style scoped>
/deep/.van-field__control {
    height: 90px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    opacity: 0.9;
    border: 1px solid #dddddd;
    padding-left: 20px;
    font-size: 30px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #000000;
}
/deep/.van-cell__title {
    line-height: 90px;
    font-size: 30px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #000000;
}
/deep/.van-button {
    width: 91%;
    margin: 0 auto;
}
</style>