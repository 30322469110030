<template>
    <div>
        <head-top title="订单中心" left_ico="1" url="/my"></head-top>
        <van-tabs v-model="tabActive" background="#f5f5f5" title-active-color="#4c83ff" title-inactive-color="#000000" color="#4c83ff" class="vant_tabs" @click="tabBtn">
            <van-tab :title="item.lable" v-for="(item,index) in tabArr">
                <van-list
                            v-model="loading"
                            :finished="finished"
                            :immediate-check="false"
                            finished-text="没有更多了"
                            @load="onLoad"
                            >
                <div style="margin: 0 auto;" v-for="(item,index) in orderList.data">
                    <div class="order">
                        <div class="order_head">
                        <span class="time">{{item.create_time}}</span>
                        <span class="text" v-if="item.order_status==1">线下付款，未支付</span>
                        <span class="text" v-if="item.order_status==2">待发货</span>
                        <span class="text" v-if="item.order_status==3">待收货</span>
                        <span class="text" v-if="item.order_status==4">待评价</span>
                        <span class="text" v-if="item.order_status==5">已完成</span>
                        </div>
                        <order-goods-details  v-for="(item2,index2) in item.order_goods" :img="$imgUrl+item2.img_url" :price="item2.price" :title="item2.goods_title" :specs="item2.sku" :number="item2.quantity" :goods_id="item2.goods_id" />
                        <div class="total_price" >
                            <span class="quantity">共{{item.total_quantity}}件商品，总金额</span>
                            <span class="money">{{item.price}}日元<span class="money2">({{item.rmb_money}})</span></span>
                        </div>
                        <van-divider />

                        <div class="operation">
                            <van-button type="primary" size="small" color="#ca2d2d" round  class="btn" 
                            v-if="item.order_status==1" @click="orderPay(item.id,item.index)">立即支付</van-button>
                            <van-button type="primary" size="small" color="#4c83ff" round  class="btn" 
                            v-if="item.order_status==3" @click="confirmReceipt(item.number,item.index)">确认收货</van-button>
                            <van-button type="primary" size="small" color="#4c83ff" round  class="btn" 
                            v-if="item.order_status==4&&item.order_goods_count==1" @click="goodsFeedback(item,item.index,1)">评价</van-button>
                            <van-button type="primary" size="small" color="#4c83ff" round  class="btn" 
                            v-if="item.order_status==4&&item.order_goods_count>1" @click="goodsFeedback(item,item.index,2)">评价</van-button>
                            <van-button type="primary" size="small" color="#999999" 
                            round plain class="btn" @click="toDetail(item.number)">查看详情</van-button>
                        </div>
                    </div>
                </div>
                 </van-list>
                <!-- <div v-if="pageEnd" style="width:100%;margin-top:20px;color:#999;display: flex;justify-content: center;margin-bottom:20px;">
                                <div>- 没有更多了 -</div>
                        </div> -->
            </van-tab>
            <!-- <van-tab title="待发货">内容 2</van-tab>
            <van-tab title="待收货">内容 3</van-tab>
            <van-tab title="待评价">内容 4</van-tab>
            <van-tab title="已完成">内容 4</van-tab> -->
        </van-tabs>
            <van-overlay :show="btnShow" >
                <div class="wrapper">
                    <van-loading size="24px" vertical>跳转支付过程会有短暂的空白页，请耐心等待一下</van-loading>
                </div>
            </van-overlay>
    </div>

    <!-- <nav-bar /> -->
</template>

<script>
import HeadTop from "@/components/HeadTop.vue";
import NavBar from "@/components/NavBar.vue";
import OrderGoodsDetails from "@/components/OrderGoodsDetails.vue";
// import { debounce  } from '@/plugin/common.js'
export default {
    components: {
        HeadTop,
        NavBar,
        OrderGoodsDetails,
    },
    data() {
        return {
            tabActive: -2,
            tabArr: [
                // 订单状态：-1已取消1待支付2待发货3待收货4待评价5已完成
                {
                    'id': -2,
                    'lable': '全部订单'
                },
                {
                    'id': 1,
                    'lable': '待付款'
                },
                {
                    'id': 2,
                    'lable': '待发货'
                },
                {
                    'id': 3,
                    'lable': '待收货'
                },
                {
                    'id': 4,
                    'lable': '待评价'
                },
                {
                    'id': 5,
                    'lable': '已完成'
                }
            ],
            page: 1,
            orderList: {},//订单列表
            orderStatus: -2,//订单状态
            // pageEnd:false,
            req:true,
            loading: false,//设置为 true，表示分页处于加载状态
            finished: false,//	是否已加载完成，加载完成后不再触发load事件
            btnShow: false, //提交后的遮罩层
        };
    },
    
    created() {
        this.loading = true;
        
          this.token = this.$store.state.token
        // window.addEventListener('scroll', this.Scrollbottom, true);  //页面加载时监听滚动事件
        // console.log(localStorage.getItem('orderStatusChange'),'create123')
        // this.getOrderList()
        if(this.$route.query.orderStatus==-2){
            
            console.log('=-2加载')
            this.tabActive = -2
            this.getOrderList()
        } 
        if(localStorage.getItem('orderStatusChange')>=0&&this.req==true)
        {
            this.req = false
            this.tabActive = Number(localStorage.getItem('orderStatusChange'))+1
            this.orderStatus = Number(localStorage.getItem('orderStatusChange')) + 1
            this.orderList = {}
            this.page = 1
            this.getOrderList()
           console.log('create刷新');
        }
    },
    activated() {
        if(this.req==false){
            return;
        }
        if(this.$route.query.orderStatus==-2){
            return;
        }
        // if(localStorage.getItem('plcae_order_yes')==1){
        //     this.tabActive = 0
        //         this.orderStatus = 1
        //         this.orderList = {}
        //         this.page = 1
        //         this.getOrderList()
        //         console.log('plcae_order_yes')
        //         localStorage.setItem('plcae_order_yes',-1)
        //         console.log('1刷新')
        //         return;
        // }
        
        // console.log(localStorage.getItem('orderStatusChange')==-999,'activated1')
        if(localStorage.getItem('orderStatusChange')==-2){
                this.tabActive = -2
                this.orderStatus = -2
                this.orderList = {}
                this.page = 1
                this.getOrderList()
            console.log('-2刷新')
                return;
        }
        if(localStorage.getItem('orderStatusChange')==-3){
                this.tabActive = 0
                this.orderStatus = 1
                this.orderList = {}
                this.page = 1
                this.getOrderList()
            console.log('-3刷新')
                return;
        }
        if(localStorage.getItem('orderStatusChange')==-1){
            this.tabActive = 0
                this.orderStatus = 1
                this.orderList = {}
                this.page = 1
                this.getOrderList()
                console.log('-1刷新')
                return;
     
        }
        if(localStorage.getItem('orderStatusChange')>=0){
            this.tabActive = Number(localStorage.getItem('orderStatusChange'))+1
            this.orderStatus = Number(localStorage.getItem('orderStatusChange')) + 1
            this.orderList = {}
            this.page = 1
            this.getOrderList()
            console.log('-1大于0刷新',this.tabActive)
            return;
        }
    },
    // destroyed() {
    //     window.removeEventListener('scroll', this.Scrollbottom, true) //页面离开后销毁监听事件
    // },
    methods: {
        orderPay(order_id,index){
            let that = this
            var agent = 'H5';
            var userAgent = window.navigator.userAgent.toLowerCase(); 
            if (userAgent.match(/MicroMessenger/i) == "micromessenger") {
                // console.log('微信');
                agent = 'weixin'
            }
            if (userAgent.match(/Alipay/i) == "alipay") {
                // agent = 'weixin'
            }
            that.btnShow = true
            let data = {
                    order_id,
                    agent,
                    token:this.$store.state.token
                }
            
             that.$request('/api/orderPayment',data )
                .then(res => {
                    // console.log(res)
                    if(res.data.code==1){
                        window.location.href = res.data.data.url
                        // that.orderList.remove(index,1)
                        // this.$toast('确认收货成功');
                        // that.orderList.data.splice(index,1)
                    }
            })
        },
        // 评价
        goodsFeedback(val,index,type){
            if(type==1){
                this.$router.push({path:"/GoodsFeedback","query":{orderId:val.number}})
            }else{
                this.$router.push({path:"/multiGoods","query":{orderId:val.number}})
            }
            
        },
        // 确认收货
        confirmReceipt(number,index){
            // console.log(number)
            let that = this
            let data = {
                    number,
                    token:this.$store.state.token
                }
            
             that.$request('/api/confirmReceipt',data )
                .then(res => {
                    if(res.data.code==1){
                        // that.orderList.remove(index,1)
                        this.$toast('确认收货成功');
                        that.orderList.data.splice(index,1)
                    }
                })
        },
        toDetail(number) {
            this.$router.push({ path: "/orderDetails", "query": { number } })
        },
        
        //订单状态切换
        tabBtn() {
            // console.log(this.tabActive)
            this.orderStatus = this.tabArr[this.tabActive].id
            this.orderList = {}
            this.page = 1
            this.getOrderList()
            console.log(this.tabArr[this.tabActive].id,'状态栏切换');
        },
        getOrderList() {
            let that = this
        //     let load = that.$toast.loading({
        //     message: "加载中...",
        //     forbidClick: true,
        //     duration:0
        //   });
        //   load.clear()
            let data = {
                // order_status: that.orderStatus,
                page: that.page,
                token: this.$store.state.token
            }
            if(that.orderStatus!=-2){
                data.order_status = that.orderStatus
            }
            // console.log(that.orderStatus)
            that.$request('/api/getOrderList',data )
                .then(res => {
                    // load.clear()
                    that.req = true;
                    that.loading = false;
                    let data = res.data.data.data;
                    // 计算每个订单商品总数
                    for (let val in data) {
                        let quantity = 0
                        for (let val2 in data[val]['order_goods']) {
                            quantity = quantity + data[val]['order_goods'][val2]['quantity']

                        }
                        data[val]['total_quantity'] = quantity
                    }

                    res.data.data.data = data
                    if (Object.keys(that.orderList).length === 0) {
                        that.orderList = res.data.data
                    } else {
                        that.orderList.data = that.orderList.data.concat(data)
                    }
                    // console.log(res.data.data.data.length,'=====')
                    if (res.data.data.data.length == 0) {
                        this.finished = true
                    }
                    this.page = res.data.data.current_page+1
                    // localStorage.setItem('orderStatusChange', -998)
                })
        },
        //触底事件
        // Scrollbottom:debounce(function () {
         
        //         console.log(123)
        //         let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        //         let clientHeight = document.documentElement.clientHeight;
        //         let scrollHeight = document.documentElement.scrollHeight;
        //         if (scrollTop+400 + clientHeight >= scrollHeight) {
        //             // if(this.pageEnd){
        //             //     return;
        //             // }
        //             this.page = this.page + 1
        //             this.getOrderList()
                    
        //         }
        //     },500),
            
        onLoad() {
                this.getOrderList()
        },
        
        // 取消订单
        cancelOrder(number) {
            this.$request('/api/cancelOrder', {
                number,
                token: this.$store.state.token
            })
            .then(res => {
                this.$toast('取消成功')
                // this.getOrderList()
            })
        }
    },
};
</script>
<style src="./order.css" scoped>
</style>
<style scoped>
/deep/ .van-tab__text {
    font-size: 30px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    /* color: #000000; */
}
/deep/ .van-tabs__line {
    width: 40px;
    height: 4px;
}
</style>