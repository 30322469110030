import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'normalize.css/normalize.css'
// 按需引入vant组件
import '@/plugin/vant'
// 引入vant组件样式
import 'vant/lib/index.css';
// 引入移动端适配
import 'lib-flexible/flexible.js'
import apiURL from '@/plugin/apiUrl.js'
import { request } from '@/plugin/request.js'

import MoveDiv from '@/components/MoveDiv.vue'
import { store } from './store/store'
Vue.component('MoveDiv', MoveDiv)
Vue.prototype.$request = request
Vue.config.productionTip = false
Vue.prototype.$host = apiURL
Vue.prototype.$imgUrl = 'https://static.ripinhui.shop'//静态文件域名
Vue.prototype.$sellOut = 'https://static.ripinhui.shop/static/i/2023/10/14/5524940044059545655.png'//商品售罄图标

// Vue.prototype.$token = localStorage.getItem('token')
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
}),
// router.beforeEach((to, from, next) => {
//   console.log(to.query,'-----')


//  });
router.beforeEach((to, from, next) => {
  let toQuery = JSON.parse(JSON.stringify(to.query));
  // 防止刷新后vuex里丢失token
  store.commit('getToken')
  store.commit('getUid')
  // 给url统一添加公共参数
  if (to.query.aff) {
  
    // console.log(to.query.aff,'----+')
    if(!localStorage.getItem('aff')){
      localStorage.setItem('aff',to.query.aff)
    }
    next();
    return;
  };
  // console.log(from.query.aff,'from.query.aff')


  if(!to.query.aff){

    // let toQuery = JSON.parse(JSON.stringify(to.query));
    if (store.state.uid) {
      toQuery.aff = store.state.uid;
    } else {
      toQuery.aff = -1;
    }

    next({
      path: to.path,
      query: toQuery
    })
  }
  if (!from.query.aff || from.query.aff == -1) {
  
    // let toQuery = JSON.parse(JSON.stringify(to.query));
    // console.log(store.state.uid, '----')
  
    if (store.state.uid) {
      toQuery.aff = store.state.uid;
    } else {
      toQuery.aff = -1;
    }
    // console.log(to.path, 'to.path')
    next({
      path: to.path,
      query: toQuery
    })
  } else {
    next()
  }

})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

