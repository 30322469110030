<template>
    <div class="goods_details">
        <!-- <div class="top">
            <img class="goods_top_left_ico" src="../../static/image/goods_top_left_ico.png" @click="goOff()" />
            <div>
                <van-search shape="round" background="#F5F5F5" placeholder="搜索商品" :left-icon="left_icon_img" @focus="btn_search" />
            </div>
        </div> -->
        <div class="banner">
            <van-swipe :autoplay="3000" indicator-color="#fff">
                <van-swipe-item v-for="(item, index) in goods.img_url" :key="index">
                    <img class="banner_img"   v-lazy="$imgUrl+item"/>
                </van-swipe-item>
            </van-swipe>
        </div>
        <!-- <div class="goods_price">
            <div>
                <div>
                    <span class="original_price_left">原价</span>
                    <span class="original_price_right">{{goods.original_price}}日元</span>
                </div>
                <div class="mt40">
                    <span class="price_left">商城价</span>
                    <span class="price_right">
                        <img class="price_img_ico" src="../../static/image/goods_price_ico.png" alt="" />
                        <span>{{goods.price}}日元</span>
                    </span>
                </div>
            </div>
            <div>
                <img class="goods_share_ico" src="../../static/image/goods_share_ico.png" alt="">
            </div>
        </div> -->
        <div class="goods_price2">
            <div class="price_left">
                <div class="original_price" v-if="goods.original_price==0">&nbsp;</div>
                <div class="original_price" v-if="goods.original_price!=0">原价:{{goods.original_price}}</div>
                <div class="jp_price">商城价:{{goods.price}}日元 ≈</div>
            </div>
            <div class="price_right">
                <div>
                    <div class="rmb_logo">
                        ￥
                    </div>
                    <div class="rmb_logo2">
                        RMB
                    </div>
                </div>
                <div class="rmb_price">
                    {{goods.price_cny[0]}}<span class="decimal">.{{goods.price_cny[1]}}</span>
                </div>
                <div class="number_price">{{goods.user_grade_discount}}</div>
            </div>
            
            <!-- <div class="original_price" v-if="goods.original_price==0"> </div>

            <div class="original_price" v-if="goods.original_price!=0">原价：{{goods.original_price}}日元</div>
            <div class="price_body">

            
            <div class="price_left">
                <div class="price_text">商城价：{{goods.price}}日元</div>
                <div class="price_text_rmb">
                    <div>≈RMB:</div>
                    <div class="rmb_ico">￥</div>
                    <div>{{goods.price_cny}}</div></div>
            </div>
            <div class="price_right" @click="seccendCopy()">
                <img src="../../static/image/goods_price_share.png" alt="">
            </div>
            </div> -->
        </div>
        <img class="vip"  @click="tolink('/member')" src="https://static.ripinhui.shop/static/i/2023/08/30/5508637439507304773.png" alt="">
        <div class="goods_title_body goods_title mt20">
            <div class="goods_title_top ">
                
                <div>
                    <img class="goods_jp_ico" :src="$imgUrl+'/static/i/2023/08/29/5508354191099172419.png'" alt="">
                
                </div>
                <div>
                    <span class="goods_top_custom_text">{{goods.custom_text}}</span>
                </div>
            </div>
            <div class="title text_rows2">
                {{goods.title}}
            </div>
            <div class="title_bottom_border">
                
            </div>
            <div class="bottom">
                    <img  :src="$imgUrl+'/static/i/2023/08/29/5508361241699032290.jpg'" alt="">
                <!-- <span class="lable" v-for="(item,index) in goods.tag">{{item}}</span> -->
                <!-- <span class="lable">日本本土版</span>
                <span class="lable">日本直邮</span>
                <span class="lable">日本本土版</span>
                <span class="lable">日本直邮</span> -->
                
                <span class="sales_volume">销量：{{goods.sales}}</span>
            </div>
        </div>
        <!-- <div class="goods_recommend_top">
            <img class="ico" src="../../static/image/goods_recommend_ico.png" alt="">
            <span class="title">优品推荐</span>
            <img class="ico" src="../../static/image/goods_recommend_ico.png" alt="">
        </div>
        <van-swipe class="my-swipe" indicator-color="#4c83ff">
            <van-swipe-item>
                <div class="card_white goods_recommend mt30">
                    <div class="goods" @click="toGoods(item.id)" v-for="(item,index) in activityGoodsList.selected" v-if="index<=2">
                        <img class="goods_img" v-lazy="$imgUrl+item.img_url"  alt="">
                        <span class="title text_rows1">{{item.title}}</span>
                        <span class="price">{{item.price_cny.money}}</span>
                    </div>
                </div>
            </van-swipe-item>

            <van-swipe-item>
                <div class="card_white goods_recommend mt30">
                    <div class="goods" @click="toGoods(item.id)" v-for="(item,index) in activityGoodsList.selected" v-if="index>=2">
                        <img class="goods_img" :src="$imgUrl+item.img_url" alt="">
                        <span class="title text_rows1">{{item.title}}</span>
                        <span class="price">{{item.price_cny.money}}</span>
                    </div>
                </div>
            </van-swipe-item>
        </van-swipe> -->
        <div class="card_white mt30 evaluate" v-if="goodsFeedback.count!=0">
            <div class="head">
                <span class="top_left">商品评价({{goodsFeedback.count}})</span>
                <span class="top_right" @click="toFeedBackList()">查看全部<img src="../../static/image/more_icon.png" alt=""></span>
            </div>
            <div class="content mt30" v-if="index<=1" v-for="(item,index) in goodsFeedback.list">
                <div class="user">
                    <!-- <img class="head_portrait" src="" alt=""> -->
                    <div class="user_info">
                        <span class="user_name">{{item.username}}</span>
                        <span class="time">{{item.create_time}}</span>
                    </div>
                </div>
                <div class="text text_rows2">
                    <span>{{item.content}}</span>

                </div>
                <div class="evaluate_img">
                    <img v-for="(item2,index) in item.img_url" v-if="item2" v-lazy="$imgUrl+item2" alt="">
                </div>
            </div>
            <!-- <div class="content mt30">
                <div class="user">
                    <img class="head_portrait" src="" alt="">
                    <div class="user_info">
                        <span class="user_name">爱***花</span>
                        <span class="time">20天前</span>
                    </div>
                </div>
                <div class="text text_rows2">
                    <div>
                        <span>是正品，实物与图片描述一样，质量相当好，发货很快，包装完好，携带方便，之前的水乳用完了，赶...是正品，实物与图片描述一样，质量相当好，发货很快，包装完好，携带方便，之前的水乳用完了，赶...</span>

                    </div>

                </div>
                <div class="evaluate_one_img">
                        <img class="" src="" alt="">
                </div>
            </div> -->

        </div>
        <div class="tips1">
            <img v-lazy="$imgUrl+goodsDetailsTopImg.img1"  alt="">
            <img v-lazy="$imgUrl+goodsDetailsTopImg.img2" @click="tolink('/calculator')" alt="">

        </div>
        <div class="bmcp">
            <img class="head_img" src="https://static.ripinhui.shop/static/i/2023/08/30/5508677899504325916.jpg" alt="">
            <van-swipe class="my_swipe" indicator-color="#4c83ff">
            <van-swipe-item>
                <div class=" goods_recommend1 mt30">
                    <div  @click="toGoods(item.id)" v-for="(item,index) in hwtj" v-if="index<=2">
                        <div class="goods">
                            <img class="goods_img" v-lazy="$imgUrl+item.img_url"  alt="">
                            <div class="border_bottom"></div>
                            <span class="title text_rows1">{{item.title}}</span>
                        </div>
                        <div class="price">≈RMB:￥{{item.price_cny.money1}}</div>
                     </div>
                </div>
            </van-swipe-item>

            <van-swipe-item>
                <div class=" goods_recommend1 mt30">
                    <div  @click="toGoods(item.id)" v-for="(item,index) in hwtj" v-if="index>2">
                        <div class="goods">
                            <img class="goods_img" v-lazy="$imgUrl+item.img_url"  alt="">
                            <div class="border_bottom"></div>
                            <span class="title text_rows1">{{item.title}}</span>
                        </div>
                        <div class="price">≈RMB:￥{{item.price_cny.money1}}</div>
                     </div>
                </div>
            </van-swipe-item>
        </van-swipe>
   
        </div>
        <!--  商品视频 -->
        <video
            v-if="goods.video_url"
            class="video_body" 
            :src="$imgUrl+goods.video_url" 
            controls
        ></video> 
        <!-- <div class="goods_content_title">
            <van-divider :style="{ color: '#aaa', borderColor: '#AAA',  }">宝贝详情</van-divider>
        </div> -->
        <div class="goods_content" v-html="goods.content">
        </div>
        <div class="tips1">
            <img v-lazy="$imgUrl+goodsDetailsTopImg.img3"  >
            <img v-lazy="$imgUrl+goodsDetailsTopImg.img6"  >
            <img v-lazy="$imgUrl+goodsDetailsTopImg.img4"  >
            <img v-lazy="$imgUrl+goodsDetailsTopImg.img5"  >

        </div>
        <div class="goods_bottom_tips">
            <van-collapse v-model="activeNames">
            <van-collapse-item v-for="(item,index) in articleList" :key="index" :title="item.title" :name="index">
                <div v-html="item.content"></div>
            </van-collapse-item>
            </van-collapse>
        </div>
        <div class="divBottom">

        </div>
        <van-goods-action>
            <van-goods-action-icon icon="shop-o" text="首页" color="#000" @click="toIndex()" />
            <van-goods-action-icon icon="star-o" color="#000" text="收藏" @click="collection(1)" v-if="!collectionStatus" />
            <van-goods-action-icon icon="star" color="#D61718" text="收藏" @click="collection(2)" v-if="collectionStatus" />
            <van-goods-action-icon icon="cart-o" text="购物车" @click="toShopCart" />
            <van-goods-action-button type="warning" v-if="goods.sellOut==1" color="#ad0304" text="加入购物车" @click="showSkuPopup(-1)" />
            <van-goods-action-button type="danger" v-if="goods.sellOut==1"@click="showSkuPopup(1)" color="#D61718" text="立即购买" />
        </van-goods-action>
        <van-popup v-model="skuShow" position="bottom" closeable :style="{ height: '70%' }">
            <div class="goods_sku">
                <div class="goods_sku_head">
                    <div class="sku_top_left">
                        <img :src="skuGoodsImg" alt="">
                    </div>
                    <div class="sku_top_right">
                        <div class="sku_title text_rows1">{{goods.title}}</div>
                        <div class="sku_price">{{skuGoodsPrice}}日元</div>
                        <!-- <div class="sku_stock" v-if="skuGoodsStock!=0">库存：{{skuGoodsStock}}</div> -->
                    </div>
                </div>
                <div class="sku_body">

                    <div class="sku_list" v-for="(item,index) in skuList">
                        <div class="sku_list_title">{{index}}</div>
                        <div>
                            <ul class="suk_btn">
                                <li :class="subIndex[index]==index2?'productActive':''" @click="selectSkuBtn(item2,index,index2)" v-for="(item2,index2) in item">{{item2}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="sku_bottom">
                    <div class="number">
                        <span class="title">数量</span>
                        <van-stepper v-model="orderQuantity" />
                    </div>
                    <van-button type="primary" @click="placeOrder" block round color="#4c83ff">确定</van-button>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
// import { request } from '@/plugin/request.js'
export default {

    components: {},
    data() {
        return {
            left_icon_img: require("../../static/image/search_left_icon.png"),
            images: [
                
            ],
            goods: [],
            skuShow: false,
            skuList: [],
            selectArr: {}, //存放被选中的值
            subIndex: {}, //是否选中 因为不确定是多规格还是但规格，所以这里定义数组来判断
            skuGoodsImg: '',
            skuGoodsPrice: -1,//SKU价格
            skuGoodsStock: -1,//SKU库存
            goodsId: '',
            orderQuantity: 1, //选中商品数量
            activityGoodsList: {},
            addShopCartVal: 1,//用于sku弹窗判断是下单还是加入购物车 -1加入购物车 1下单
            selectSkuInfo: {},//多规格选中sku商品详情
            collectionStatus:false, //是否收藏
            activeNames: [0], //商品底部文章折叠面板默认开的位置
            articleList:[], //文章列表
            weight:-1, //商品重量
            goodsFeedback:[], //评价列表
            // 商品详情顶部图片
            goodsDetailsTopImg:{
                img1:'/static/i/2023/08/29/5508363069975172290.jpg',
                img2:'/static/i/2023/09/03/5510087226408572510.jpg',
                img3:'/static/i/2023/08/30/5508656343193492750.jpg',
                img4:'/static/i/2023/08/30/5508656612706883794.jpg',
                img5:'/static/i/2023/08/30/5508656837836149128.jpg',
                img6:'/static/i/2023/08/30/5508681438377024599.jpg'
            },
            hwtj:[]
        };
    },
    created() {
        if (!localStorage.getItem('token')) {
            this.$router.push("/login");
        }
        this.token = this.$store.state.token;
        this.goodsId = this.$route.query.goodsId
        this.getGoodsInfo()
        this.getActivityGoods()
        this.getArticle()
        this.getUserInfo()
        this.getGoodsFeedback()
        
    },

    watch: {
        '$route': function (to, from) {
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
        }
    },
    methods: {
        tolink(url){
            console.log(url)
            this.$router.push(url);
        },
        toFeedBackList(){
            this.$router.push({path:"/goodsFeedbackList","query":{goodsId:this.$route.query.goodsId}})
        },
        getGoodsFeedback(){
            let that = this
            this.$request('/api/getGoodsFeedback', {
                goods_id: that.goodsId,
            })
            .then(res => {
                this.goodsFeedback = res.data.data
                // console.log(res.data.data)
                // that.goods = res.data.data.goods
            })
        },
        //下单
        placeOrder() {
          
            if (this.addShopCartVal == -1) {
                this.addShopCart()

                return;
            }
            let data = []
            if (Object.keys(this.selectArr).length == 0) {
                this.$toast('请正确选择规格')
                return
            }
            let sku = '';
            let skuJson = {};
            for (let item in this.selectArr) {
                sku = sku + ' ' + this.selectArr[item]

            }
            data.push({
                skuJson: this.selectArr,
                sku,
                quantity: this.orderQuantity,
                goods_id: this.goodsId,
                goods_img: this.skuGoodsImg,
                sku_price: this.skuGoodsPrice,
                goods_title: this.goods.title,
                weight:this.weight
            })
            if (Object.keys(this.skuList).length == 2) {
                if (Object.keys(this.selectArr).length == 1) {
                    this.$toast('请正确选择规格')
                    return
                }
            }
            localStorage.setItem('place_order_data', JSON.stringify(data))
            this.$router.push("/placeOrder");
        },
        btn_search() {
            this.$router.push("/search");
        },
        toIndex() {
            this.$router.push("/");
        },
        goOff() {
            this.$router.go(-1);
        },
        getGoodsInfo() {
            let that = this
            this.$request('/api/getGoodsDetail', {
                goods_id: that.goodsId,
                token:that.token
            })
            .then(res => {
                // console.log(res.data.data.goods.img_url)
                that.goods = res.data.data.goods
                that.skuList = JSON.parse(res.data.data.goods.attribute_list);
                that.hwtj = res.data.data.hwtj
                let price_cny = res.data.data.goods.price_cny.toString()
                that.goods.price_cny = price_cny.split('.')
                console.log(that.goods.price_cny,'CNY')
                if(res.data.data.collection==-1){
                    that.collectionStatus = false
                }else{
                    that.collectionStatus = true
                }
            })
        },
        // 弹出sku列表-1加入购物车 1下单
        showSkuPopup(type) {
            this.addShopCartVal = type
            if (this.goods.specs_type == 2) {
                // console.log(this.goods)
                this.skuGoodsImg = this.$imgUrl + this.goods.img_url[0]
                this.skuGoodsPrice = this.goods.price
                this.skuGoodsStock = this.goods.goods_stock;
                this.skuShow = true
            } else {
                //加入购物车
                if(this.addShopCartVal==-1){
                    this.addShopCart()
                    return
                }
                let data = []
                data.push({
                    skuJson: false,
                    quantity: this.orderQuantity,
                    goods_id: this.goods.id,
                    goods_img: this.$imgUrl + this.goods.img_url[0],
                    sku_price: this.goods.price,
                    goods_title: this.goods.title,
                    weight:this.goods.weight
                })
                // console.log(data)
                // return;
                localStorage.setItem('place_order_data', JSON.stringify(data))
                this.$router.push("/placeOrder");
            }

        },
        selectSkuBtn(item, index, index2) {
            // console.log(item, '---', index, '----', index2, '---')
            if (this.selectArr[index] != item) {
                this.selectArr[index] = item
                this.subIndex[index] = index2

            } else {
                this.selectArr[index] = ""
                this.subIndex[index] = -1
            }
            this.$forceUpdate();
            if (Object.keys(this.skuList).length == 1) {
                this.getSkuDetail(this.selectArr)
            } else {
                this.getSkuDetail(this.selectArr)
            }
        },
        getSkuDetail(skuValue) {
            let that = this
            this.$request('/api/getSkuDetail', {
                sku_value: skuValue,
                goods_id: this.goods.id
            })
                .then(res => {
                    if (!res.data.data) {
                        return;
                    }
                    let data = res.data.data
                    this.skuGoodsImg = this.$imgUrl + data.thumbnail
                    this.skuGoodsStock = data.goods_stock
                    this.skuGoodsPrice = data.goods_price
                    this.selectSkuInfo = res.data.data
                    this.weight = data.weight
                })
        },
        // 获取活动商品列表
        getActivityGoods() {
            let that = this
            that.$request('/api/activityGoodsList', { type: 1 })
                .then(res => {
                    that.activityGoodsList = res.data.data
                })
        },
        toGoods(goodsId) {
            this.$router.push({ path: "/goods", "query": { goodsId } })
            this.$router.go(0)
        },
        toShopCart() {
            this.$router.push("/shopCart");
        },
        addShopCart() {
            let that = this
            let data = {}
            
            if (this.goods.specs_type == 2) {
                if (Object.keys(this.selectArr).length == 0) {
                    this.$toast('请正确选择规格')
                    return
                }
                if (Object.keys(this.skuList).length == 2) {
                    if (Object.keys(this.selectArr).length == 1) {
                        this.$toast('请正确选择规格')
                        return
                    }
                }
                data = {
                    quantity: this.orderQuantity,
                    sku_id: this.selectSkuInfo.id,
                    goods_id: this.selectSkuInfo.goods_id
                }
            } else {
                data = {
                    quantity: 1,
                    sku_id: -1,
                    goods_id: this.goods.id
                }
            }
                        
            data.token = this.$store.state.token
            that.$request('/api/addShopCart', data)
                .then(res => {
                    this.$toast('已成功加入到购物车')
                })
        },
        collection(val){
            let that  = this
            let data = {
                token:this.$store.state.token,
                goods_id:this.goods.id
            }
            if(val==1){
                that.$request('/api/addCollection', data)
                .then(res => {
                    // this.$toast('已成功加入到购物车')
                })
            }
            if(val==2){
                that.$request('/api/delCollection', data)
                .then(res => {
                    // this.$toast('已成功加入到购物车')
                })
            }
            that.collectionStatus = !that.collectionStatus
        },
        getArticle(){
            let that = this
            that.$request('/api/getArticle', {type:1})
                .then(res => {
                    that.articleList = res.data.data.data
                })
        },
        getUserInfo(){
            this.$request('/api/getUserInfo', {
                token: this.$store.state.token
            })
            .then(res => {
                localStorage.setItem('discount',res.data.data.discount)
            })
        },
        toArticle(id){
            this.$router.push({
                path:"/article"
                ,query:{
                    articleId:id
                }
            });
        },
        seccendCopy() {
           
            let that = this
            let val = window.location.href

            this.$copyText(val).then(
                function (e) {
                    // console.log("copy arguments e:", e);
                    that.$toast('分享链接复制成功');
                },
                function (e) {
                    // console.log("copy arguments e:", e);
                    that.$toast('复制失败，请长按手动复制');
                }
            );
        },
    },
};
</script>

<style src="./goods.css" scoped>
</style>
<style scoped>
/deep/.van-search {
    width: 670px;
}
/deep/.van-field__left-icon {
    margin: auto;
    padding-right: 10px;
}
/deep/ .van-search__content {
    background-color: #fff !important;
}
/deep/ .van-notice-bar {
    height: 90px;
}
/deep/ .notice-swipe {
    height: 40px;
    line-height: 40px;
}
/deep/ .van-swipe__indicator {
    height: 16px;
    width: 16px;
}
/deep/ .van-swipe__indicators {
    bottom: 14px;
}
/deep/.van-swipe-item {
    font-size: 0px;
}
/deep/ p img{
    font-size: 0px;
    vertical-align:top;
}
/deep/ p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}
/deep/ .goods_bottom_tips .van-collapse-item__content{
    font-size: 20px;
}
/deep/.goods_bottom_tips .van-cell{
    background: #f5f5f5;
}
/deep/ .goods_bottom_tips .van-collapse-item__content{
    background: #f5f5f5;
}
/deep/ .bmcp .my_swipe{
    padding-bottom: 80px;
}
/deep/ .bmcp .van-swipe__indicator--active{
    background-color: #FFF!important;
}
/* /deep/ Attribute{
    font-size: 0px !important;
}
img{
  font-size: 0px !important;
} */
</style>