<template>
    <div class="">
        <nav-bar></nav-bar>
        <div>
            <!-- <head-top-white title="日本代购" left_ico="1"></head-top-white> -->
            
            <div class="top_head">
                <img class="banner" src="../../static/image/jp_banner.jpg" alt="">
                <!-- <van-swipe :autoplay="3000" class="banner" indicator-color="#fff">
                    <van-swipe-item v-for="(image, index) in images" :key="index">
                        <img class="banner_img" :src="image" />
                    </van-swipe-item>
                </van-swipe> -->
            </div>
            <!-- <div class="nav_bar">
                <div @click="toCategory">
                    <div class="nav_img">
                        <img src="../../static/image/nav1.png" />
                    </div>
                    <span>商品分类</span>
                </div>
                <div>
                    <div class="nav_img">
                        <img src="../../static/image/nav2.png" />
                    </div>
                    <span>日本代购</span>
                </div>
                <div>
                    <div class="nav_img">
                        <img src="../../static/image/nav3.png" />
                    </div>
                    <span>天诚转运</span>
                </div>
                <div>
                    <div class="nav_img">
                        <img src="../../static/image/nav4.png" />
                    </div>
                    <span>企业介绍</span>
                </div>
                <div>
                    <div class="nav_img">
                        <img src="../../static/image/nav5.png" />
                    </div>
                    <span>会员中心</span>
                </div>
            </div> -->
            <!-- 通知 -->
            <!-- <van-notice-bar left-icon="volume-o" :scrollable="false" background="#fff" color="#000">
                <van-swipe vertical class="notice-swipe" :autoplay="3000" :show-indicators="false">
                    <van-swipe-item>内容 1</van-swipe-item>
                    <van-swipe-item>内容 2</van-swipe-item>
                    <van-swipe-item>内容 3</van-swipe-item>
                </van-swipe>
            </van-notice-bar> -->
            <!-- 精选单品 -->
            <div class="selected">
                <div class="top">
                    <van-row>
                        <van-col span="6">
                            <span class="title">精选单品</span>
                        </van-col>
                        <van-col span="14">
                            <img class="btzn" src="../../static/image/btzn.gif" />
                        </van-col>
                        <van-col span="4">
                            <span class="more" @click="toGoodsList(1)">更多</span>
                            <img class="more_ico" src="../../static/image/index_more.png" alt="" />
                        </van-col>
                    </van-row>
                </div>
                <div class="goods_body">
                    <div class="goods" @click="toGoods(item.id)" v-for="(item,index) in activityGoodsList.selected">
                        <img :src="$imgUrl+item.img_url" alt="" />
                        <div class="money">{{item.price}}日元</div>
                    </div>

                </div>
            </div>

            <!-- 促销精品 -->
            <div class="promotion">
                <div class="top">
                    <van-row>
                        <van-col span="6">
                            <span class="title">促销精品</span>
                        </van-col>
                        <van-col span="14">
                            <img class="btzn" src="../../static/image/dzlj.gif" />
                        </van-col>
                        <van-col span="4">
                            <span class="more" @click="toGoodsList(2)">更多</span>
                            <img class="more_ico" src="../../static/image/index_more.png" alt="" />
                        </van-col>
                    </van-row>
                </div>
                <div class="goods_body" >
                    <div class="goods" @click="toGoods(item.id)" v-for="(item,index) in activityGoodsList.discount">
                        <img :src="$imgUrl+item.img_url" alt="" />
                        <div class="goods_title text_rows1">{{item.activity_title}}</div>
                        <div class="price_body">
                            <div class="original_price">原价：1500</div>
                            <div class="discount">活动价：950</div>
                            <img class="reduce" src="../../static/image/reduce.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- 活动图片 -->
            <div class="activity">
                <div class="top">
                    <img src="../../static/image/hd1.png" alt="" />
                    <img src="../../static/image/hd2.png" alt="" />
                    <img src="../../static/image/hd4.png" alt="" />
                </div>
                <div class="middle">
                    <div class="hd_left">
                        <img src="../../static/image/hd7.png" alt="" />
                        <img src="../../static/image/hd8.png" alt="" />
                    </div>
                    <div class="hd_right">
                        <img src="../../static/image/hd5.png" alt="" />
                    </div>
                </div>
            </div>
            <!-- 分类标签栏 -->
            <div class="tabs">
                <van-tabs background="#f5f5f5" title-active-color="#4c83ff" color="#f5f5f5" class="vant_tabs" @click="checkCate" v-model="tabActive">
                    <van-tab v-for="(item,index) in cateList" :title="item.category_name" >
                        <div class="goods_body">

                            <div class="goods" @click="toGoods(item2.id)" v-for="(item2,index2) in goodsList.data">
                                <img class="goods_img" :src="$imgUrl+item2.img_url" alt="" />
                                <div class="goods_title">
                                    {{item2.title}}
                                </div>
                                <div class="goods_bottom">
                                    <span class="money">{{item2.price}}日元</span>
                                    <span class="sales_volume">已售{{item2.sales}}</span>
                                </div>
                            </div>
                            
                        </div>
                        <div v-if="pageEnd" style="width:100%;margin-top:20px;color:#999;display: flex;justify-content: center;">
                                <div>- 没有更多了 -</div>
                        </div>
                    </van-tab>
                </van-tabs>
            </div>

            <div style="height: 100px"></div>
        </div>
    </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";

import HeadSearch from '@/components/HeadSearch.vue';
import HeadTopWhite from '@/components/HeadTopWhite.vue';

// import Vue from 'vue';

// Vue.use(Button);
export default {
    name: "HomeView",
    components: {
        NavBar,
        HeadSearch,
        HeadTopWhite
    },
    data() {
        return {
            images: [
                require("../../static/image/banner1.png"),
                require("../../static/image/banner1.png"),
            ],
            left_icon_img: require("../../static/image/search_left_icon.png"),
            cateList: [],
            goodsList: {},
            page: 1, //页码
            pid:'', //选中顶级分类id
            activityGoodsList:{},//活动商品列表
            pageEnd:false,
            tabActive:-1
        };
    },
    created() {
        window.addEventListener('scroll', this.Scrollbottom, true);  //页面加载时监听滚动事件
        this.getCategory()
        this.getGoods()
        this.getActivityGoods()
        
    },
    destroyed() {
        window.removeEventListener('scroll', this.Scrollbottom, true) //页面离开后销毁监听事件
    },
    methods: {
        btnSearch() {
            this.$router.push("/search");
        },
        toCategory() {
            this.$router.push("/category");
        },
        toGoods(goodsId) {
            this.$router.push({path:"/goods","query":{goodsId}})
        },
        toGoodsList(val) {
            this.$router.push({path:"/goodsList","query":{type:val}})
        },
        getCategory() {
            let that = this
            this.$request('/api/categoryList', { pid: -1 })
                .then(res => {
                     console.log(res.data.data)
                     res.data.data.unshift({category_name:'全部'})
                    that.cateList = res.data.data
                })
        },
        // 获取活动商品列表
        getActivityGoods(){
            let that= this
            that.$request('/api/activityGoodsList', {type:1})
                .then(res => {
                    that.activityGoodsList = res.data.data
                })
        },
        getGoods() {
            let that = this
            let res = {
                page: this.page,
                goods_type:2 
            }
            if(that.pid){
                res.pid=that.pid
            }

            this.$request('/api/goodsList', res)
                .then(res => {
                    if (Object.keys(that.goodsList).length===0) {
                        that.goodsList = res.data.data
                    }else{
                        that.goodsList.data = that.goodsList.data.concat(res.data.data.data)
                    }
                    if(res.data.data.data.length==0){
                        this.pageEnd = true
                    }
                })

        },
        //触底事件
        Scrollbottom() {
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            let clientHeight = document.documentElement.clientHeight;
            let scrollHeight = document.documentElement.scrollHeight;
            if (scrollTop + clientHeight >= scrollHeight) {
                this.page = this.page + 1
                this.getGoods()
            }

        },
        checkCate(index){
            this.pid = this.cateList[index].id
            this.page=1
            this.goodsList = {}
            this.getGoods()
        }
    },
};
</script>
<style src="./jpGoods.css" scoped>
</style>
<style  scoped>
/* @import'homeView.css'; */
/deep/ .van-tab {
    font-size: 34px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #333333;
}
/deep/ .van-notice-bar {
    height: 90px;
}
/deep/ .notice-swipe {
    height: 40px;
    line-height: 40px;
}

/deep/ .van-tabs__nav--line.van-tabs__nav--complete {
    padding-left: 0px;
}
</style>