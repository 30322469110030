<template>
  <div>
    <head-top title="企业介绍" left_ico="1"></head-top>
    <div class="title">企业介绍</div>
    <div class="content">人活平生，没谁能躲得过难受，唯独的差别是谁更会应答难受。你能够把它当做一种磨砺，一块垫脚石，一段赞助本人发展的历史，也能够今后迷恋，每天迷恋于惭愧自怜而无法自拔。这些，都在于你的选定。选定差别，人生也差别。因此，人生苦短，有美满时享用美满，没有美满时，要为本人缔造美满。</div>
    <div class="notice">
        <div class="title1">
            <div class="important_notice">重要通知</div>
            <div class="more">
                <div class="word">更多</div>
                <img src="../../static/image/more_icon.png" alt="">
            </div>
        </div>
    </div>
    <div class="con">
        <div class="left_border"></div>
        <div class="right">
            <div class="title_word">有关邮政快递停发的通知</div>
            <div class="time">2022-08-30</div>
        </div>
    </div>
  </div>
</template>

<script>
import HeadTop from "@/components/HeadTop.vue";
export default {
  components: {
    HeadTop,
  },
};
</script>

<style src="./company.css" scoped>
</style>

<style scoped>

</style>
