<template>
    <div class="head_top">
        
        <img
            class="top_left_icon"
            @click="goOff()"
            :src="search_top_left_icon"
            alt=""
            v-if="left_ico==1"
            
        />
        <span :class="left_ico==2?'mleft30 title':'title'">{{ title }}</span>
    </div>
</template>

<script>
export default {
    props: ["title","left_ico",'url'],
    data() {
        return {
            search_top_left_icon: require("../static/image/search_top_left_icon2.png"),
        };
    },
    watch: {
        toUrl(val){
            // console.log(val)
        }
    },

    methods: {
        goOff() {
            // this.$router.go(-1);
            // console.log(this.url!='-1','----'+this.url)
            if(this.url!='-1'){
                // if(this.url=='/my')
                this.$router.push(this.url);
                
            }else{
                this.$router.go(-1);
            }
            
            // this.$router.go(this.url);
        },
    },
};
</script>

<style scoped>
.head_top {
    background: #FFFFFF;
    display: flex;
    align-items: center;
    padding-bottom: 20px;
}
.top_left_icon {
    width: 44px;
    height: 44px;
    margin: 20px 0 0 20px;
}
.title {
    font-size: 44px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #000;
    margin-top: 24px;
}
.mleft30{
    margin-left:30px;
}
</style>