<template>
    <div>
        <head-top title="提交订单" left_ico="1"></head-top>
        <div class="address_body" @click="toAddressList">
            <div class="address" v-if="addressShow==true">
                <div>
                    <div class="top">
                        <span class="top_text">{{address.name}}</span>
                        <span class="top_text">{{address.phone}}</span>
                    </div>
                    <div class="bottom">
                        <span class="default" v-if="address.default==2">[默认]</span>
                        <span>{{address.province+" "+address.city+" "+address.area+" "+address.address}}</span>
                    </div>
                </div>
                <div class="more">
                    <img src="../../static/image/more_icon.png" alt="">
                </div>
            </div>
            <div v-if="addressShow==false" class="address_default">
                <div>
                    + 请添加收货地址
                </div>
            </div>
        </div>
         <div class="sfz_tips">
      <van-notice-bar
        wrapable
        :scrollable="false"
        v-if="address.type==1"
        text="根据海关要求，购买跨境商品需要提供订购人身份信息（请与支付账号实名信息相同）本信息仅用于海关清关，日品汇保证信息安全。"
      />
    </div>
    <div class="sfz_info" v-if="address.type==1">
        <van-cell-group>
        <van-field
          v-model="sfzName"
          name="sfz_name"
          label="订购人"
          placeholder="请输入真实姓名"
        />
        <van-field
          v-model="sfzId"
          name="sfz_id"
          label="身份证号"
          placeholder="请输入身份证号"
        />
        </van-cell-group>
    </div>
        <div class="goods card_white">
            <div class="details" v-for="(item,index) in goodsList">
                <div class="details_left">
                    <img :src="item.goods_img" alt="" />
                </div>
                <div class="details_right">
                    <div class="goods_title text_rows1">{{item.goods_title}}</div>
                    <div class="quantity">
                        <span>{{item.sku}}</span>
                        <van-stepper v-model="item.quantity" input-width="28" button-size="24" />
                        <!-- <span>x{{item.quantity}}</span> -->
                    </div>
                    <div class="unit_price unit_price_color">{{item.sku_price}}日元</div>
                </div>
            </div>
            <!-- <order-goods-details v-for="(item,index) in goodsList" :img="item.goods_img" :price="item.sku_price" :title="item.goods_title" :specs="item.sku" :number="item.quantity" price_colour="true" divider="1" /> -->
        </div>
        <div class="order_other card_white">
            <!-- <div class="integral">
                <div class="integral_left"><img src="../../static/image/place_mone_ico.png" alt=""><span>积分抵扣</span></div>
                <div class="integral_right">
                    <div class="quantity">
                        当前积分：<span>100</span>
                    </div>
                    <van-checkbox v-model="integral" checked-color="#4c83ff"></van-checkbox>
                </div>
            </div> -->
            <div class="note">
                <div>
                    <div class="note_left"><img src="../../static/image/place_note_ico.png" alt=""><span>备注信息</span></div>
                </div>
                <van-field rows="2" v-model="note" autosize type="textarea" maxlength="50" placeholder="输入订单备注" show-word-limit />
            </div>
        </div>
        <div class="order_details card_white">
            <div class="title">
                价格明细
            </div>
            <div class="price">
                <div class="price_left">
                    <div>商品金额</div>
                    <span>共{{orderQuantity}}件商品</span>

                </div>
                <div class="price_right">
                    <div>{{orderTotalPrice}}日元</div>
                </div>
            </div>
             <div class="price" v-if="address.type==1">
                <div class="price_left">
                    <div >选择快递</div>
                    <div class="ico_style" @click="toFreightArticle()"> <van-icon name="question-o" /></div>

                </div>
                <div class="price_right">
                    {{kdname}}<span style="color:#4c83ff" @click="kdshow=true">(更换)</span>
                </div>
            </div>
            <div class="price">
                <div class="price_left">
                    <div >支付方式</div>
                    <!-- <div class="ico_style" @click="toFreightArticle()"> <van-icon name="question-o" /></div> -->

                </div>
                <div class="price_right">
                    {{payType}}<span style="color:#4c83ff" @click="payTypeShow=true">(更换)</span>
                </div>
            </div>
            <div class="price">
                <div class="price_left">
                    <div >运费</div>
                    <div class="ico_style" @click="toFreightArticle()"> <van-icon name="question-o" /></div>

                </div>
                <div class="price_right">
                    <div>{{freightVal}}日元</div>
                </div>
            </div>
            <div class="price">
                <div class="price_left">
                    <div >税费</div>
                    <div class="ico_style" @click="toFreightArticle()"> <van-icon name="question-o" /></div>

                </div>
                <div class="price_right">
                    <div v-if="address.type==1">包税费</div>
                    <div v-if="address.type==2">不包税费</div>
                </div>
            </div>
            <div class="price">
                <div class="price_left">
                    <div >会员折扣</div>
                </div>
                <div class="price_right">
                    <div>-{{discountM}}日元</div>
                </div>
            </div>
            <!-- <div class="price">
                <div class="price_left">
                    <div>积分抵扣</div>
                </div>
                <div class="price_right">
                    <div>-1099.00日元</div>
                </div>
            </div> -->
        </div>
        <div class="total">
            <div class="total_left">
                合计:<span>{{orderTotalPrice+freightVal-discountM}}日元(约{{rmb}})</span>
            </div>
            <div>
                <van-button type="primary" color="#4c83ff" round class="btn" @click="orderBtn">提交订单</van-button>
            </div>
        </div>
        <van-overlay :show="btnShow" >
            <div class="wrapper">
                <van-loading size="24px" vertical>跳转支付过程会有短暂的空白页，请耐心等待一下</van-loading>
            </div>
            
            </van-overlay>
            <van-overlay :show="show" @click="show = false">
                <div class="wrapper" @click.stop>
                    <div class="block" />
                </div>
            </van-overlay>
            <van-popup v-model="kdshow" position="bottom"  :style="{ height: '45%'}">
               <van-picker
                title="选择快递"
                show-toolbar
                :columns="columns"
                @confirm="onConfirm"
                />
            </van-popup>
            <van-popup v-model="payTypeShow" position="bottom"  :style="{ height: '45%'}">
               <van-picker
                title="选择支付方式"
                show-toolbar
                :columns="payList"
                @confirm="payTypeClick"
                />
            </van-popup>
        <!-- <right-nav></right-nav> -->
    </div>
</template>

<script>
import HeadTop from '@/components/HeadTop.vue'
import OrderGoodsDetails from '@/components/OrderGoodsDetails.vue'
// import MoveDiv from '@/components/MoveDiv.vue'

export default {
    components: { HeadTop, OrderGoodsDetails },
    data() {
        return {
            integral: '',
            address: {}, //收货地址列表
            addressShow: false, //判断显示没有地址组件还是地址信息
            addressListPopup: false, //地址列表弹窗
            addressList: [],
            goodsList: [],// 商品列表
            orderQuantity: 0, //订单总商品数量
            orderTotalPrice: 0,//订单总价
            totalWeight:0 ,//总重量
            note: '',//订单备注
            kfbtn: true, //客服弹窗
            rmb:'',
            btnShow: false, //提交后的遮罩层
            freightVal:0, //运费
            discount:0, //折扣
            discountM:0, //折扣金额
            sfzName:'', //订购人姓名
            sfzId:'', //订购人身份证
            kdshow:false, //选择快递弹出层
            columns: ['普快(中通国际)', '特快(国际EMS)'],
            kdname:'普快(中通国际)',
            payType:'微信支付',
            payTypeShow:false,
            payList: ['微信支付', '好友代付'],
        }
    },
    watch: {
        goodsList: {
            handler(newValue) {

                this.updatePrice(newValue)
            },
            deep: true
        },
    },
    created() {
        this.discount = localStorage.getItem('discount');
        this.token = this.$store.state.token
        this.getAddressDefaultList()
        this.goodsList = JSON.parse(localStorage.getItem('place_order_data'));
        localStorage.setItem('orderStatusChange', -2)
        // this.freight()
        
        
        if(localStorage.getItem('address_select_data')){
            this.address = JSON.parse(localStorage.getItem('address_select_data'));
            console.log(this.address)
        }
               
    },
    methods: {
        onConfirm(value, index) {
            this.kdname = value
            this.kdshow = false
            this.updatePrice(this.goodsList)
        },
        payTypeClick(value){
             this.payType = value
            this.payTypeShow = false
        },
        //计算修改数量后的价格
       updatePrice(val) {
           console.log('数量变动')
            let price = false;
            let quantity = 0;
            let weight = 0
            val.forEach(item => {
                price = price + item.quantity * item.sku_price
                quantity = quantity + item.quantity
                weight+=item.quantity*item.weight
                
               
            });
            let discountM = this.orderTotalPrice-this.orderTotalPrice*this.discount;
            this.freight(weight,quantity)
            this.totalWeight = weight 
            this.orderTotalPrice = price
           
            this.setDiscount()
            this.orderQuantity = quantity
            this.$request('/api/jpyToCny', {
                value1: 'JPY',
                value2: 'CNY',
                money:price+this.freightVal-this.discountM
            })
            .then(res => {
                this.rmb = res.data.data.money1 +'人民币'
            })
            
        },
        setDiscount(){
            if(this.discount==0){
                return
            }
            let discountM = this.orderTotalPrice-this.orderTotalPrice*this.discount;
            this.discountM = Math.round(discountM)
            return discountM
        },
        // setPrice() {
        //     for (let item in this.goodsList) {
        //         this.orderQuantity = this.orderQuantity + this.goodsList[item].quantity
        //         this.orderTotalPrice = this.orderTotalPrice + this.goodsList[item].sku_price * this.goodsList[item].quantity
        //     }
        // },
        // 获取默认收获地址列表
        getAddressDefaultList() {
            let that = this
            this.$request('/api/addressList', {
                token: this.$store.state.token,
                type: 2
            })
                .then(res => {


                    if (res.data.data.length > 0) {
                        
                        this.addressShow = true
                        if (Object.keys(that.address).length == 0) {
                             console.log(res.data.data)
                            that.address = res.data.data[0];
                            that.freight(that.totalWeight,that.orderQuantity)
                            
                        }

                    } else {
                       
                        this.addressShow = false
                        if(localStorage.getItem('address_select_data')){
                            this.address = JSON.parse(localStorage.getItem('address_select_data'));
                            this.addressShow = true
                        }
                    }
 
                })
               
        },
        //提交订单
        orderBtn() {
            if(this.address.type==1){
                if(this.sfzName == ''){
                this.$toast('请填写订购人姓名')
                return 
                }
                if(this.sfzId == ''){
                    this.$toast('请填写身份证号')
                    return 
                }
                const pattern = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
                if (!pattern.test(this.sfzId)) {
                    this.$toast('请输入正确的身份证号码')
                    return 
                }
                if (Object.keys(this.address).length == 0) {
                    this.btnShow = false
                    this.$toast('请选择收货地址')
                    
                    return;
                }
            }
            

            this.btnShow = true
            
            
            let data = {
                address: this.address,
                goodsList: this.goodsList,
                note: this.note,
                token: this.$store.state.token,
                sfzId:this.sfzId,
                sfzName:this.sfzName,
                kdname:this.kdname
            }
            if(this.address.type==2){
                data.kdname  = '特快(国际EMS)'
            }
            this.$request('/api/placeOrder', data)
                .then(res => {
                    if (res.data.code == 1) {
                        localStorage.setItem('plcae_order_yes', 1)
                        localStorage.setItem('previous_url', '/order')
                        if(this.payType=='好友代付'){
                            this.$router.push("/friendPayment?pay=-1&order_id="+res.data.data.id);
                        }else{
                            window.location.href = res.data.data.url
                        }
                        
                        // this.$toast('下单成功,请联系客服进行付款');
                        // this.$router.push({ path: "/orderDetails", "query": { number: res.data.data.number } })
                    }

                })
            // console.log(data)

        },
        toAddressList() {
            localStorage.setItem('addressUrl', 'address_gl')
            // return;
            // localStorage.setItem('addressUrl', 'address_gl')
            this.$router.push("/address_gl");
        },
        toFreightArticle(){
            this.$router.push("/freightArticle");
        },
        /**
         * @Description: 计算运费
         * @Author: zxy
         * @param {*} weight 总重量
         * @param {*} quantity 总数量
         * @return {*}
         */
        freight(weight='',quantity){
            let that = this
            let address = ''
            if(Object.keys(this.address).length != 0){
               address = this.address 
            }else{
                address = JSON.parse(localStorage.getItem('address_select_data'));
            }
            
            // console.log(address.province)
            //   let weight = '';
            if(weight==''){
              
                this.goodsList.forEach(item=>{
                    weight += item.weight
                })
            }
            if(!address){
                return;
            }
            
            let data = {
                weight,
                quantity,
                province:address.province,
                type:address.type,
                kdname:this.kdname
            }
            if(address.type==1){
                data.province = address.province
            }
            if(address.type==2){
                data.province = address.province
            }
            that.$request('/api/freight', data)
                .then(res => {
                    if (res.data.code == 1) {
                        that.freightVal = Math.round(res.data.data)
                        this.$request('/api/jpyToCny', {
                                value1: 'JPY',
                                value2: 'CNY',
                                money:this.orderTotalPrice+this.freightVal-this.discountM
                            })
                            .then(res => {
                                this.orderTotalPrice
                                this.rmb = res.data.data.money1 +'人民币'
                            })
                        // this.$toast('下单成功,请联系客服进行付款');
                        // this.$router.push({ path: "/orderDetails", "query": { number: res.data.data.number } })
                    }

                })
        }
    },

}
</script>

<style src="./placeOrder.css" scoped>
</style>
<style scoped>
/deep/ .note .van-cell {
    background: #f5f5f5;
    margin-top: 20px;
    border-radius: 10px 10px 10px 10px;
}
/deep/ .sfz_tips .van-notice-bar{
    border-radius: 20px 2px 20px 20px;
}

/deep/ .sfz_info .van-cell{
    background: transparent !important;
}
</style>