<template>
    <div>
        <head-top :title="title" left_ico="1"></head-top>
        <head-search />
        <div class="head_top_body">

        </div>
        <div class="content card_white">
            <div class="condition">
                <div @click="conditionPopup()">筛选</div>
                <div @click="clickFilter(1)">价格
                    <img v-if="filter1==0" src="../../static/image/screen_default_ico.png" alt="">
                    <img v-if="filter1==1" src="../../static/image/screen_top_ico.png" alt="">
                    <img v-if="filter1==2" src="../../static/image/screen_lower_ico.png" alt="">
                </div>
                <div @click="clickFilter(2)">销量
                    <img v-if="filter2==0" src="../../static/image/screen_default_ico.png" alt="">
                    <img v-if="filter2==1" src="../../static/image/screen_top_ico.png" alt="">
                    <img v-if="filter2==2" src="../../static/image/screen_lower_ico.png" alt="">
                </div>
                <div @click="clickFilter(3)">新品</div>
            </div>
              <van-list
                v-model="loading"
                :finished="finished"
                :immediate-check="false"
                @load="onLoad"
                >
            <div class="goods_body">
              
                <div class="goods" @click="toGoods(item2.id)" v-for="(item2,index2) in goodsList.data">
                    
                    <img class="goods_img" v-if="item2.thumbnail" v-lazy="$imgUrl+item2.thumbnail" alt="" />
                    <img class="goods_img" v-if="!item2.thumbnail" v-lazy="$imgUrl+item2.img_url" alt="" />
                    <img class="goods_img2" v-if="item2.sellOut==2" v-lazy="$sellOut" alt="" />
                    
                    <div class="goods_title">
                        {{item2.title}}
                    </div>
                    <div class="goods_bottom">
                        <span class="money">{{item2.price_cny.money}}</span>
                        <span class="sales_volume">已售{{item2.sales}}</span>
                    </div>
                </div>
         
            </div>
               </van-list>
            <div v-if="finished" style="width:100%;margin-top:20px;color:#999;display: flex;justify-content: center;">
                <div>- 没有更多了 -</div>
            </div>
            <van-popup v-model="show" position="right" :style="{ height: '100%',width:'80%'}">
                <div class="condition_popup">
                    <div class="condition_content">
                        <div class="condition_title">价格区间</div>
                        <div class="condition_input">
                            <van-row>
                                <van-col span="10">
                                    <van-field v-model="priceStart" />
                                </van-col>
                                <van-col span="2">
                                    <div style="width: 20px;height: 1px;background: #CBCBCB;margin-top:25px;">
                                    </div>
                                </van-col>
                                <van-col span="10">
                                    <van-field v-model="priceEnd" />
                                </van-col>
                            </van-row>
                        </div>
                    </div>
                    <div class="condition_content">
                        <div class="condition_title">分类</div>
                        <div class="tagList">
                            <div :class="clickCateKey==index?'productActive tag text_rows1':'tag text_rows1'" v-for="(item,index) in categoryList"  @click="clickCate(index)">
                                {{item.category_name}}
                            </div>

                        </div>
                    </div>
                    <!-- <div class="condition_content">
                        <div class="condition_title">品牌</div>
                        <div class="tagList">
                            <div :class="clickBrandKey==index?'productActive tag':'tag'" v-for="(item,index) in brandList" @click="clickBrand(index)">
                                {{item.name}}
                            </div>

                        </div>
                    </div> -->
                    <div class="condition_btn">
                        <div class="btn_left" @click="resetFilter">
                            重置
                        </div>
                        <div class="btn_right" @click="btnFilter">
                            完成
                        </div>
                    </div>
                </div>
            </van-popup>
        </div>
    </div>
</template>

<script>
import HeadTop from "@/components/HeadTop.vue";
import HeadSearch from "@/components/HeadSearch.vue";
export default {
    components: {
        HeadSearch,
        HeadTop
    },
    data() {
        return {
            title: "商品列表",
            filter1: 0,
            filter2: 0,
            goodsList: {},
            page: 1,
            pageEnd: false,
            keyword: '',//搜索词
            salesSort: '', //销量排序
            priceSort: '', //价格排序
            show: false, //筛选弹框
            categoryList: '',
            clickCateKey: -1,//筛选选中分类
            clickBrandKey: -1,//筛选选中品牌
            priceStart: '', //筛选设置起始价格
            priceEnd: '', //筛选设置结束价
            brandKey: -1, //筛选选中品牌
            goodsType: '', //1精选单品 2促销精品 3日本代购
            catagoryId:-1, //分类id
            brandList: {}, //品牌列表
            loading: false,//设置为 true，表示分页处于加载状态
            finished: false,//	是否已加载完成，加载完成后不再触发load事件
        }
    },
    created() {

        // this.keyword = this.$route.query.keyword
        // type 1精选单品 2促销精品 3日本代购
        // this.goodsType = this.$route.query.type
        // this.catagoryId = this.$route.query.catagoryId
        // window.addEventListener('scroll', this.Scrollbottom, true);  //页面加载时监听滚动事件
        // this.getGoods()
        this.getCategoryList()
        this.getBrandList()
    },
    destroyed() {

        // window.removeEventListener('scroll', this.Scrollbottom, true) //页面离开后销毁监听事件
    },
    // keep-alive组件激活时触发
    activated() {
       this.finished = false
        this.resetFilter()
        // console.log(this.catagoryId,'====++',this.$route.query.catagoryId)
        if(this.$route.query.keyword&&this.$route.query.keyword!=this.keyword){
            this.catagoryId = -1
            this.goodsType = ''
            //  console.log(0,'==')
            this.keyword = this.$route.query.keyword
            this.goodsList={}
            this.page=1
            this.getGoods()
            
        }
        
        if(this.$route.query.catagoryId&&this.$route.query.catagoryId!=this.catagoryId){
            this.goodsType = ''
            this.keyword = ''
            // console.log(1,'==')
            this.catagoryId = this.$route.query.catagoryId
            this.goodsList={}
            this.page=1
            this.getGoods()
        }
        if(this.$route.query.type&&this.$route.query.type!=this.goodsType){
            //  console.log(2,'==')
            this.keyword = ''
            this.catagoryId = -1
            this.goodsType = this.$route.query.type
            this.goodsList={}
            this.page=1
            this.getGoods()
            // this.catagoryId = -1
        }
        // if(this.$route.query.type&&this.$route.query.type==2){
        //      console.log(3)
        //     this.goodsType = this.$route.query.type
        //     this.goodsList={}
        //     this.page=1
        //     this.getGoods()
        //     this.catagoryId = -1
        // }
         this.getCategoryList()
    },

    methods: {

        goOff() {
            this.$router.go(-1);
        },
        conditionPopup() {
            this.show = true
        },
        clickFilter(id) {
            if (id == 1) {
                if (this.filter1 == 0 || this.filter1 == 1) {
                    this.filter1 = 2
                    this.priceSort = 'desc'
                } else {
                    this.filter1 = 1
                    this.priceSort = 'asc'
                }
            }
            if (id == 2) {
                if (this.filter2 == 0 || this.filter2 == 1) {
                    this.filter2 = 2
                    this.salesSort = 'desc'
                } else {
                    this.filter2 = 1
                    this.salesSort = 'asc'
                }
            }
            this.setDefaultData()
            this.getGoods()
        },
        setDefaultData() {
            // this.salesSort=false, //销量排序
            // this.priceSort=false //价格排序
            this.page = 1,
                // this.pageEnd = false
            this.goodsList = {}
        },
        toGoods(goodsId) {
            this.$router.push({ path: "/goods", "query": { goodsId } })
        },
        getGoods() {
            this.loading = true
            let that = this
            let res = {
                page: this.page
            }
            if (that.pid) {
                res.pid = that.pid
            }
            if (that.keyword) {
                res.goods_title = that.keyword
            }
            if (that.salesSort) {
                res.sales_sort = that.salesSort
            }
            if (that.priceSort) {
                res.price_sort = that.priceSort
            }
            if(that.catagoryId!=-1){

                res.category_id = that.catagoryId
            }
            if(that.priceStart){
                res.price_min = that.priceStart
                if(that.priceEnd==''){
                    this.$toast('请正确输入价格区间')
    
                }
            }
            if(that.priceEnd){
                res.price_max = that.priceEnd
                if(that.priceStart==''){
                    this.$toast('请正确输入价格区间')
       
                }
            }
            if(that.clickBrandKey!=-1){
                res.goods_title = that.brandList[that.clickBrandKey]['name']
            }
            if(that.clickCateKey!=-1){
                res.category_id = that.categoryList[that.clickCateKey]['id']
            }
            if (that.goodsType) {
                if (that.goodsType == 1) {
                    res.recommend = 1
                }
                if (that.goodsType == 2) {
                    res.recommend = 2
                }
                if (that.goodsType == 3) {
                    res.goods_type = 2
                }
            }
            this.$request('/api/goodsList', res)
                .then(res => {
                    this.loading = false;
                    if (Object.keys(that.goodsList).length === 0) {
                        that.goodsList = res.data.data
                      
                    } else {
                        that.goodsList.data = that.goodsList.data.concat(res.data.data.data)
                    }
                    // if (res.data.data.data.length == 0) {
                    //     this.pageEnd = true
                    // }
                    if(res.data.data.data.length==0){
                        this.finished = true
                    }
                    this.page = res.data.data.current_page+1
                })

        },
        onLoad() {
            console.log(1123)
            this.getGoods()
        },
        // //触底事件
        // Scrollbottom() {
        //     let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        //     let clientHeight = document.documentElement.clientHeight;
        //     let scrollHeight = document.documentElement.scrollHeight;
        //     if (scrollTop + clientHeight >= scrollHeight) {
        //         this.page = this.page + 1
        //         this.getGoods()
        //     }
        // },
        getCategoryList() {
            let that = this
            if(!this.catagoryId){
                return;
            }
            that.$request('/api/getSubCatrgory', {
                category_id:this.catagoryId,
                token:this.$store.state.token
                })
                .then(res => {
                    that.categoryList = res.data.data

                })
        },
        clickCate(index) {
            this.page = 1
            this.clickCateKey = index
        },
        clickBrand(index) {

            this.clickBrandKey = index
        },
        // 重置筛选条件
        resetFilter() {
            this.clickBrandKey = -1
            this.clickCateKey = -1,//筛选选中分类
                this.priceStart = '', //筛选设置起始价格
                this.priceEnd = '', //筛选设置结束价
                this.brandKey = -1 //筛选选中品牌
            this.salesSort = '', //销量排序
                this.priceSort = '' //价格排序
            this.filter1 = 0,
                this.filter2 = 0
        },
        // 提交搜索条件
        btnFilter() {
            this.goodsList = {}
            this.getGoods()
            this.show = false
            this.page=1
            this.finished = false
        },
        getBrandList() {
            let that = this
            that.$request('/api/brandList', {type:1})
                .then(res => {
                    // console.log(res.data.data)
                    that.brandList = res.data.data

                })
        }
    }
}
</script>

<style src="./goodsList.css" scoped>
</style>
<style  scoped>
/deep/ .van-popup {
    background: #f5f5f5 !important;
}
/deep/ .condition_popup .van-field__control {
    border: 1px solid #cbcbcb;
}
/deep/ .condition_popup .van-field__control {
    height: 70px;
}
/deep/ .condition_popup .van-field__control {
    text-align: center;
}
</style>