<template>
    <div>
        <head-top-white title="地址管理" left_ico="1" url="-1"></head-top-white>
        <div class="address_body">

      
        <div class="white_bg" v-for="(item,index) in addressList" >
            <div class="above" @click="btnAddres(item)">
                <div class="user_name">{{item.name}}</div>
                <div class="user_tel">{{item.phone}}</div>
            </div>
            <div class="middle" @click="btnAddres(item)">{{item.province+" "+item.city+" "+item.area+" "+item.address}}</div>
            <van-divider />
            <div class="below">
                <div class="below_left">
                    <van-tag plain type="primary" color="#4c83ff" v-if="item.default==2">默认地址</van-tag>
                </div>

                <!-- <van-radio-group v-model="item.default" checked-color="#4c83ff">
                    <van-radio name="1">设为默认</van-radio>
                </van-radio-group> -->
                <div class="below_right">
                    <div class="edit" @click="toUpdateAddress(item.id)">
                        <img src="../../static/image/edit_icon.png" alt="">
                        <div class="word" >编辑</div>
                    </div>
                    <div class="del">
                        <img src="../../static/image/del_icon.png" alt="">
                        <div class="word" @click="delAddress(item.id)">删除</div>
                    </div>
                </div>

            </div>
        </div>
          </div>
        <div class="add_btn">
            <van-button type="primary" round color="#4c83ff" block icon="plus" @click="toUpdateAddress(false)"> 添加新地址</van-button>

        </div>
    </div>
</template>

<script>
import HeadTopWhite from "@/components/HeadTopWhite.vue";
export default {
    components: {
        HeadTopWhite,
    },
    data() {
        return {
            radio1: false,
            radio2: false,
            addressList: []//收货地址列表
        };
    },
    created() {
        // console.log(localStorage.getItem('addressUrl'))
        this.addressUrl = localStorage.getItem('addressUrl')
        this.token = localStorage.getItem('token');
        this.getAddressList()
    },
    methods: {
        // 获取收货地址列表
        getAddressList() {
            this.$request('/api/addressList', {
                token: this.$store.state.token,
            })
                .then(res => {
                    this.addressList = res.data.data
                })
        },

        toUpdateAddress(address_id) {
            if(!address_id){
                this.$router.push({ path: "/addressSave" })
            }
            this.$router.push({ path: "/addressSave", "query": { address_id } })
        },
        // 删除
        delAddress(address_id) {
            this.$dialog.confirm({
                title: '提示',
                message: '确认删除该地址吗',
            })
                .then(() => {
                    this.$request('/api/delAddress', {
                        token: this.$store.state.token,
                        address_id
                    })
                        .then(res => {
                            this.$toast('删除成功')
                            this.getAddressList()
                        })
                })
        },
        btnAddres(val){
            if(this.addressUrl=='my'){
                return
            }
            localStorage.setItem('address_select_data',JSON.stringify(val))
             this.$router.push("/placeOrder");
        }
    }
};
</script>

<style src="./address_gl.css" scoped>
</style>

<style scoped>
/deep/.van-divider {
    width: 650px;
    margin-top: 20px;
    margin-bottom: 0px;
}
/deep/.van-radio__label {
    font-size: 28px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #000000;
}
</style>