<!--  -->
<template>
  <div>
    <head-top-white title="商品评价" left_ico="1" :url='-1'></head-top-white>
    <div class="goods">
        <div class="left_body">
            <img :src="$imgUrl+orderDetail.img_url" alt="">
        </div>
        <div class="right_body">
            <div class="head">
                <div class="title text_rows1">{{orderDetail.goods_title}}</div>
                <div class="price">x{{orderDetail.quantity}}</div>
            </div>
            <div class="bottom">
                ￥{{orderDetail.price}}  
            </div>
        </div>
    </div>
    <div class="card_white feedback">
      <div class="score">
        <div class="title">商品评价</div>
        <van-rate size="30px" v-model="score" color="#4c83ff" />
      </div>
          <div class="img">
            <van-uploader v-model="fileList" max-count="3" multiple :after-read="afterRead" :before-delete="delImg" />
          </div>
          <van-divider  :style="{color:'#eee'}"/>
          <van-field
            v-model="feedbackText"
            rows="3"
            autosize
            type="textarea"
            placeholder="点击此处输入评价内容"
          />
          
    </div>
    <div class="feedback_btn">
      <van-button block type="primary" color="linear-gradient(90deg, #4c83ff 0%, #FF66AC 100%)" @click="btnFeedback()" round>立即评价</van-button>
    </div>
  </div>
</template>

<script>
import HeadTopWhite from '../../components/HeadTopWhite.vue';
import axios from "axios"
export default {
  components: { HeadTopWhite },
  data () {
    return {
        orderDetail:[],
        score:0, //打分
        feedbackText:'', //评价内容
        fileList: [],
        imgList:[], //提交的图片列表
        goodsId:-1
    };
  },

  created() {
    this.orderId = this.$route.query.orderId
    this.goodsId = this.$route.query.goodsId
    this.getOrderDetail()
  },

  methods: {
    getOrderDetail(){
        let that= this
        that.$request('/api/getOrderDetail', {
                token:this.$store.state.token,
                number:this.orderId
            })
            .then(res => {
                console.log(res.data.data.goods)
                that.orderDetail = res.data.data.goods[0]

            })
    },
    // 删除图片
    delImg(val,detail){
      this.imgList.splice(detail.index,1)
      return true
    },
    //上传
    afterRead(file){
      if(file instanceof Array){
        file.forEach(e => {
          this.uploadFile(e.file)
        });
      }else{
        this.uploadFile(file.file)
      }
    },
    //提交订单
     btnFeedback(){
      let that = this
      if(that.score==0){
        that.$toast('请给商品打分')
      }
      let data = {
        imglist:that.imgList,
        order_id:that.orderId,
        score:that.score,
        feedback_text:that.feedbackText,
        token:this.$store.state.token,
      }
      if(this.goodsId!=-1){
        data.goods_id = this.goodsId
      }
      that.$request('/api/orderFeedBack', data)
            .then(res => {
              if(res.data.code==1){
                that.$toast('提交成功')
                localStorage.setItem('orderStatusChange',4);
                this.$router.push("/order");
              }
                
            })
      
      console.log(data)
    },
    uploadFile(file){
      let that = this

      let formData = new FormData();
        formData.append("image",file);
        formData.append("token",this.$store.state.token);

        const config = {
        headers: { "Content-Type": "multipart/form-data;boundary="+new Date().getTime() }
        };
        axios
        .post(that.$imgUrl+"/api/uploadImg",formData,config)
        .then(function (res) {
          that.imgList.push(res.data.data.url)
        })
    }
  }
}

</script>
<style src="./index.css" scoped>
</style>
<style  scoped>
.bottom .btn1{
    padding: 0 20px;
}
</style>