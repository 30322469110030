import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home/homeView.vue'
import Index1 from '../views/home/index1.vue'
import Search from '../views/search/search.vue'
import Calculator from '../views/calculator/calculator.vue'
import Category from '../views/category/category.vue'
import ShopCart from '../views/shopCart/shopCart'
import My from '../views/my/my'
import Order from '../views/order/order.vue'
import Integral from '../views/integral/integral'
import News from '../views/news/news'
import News_detail from '../views/news_detail/news_detail'
import Address_gl from '../views/address_gl/address_gl'
import AddressSave from '../views/address_gl/addressSave'
import Coupon from '../views/coupon/coupon'
import Personal_information from '../views/personal_information/personal_information'
import OrderDetails from '../views/order/orderDetails'
import MyCollection from '../views/my/myCollection'
import Goods from '../views/goods/goods'
import GoodsList from '../views/goods/goodsList'
import Company from '../views/company/company'
import Login from '../views/login/login'
import PlaceOrder from '../views/order/placeOrder'
import JpGoods from '../views/goods/jpGoods'
import Register from '../views/login/register'
import Member from '../views/member/index'
import Hygz from '../views/member/hygz'
import Tczy from '../views/tczy/tczy'
import Qyjs from '../views/qyjs/qyjs'
import Tgtj from '../views/tgtj/tgtj'
import Sygl from '../views/sygl/sygl'
import Message from '../views/message/message'
import FreightArticle from '../views/order/freightArticle'
import Article from '../views/article/index'
import GoodsFeedback  from '../views/goods_feedback/index'
import GoodsFeedbackList  from '../views/goods_feedback/goodsFeedList'
import MultiGoods  from '../views/goods_feedback/multiGoods'
import ClearCache  from '../views/my/clearCache'
import Activity1  from '../views/activity_page/activity1'
import Activity2  from '../views/activity_page/activity2'
//零食馆
import Activity3  from '../views/activity_page/activity3'
// 美食馆
import Activity4  from '../views/activity_page/activity4'
import FriendPayment  from '../views/order/friendPayment'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
}

const routes = [
 
  {
    path: '/index2',
    name: 'home',
    component: HomeView,
    meta: {

      keepAlive: true

    },
  },
  {
    path: '/',
    name: 'index1',
    component: Index1,
    meta: {

      keepAlive: true

    },
  },
  
  {//会员规则
    path: '/hygz',
    name: 'hygz',
    component: Hygz
  },
  {//搜索
    path: '/search',
    name: 'search',
    component: Search
  },
  {//汇率计算器
    path: '/calculator',
    name: 'calculator',
    component: Calculator
  },
  {//分类
    path: '/category',
    name: 'category',
    component: Category,
    meta: {
      keepAlive: true
    },
  },

  
  {//购物车
    path: '/shopCart',
    name: 'shopCart',
    component: ShopCart
  },
  {//我的
    path: '/my',
    name: 'my',
    component: My
  },
  {//订单列表
    path: '/order',
    name: 'order',
    component: Order,
    meta: {
      keepAlive: true
    },
  },
  {//积分
    path: '/integral',
    name: 'integral',
    component: Integral
  },
  {//消息
    path: '/news',
    name: 'news',
    component: News
  },
  {//消息详情
    path: '/news_detail',
    name: 'news_detail',
    component: News_detail
  },
  {//地址管理
    path: '/address_gl',
    name: 'address_gl',
    component: Address_gl
  },
  {//编辑地址
    path: '/addressSave',
    name: 'addressSave',
    component: AddressSave
  },
  
  {//优惠券
    path: '/coupon',
    name: 'coupon',
    component: Coupon
  },
  {//个人资料
    path: '/personal_information',
    name: 'personal_information',
    component: Personal_information
  },
  {//订单详情
    path:'/orderDetails',
    name:'orderDetails',
    component:OrderDetails
  },
  {//我的收藏
    path:'/myCollection',
    name:'myCollection',
    component:MyCollection
  },
  {//商品详情
    path:'/goods',
    name:'goods',
    component:Goods
  },
   {//商品列表
    path:'/goodsList',
    name:'goodsList',
    component:GoodsList,
    meta: {
      keepAlive: true,
      saveSrollTop:true
    },
  },
  
  
  {//订单详情
    path:'/company',
    name:'company',
    component:Company
  },
  {//登陆
    path:'/login',
    name:'login',
    component:Login
  },
  {//下单
    path:'/placeOrder',
    name:'placeOrder',
    component:PlaceOrder
  },
  {//日本代购
    path:'/jpGoods',
    name:'jpGoods',
    component:JpGoods
  },
  {//注册
    path:'/register',
    name:'register',
    component:Register
  },
  {//我的等级
    path:'/member',
    name:'member',
    component:Member
  },
  {//天成转运
    path:'/tczy',
    name:'tczy',
    component:Tczy
  },
  {//企业介绍
    path:'/qyjs',
    name:'qyjs',
    component:Qyjs
  },
  {//推广统计
    path:'/tgtj',
    name:'tgtj',
    component:Tgtj
  },
  {//收益管理
    path:'/sygl',
    name:'sygl',
    component:Sygl
  },
  {//聊天页面
    path:'/message',
    name:'message',
    component:Message
  }
  ,
  {//运费、税费规则文章
    path:'/freightArticle',
    name:'freightArticle',
    component:FreightArticle
  },
  {//文章内容页
    path:'/article',
    name:'aritcle',
    component:Article
  },
   {//商品评价
    path:'/goodsFeedback',
    name:'goodsFeedback',
    component:GoodsFeedback
  },
  {//订单多商品评价
    path:'/multiGoods',
    name:'multiGoods',
    component:MultiGoods
  },
  {//商品评价列表
    path:'/goodsFeedbackList',
    name:'goodsFeedbackList',
    component:GoodsFeedbackList
  },
  {
    path:'/clearCache',
    name:'clearCache',
    component:ClearCache
    
  },
  {//活动页1(美妆)
    path:'/activity1',
    name:'activity1',
    component:Activity1,
    meta: {
      keepAlive: true,
      saveSrollTop:true
    },
  },
  {//活动页2(生活馆)
    path:'/activity2',
    name:'activity2',
    component:Activity2,
    meta: {
      keepAlive: true,
      saveSrollTop:true
    },
  },
  {//好友代付
    path:'/friendPayment',
    name:'friendPayment',
    component:FriendPayment,
    beforeRouteEnter(to, from, next) {
      // 在进入路由之前触发
      next(vm => {
        // 使用$forceUpdate来强制更新组件
        vm.$forceUpdate();
      });
    }
    
  },
  {//活动页3(零食馆)
    path:'/activity3',
    name:'activity3',
    component:Activity3,
    meta: {
      keepAlive: true,
      saveSrollTop:true
    },
  },
  {//活动页3(零食馆)
    path:'/activity4',
    name:'activity4',
    component:Activity4,
    meta: {
      keepAlive: true,
      saveSrollTop:true
    },
  }       
  
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (from.meta.saveSrollTop) {
        from.meta.savedPosition = document.documentElement.scrollTop || document.body.scrollTop;
      }
      return {x: 0, y: to.meta.savedPosition || 0};
    }
  },
  routes,
})

export default router
