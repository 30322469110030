<template>
    <div class="search">
        <div class="top">
            <head-top title="搜索" left_ico="1"></head-top>
            <van-search v-model="searchValue" show-action placeholder="搜索商品" shape="round" @search="onSearch" :left-icon="left_icon_img" background="linear-gradient(90deg, #2866ff 0%, #5488ff 100%)">
                <template #action>
                    <div @click="onSearch">搜索</div>
                </template>
            </van-search>
        </div>
        <div class="content">
            <div class="content-top">
                <span class="title">搜索记录</span>
                <img class="search_del" src="../../static/image/search_del.png" alt="" @click="delBrandVal" />
            </div>
            <div class="keyword_body">
                <div class="keyword" v-for="(item,index) in searchValueArr" @click="clickKeyword(item)">{{item}}</div>
            </div>

            <van-divider :style="{
                    color: '#1989fa',
                    borderColor: '#eee',
                    padding: '0 16px',
                }" />
            <div class="content-top">
                <span class="title">推荐</span>
            </div>
            <div class="keyword_body">
                <div class="keyword" v-for="(item,index) in brandList" @click="clickKeyword(item.name)">{{item.name}}</div>

            </div>
        </div>
    </div>
</template>

<script>
import HeadTop from "@/components/HeadTop.vue";
export default {
    name: "HomeView",
    components: {
        HeadTop,
    },
    data() {
        return {
            left_icon_img: require("../../static/image/search_left_icon.png"),
            searchValue: '',
            searchValueArr:[],
            brandList:{}
        };
    },
    created(){
        this.searchValueArr = JSON.parse(localStorage.getItem('searchValue'));
        this.getBrandList()
    },
    methods: {
        onSearch() {
            if(this.searchValue){
                // console.log(localStorage.getItem('searchValue'))
                let arr = localStorage.getItem('searchValue')
                if(!arr){
                     arr = []
                }else{
                     arr = JSON.parse(localStorage.getItem('searchValue'));
                }
                arr.unshift(this.searchValue)
                if(Object.getOwnPropertyNames(arr).length>6){
                    arr.pop()
                }
                localStorage.setItem('searchValue', JSON.stringify(arr))
            }
            this.searchValueArr = JSON.parse(localStorage.getItem('searchValue'));
            if(this.searchValue){
                this.toGoodsList()
            }
            
        },
        toGoodsList() {
            let keyword = this.searchValue
            this.$router.push({ path: "/goodsList", "query": { keyword } })
            this.$router.go(0)
        },
        clickKeyword(val){
            this.$router.push({ path: "/goodsList", "query": { keyword:val } })
        },
        getBrandList() {
            let that = this
            that.$request('/api/brandList', {type:2})
                .then(res => {
                    // console.log(res.data.data)
                    that.brandList = res.data.data

                })
        },
        delBrandVal(){
            localStorage.setItem('searchValue', [])
            this.searchValueArr = []
        }
    }
};
</script>
<style src="./search.css" scoped>
</style>
<style scoped>
.head_top {
    padding-bottom: 0px !important;
}
/deep/.van-search__content {
    background-color: #fff !important;
}
/deep/.van-search__action {
    color: #fff !important;
}
/deep/.van-field__left-icon {
    margin: auto;
    padding-right: 10px;
}
/deep/.van-search__action:active {
    background: linear-gradient(
        90deg,
        rgb(255, 68, 143) 0%,
        rgb(255, 102, 172) 100%
    );
}
body {
    background-color: #fff !important;
}
</style>