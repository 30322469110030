<template>
    <div>
        <head-top-white title="订单详情" left_ico="1" :url='toUrl'></head-top-white>
        <div class="head">
            <img src="../../static/image/order_dfk.png" alt="" />
            <div class="head_right" v-if="orderDetail.order.order_status==-1">
                <div class="tips">订单已取消</div>
            </div>
            <div class="head_right" v-if="orderDetail.order.order_status==1">
                <div class="tips">待付款 请联系客服 完成付款</div>
                <div class="time">{{orderDetail.order.create_time}}</div>
            </div>
            <div class="head_right" v-if="orderDetail.order.order_status==2">
                <div class="tips">商家待发货，请耐心等待</div>
                <div class="time">{{orderDetail.order.deliver_goods_time}}</div>
            </div>
            <div class="head_right" v-if="orderDetail.order.order_status==3">
                <div class="tips">买家已确认收货</div>
                <div class="time">{{orderDetail.order.confirm_receipt_time}}</div>
            </div>
            <div class="head_right" v-if="orderDetail.order.order_status==4">
                <div class="tips">买家待评价</div>
            </div>
           <div class="head_right" v-if="orderDetail.order.order_status==5">
                <div class="tips">订单已完成</div>
                <div class="time">{{orderDetail.order.confirm_receipt_time}}</div>
            </div>
        </div>
        <van-steps :active="progress" active-color="#4c83ff">
            <van-step>待付款</van-step>
            <van-step>待发货</van-step>
            <van-step>待收货</van-step>
            <van-step>待评价</van-step>
            <van-step>已完成</van-step>
        </van-steps>
         <div class="card_white mt30 address wuliuicon" v-if="orderDetail.order.order_status>=3">
            <div>
                <img class="ml20 wl_left_ico" src="../../static/image/wuliu.png" alt="" />
            </div>
            <div class="mt30 ml30 mb30 wltext" @click="wlshow=true">
                <div v-if="orderDetail.logistics">{{orderDetail.logistics[0].tracking_detail}}</div>
                <div class="right"><img class="ml20" src="../../static/image/more_icon.png" alt="" /></div>
            </div>
        </div>
        <van-popup v-model="wlshow" position="bottom" :style="{ height: '50%' }" v-if="orderDetail.logistics">
            <van-steps direction="vertical" :active="0">
            <van-step v-for="item in orderDetail.logistics">
                <p>{{item.tracking_detail}}</p>
                <p v-if="item.checkpoint_date">{{item.checkpoint_date}}</p>
            </van-step>
            </van-steps>
        </van-popup>
        <div class="card_white mt30 address">
            <div>
                <img class="ml20 mt40" src="../../static/image/order_loc.png" alt="" />
            </div>
            <div class="mt30 ml30 mb30">
                <div class="name">{{orderDetail.order.name}} {{orderDetail.order.phone}}</div>
                <div class="detailed_address">
                    {{orderDetail.order.province+" "+orderDetail.order.city+" "+orderDetail.order.area+" "+orderDetail.order.address}}
                </div>
            </div>
        </div>
        <div class="card_white goods mt20">
            <div class="title ml20">共{{orderDetail.goods_quantity}}件商品</div>
            <order-goods-details v-for="(item,index) in orderDetail.goods" :img="$imgUrl+item.img_url" :goods_id="item.goods_id" :title="item.goods_title" :price="item.price" :specs="item.sku" :number="item.quantity" price_colour="1" />
        </div>
        <!-- <div class="card_white mt20 contact" v-if="orderDetail.order.order_status==1">
            <img class="left_tips" src="../../static/image/order_detail_left_tips.png" alt="" />
            <span class="left_tips_text">添加下面微信，联系客服，去完成付款</span>
            <div class="phone mt20">
                <span>微信：{{orderDetail.kf_wechat}}</span>
                <van-button type="primary" size="mini" color="#4c83ff" round class="btn" @click="seccendCopy(1)">复制</van-button>
            </div>
            <div class="phone mt20">
                <span>电话：{{orderDetail.kf_phone}}</span>
                <van-button type="primary" size="mini" color="#4c83ff" round class="btn" @click="seccendCopy(2)">复制</van-button>
            </div>
        </div> -->
        <div class="card_white order_status mt20" v-if="orderDetail.order.order_status>=3">
            <div class="text" v-if="orderDetail.order.logistics_name">
                <span class="left_text">物流名称：</span>
                <span class="right_text">{{orderDetail.order.logistics_name}}</span>
            </div>
            <div class="text" v-if="orderDetail.order.logistics_number">
                <span class="left_text mt30">物流单号：</span>
                <span class="right_text" >{{orderDetail.order.logistics_number}} | <span style="color:#222" @click="seccendCopy(3)">复制</span></span>
            </div>
            
            <div class="text" v-if="!orderDetail.order.logistics_number">
                <span class="left_text mt30">物流单号：</span>
                <span class="right_text" >您的订单已发货，国际快递单号申请中 </span>
            </div>
            <div class="text" v-if="orderDetail.order.other_logistics_nunber" v-for="item in orderDetail.order.other_logistics_nunber">
                <span class="left_text mt30">{{item[0]}}</span>
                <span class="right_text" >{{item[1]}}</span>
            </div>
        </div>
        <div class="card_white order_status mt20">
            <div class="text">
                <span class="left_text">订单号：</span>
                <span class="right_text">{{orderDetail.order.number}}</span>
            </div>
            <div class="text">
                <span class="left_text mt30">下单时间：</span>
                <span class="right_text">{{orderDetail.order.create_time}}</span>
            </div>
            <div class="text">
                <span class="left_text mt30">支付状态：</span>
                <span class="right_text" v-if="orderDetail.order.order_status==-1">已取消</span>
                <span class="right_text" v-if="orderDetail.order.order_status==1">待支付</span>
                <span class="right_text" v-if="orderDetail.order.order_status==2">待发货</span>
                <span class="right_text" v-if="orderDetail.order.order_status==3">待收货</span>
                <span class="right_text" v-if="orderDetail.order.order_status==4">待评价</span>
                <span class="right_text" v-if="orderDetail.order.order_status==5">已完成</span>
            </div>
            <!-- <div class="text">
                <span class="left_text mt30">支付方式：</span>
                <span class="right_text">微信支付</span>
            </div> -->
            <div class="price">
                <div class="price_left">
                    <div >运费</div>
                    <div class="ico_style" @click="toFreightArticle()"> <van-icon name="question-o" /></div>
                </div>
                <div class="price_right">
                    <div>{{orderDetail.order.freight}}日元</div>
                </div>
            </div>
            <!-- <div class="text" v-if="orderDetail.order.freight">
                <span class="left_text mt30">运费：</span>
                <span class="right_text">{{orderDetail.order.freight}}人民币</span>
            </div> -->
            <div class="text" v-if="orderDetail.order.member_discount">
                <span class="left_text mt30">会员折扣：</span>
                <span class="right_text">-{{orderDetail.order.member_discount}}日元</span>
            </div>
             <div class="text">
                <span class="left_text mt30">备注：</span>
                <span class="right_text">{{orderDetail.order.note}}</span>
            </div>
        </div>
        <div class="card_white order_status mt20">
            <div class="text">
                <span class="left_text">商品总价：</span>
                <span class="right_text">{{orderDetail.order.total_price}}日元</span>
            </div>
            <!-- <div class="text">
                <span class="left_text mt30">积分抵扣：</span>
                <span class="right_text">100</span>
            </div> -->
            <div class="text">
                <span class="left_text mt30">实付款：</span>
                <span class="right_text textColor1">{{orderDetail.order.price}}日元<span class="textColor2">（{{'约'+orderDetail.order.rmb_money}}）</span></span>
            </div>
        </div>
        <div class="operation" v-if="orderDetail.order.order_status==1">
            <van-button type="primary" size="small" color="#4c83ff" round  class="btn" v-if="payBtn" @click="orderPay(orderDetail.order.id)">立即支付</van-button>
            <van-button type="primary" size="small" color="#999999" round plain class="btn" @click="cancelOrder(orderDetail.order.number)">取消订单</van-button>
        </div>
        <van-overlay :show="btnShow" >
                <div class="wrapper">
                    <van-loading size="24px" vertical>跳转支付过程会有短暂的空白页，请耐心等待一下</van-loading>
                </div>
        </van-overlay>
        <div style="height: 80px"></div>
    </div>
</template>

<script>
import HeadTopWhite from "@/components/HeadTopWhite.vue";
import OrderGoodsDetails from "@/components/OrderGoodsDetails.vue";
import Vue from 'vue';
import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard)
export default {
    components: {
        HeadTopWhite,
        OrderGoodsDetails,
    },
    data() {
        return {
            progress: 0,//订单状态
            number:0,//订单号
            orderDetail:{},
            btnShow: false, //提交后的遮罩层
            toUrl:localStorage.getItem('previous_url'),
            wlshow:false,
            payBtn:true
        };
    },
    created(){
        if(localStorage.getItem('previous_url')=='/my'){
            this.toUrl=='-1'
        }
        
        console.log(localStorage.getItem('previous_url')=='/my')
        this.token = localStorage.getItem('token');
        this.number = this.$route.query.number
        this.getOrderDetail()
        localStorage.setItem('orderStatusChange', -998)
    },
    methods: {
        orderPay(order_id){
            this.payBtn = false
            var agent = 'H5';
            var userAgent = window.navigator.userAgent.toLowerCase(); 
            if (userAgent.match(/MicroMessenger/i) == "micromessenger") {
                // console.log('微信');
                agent = 'weixin'
            }
            if (userAgent.match(/Alipay/i) == "alipay") {
                // agent = 'weixin'
            }
            // return;

            let that = this
            that.btnShow = true
            let data = {
                    order_id,
                    agent,
                    token:this.$store.state.token
                }
            
             that.$request('/api/orderPayment',data )
                .then(res => {
                    // console.log(res)
                    if(res.data.code==1){
                        window.location.href = res.data.data.url
                        // that.orderList.remove(index,1)
                        // this.$toast('确认收货成功');
                        // that.orderList.data.splice(index,1)
                    }
            })
        },
        //复制 type 1微信2电话
        seccendCopy(type) {
            let that = this
            let val = ''
            if(type==1){
                val = that.orderDetail.kf_wechat
            }
            if(type==2){
                val = that.orderDetail.kf_phone
            }
            if(type==3){
                val = that.orderDetail.order.logistics_number
            }
            this.$copyText(val).then(
                function (e) {
                    // console.log("copy arguments e:", e);
                    that.$toast('复制成功');
                },
                function (e) {
                    // console.log("copy arguments e:", e);
                    that.$toast('复制失败，请长按手动复制');
                }
            );
        },
        getOrderDetail() {
           
            let that = this
            this.$request('/api/getOrderDetail', {
                number:that.number,
                token:that.token
                })
                .then(res => {
                    that.orderDetail = res.data.data
                    that.progress = res.data.data.order.order_status-1
                    // console.log(res.data.data)

                })
        },
        // 取消订单
        cancelOrder(number) {
            // console.log(number)
            // return;
            this.$request('/api/cancelOrder', {
                number,
                token: this.$store.state.token
            })
            .then(res => {
                localStorage.setItem('orderStatusChange',-3);
                this.$toast('取消成功')
                this.getOrderDetail()
            })
        },
        toFreightArticle(){
            this.$router.push("/freightArticle");
        },

    }
};
</script>

<style src="./orderDetails.css" scoped>

</style>
<style scoped>
/deep/ .van-step__icon--active{
    color:#666;
}
/deep/ .van-step__title--active{
    color:#666;
}
</style>