<template>
    <div class="list_body">
        <div class="goods_card" v-for="item in goodsList"  @click="toGoods(item.id)">
            <img v-lazy="$imgUrl+item.img_url"   v-if="!item.thumbnail">
            <img v-lazy="$imgUrl+item.thumbnail" v-if="item.thumbnail">
            <img class="sellout" v-lazy="$sellOut" v-if="item.sellOut==2">
               <div class="divider"></div>
               <div class="goods_title">{{item.title}}</div>
               <div class="price">≈ RMB: ￥{{item.price.money1}}</div>
        </div>
     
    </div>
</template>

<script>
export default {

    data() {
        return {
         
        };
    },
    props:["activityId","goodsList"],

    created() {

    },
    methods: {
        getActivityGoodsList(){
            this.$request('/api/getActivityGoodsList', {activity_id:this.activityId})
            .then(res => {
                // this.goods_list = res.data.data
                // console.log(res.data.data)
            })
        },
        toGoods(goodsId) {
            this.$router.push({path:"/goods","query":{goodsId}})
        },
    },
};
</script>

<style scoped>
.list_body{
    width: 733px;
    background-color: #D6D4D4;
    height: 100%;
    margin: 35px auto 0 auto;
    border-radius: 20px;
    display: flex;
    padding: 0 0 16px 0;
    flex-wrap: wrap; 
    justify-content: space-evenly;
}
.list_body .goods_card{
    background-color: #fff;
    width: 226px;
    margin-top: 16px;
    border-radius: 10px;
    
    
}
.goods_card .sellout{
    margin-top: -226px;
}
.goods_card img{
    width: 226px;
    height: 226px;
    margin-bottom: 18px;
}
.divider{
    width: 182px;
    height: 2px;
    background: #ccc;
    border-radius: 1px;
    margin: 0 auto;
}
.goods_title{
    overflow: hidden;
    text-overflow:ellipsis;
    display:-webkit-box;
    -webkit-line-clamp:2;
    -webkit-box-orient:vertical; 
    padding: 0 10px;
    margin-top: 12px;
    color: #888888;
    font-weight: lighter;
}
.price{
    width: 227px;
    height: 55px;
    background: #D7343A;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-top: 18px;
    color: #fff;
    font-size: 22px;
    line-height: 55px;
    text-align: center;
}
</style>