<template>
  <div>
      <nav-bar></nav-bar>
       <div class="head">
            <div class="user_info">
                <div class="user_left">
                    <img src="https://static.ripinhui.shop/static/i/2023/10/12/5524245909644254469.png" alt="">
                </div>
                <div class="user_right">
                    <div class="name">
                        日品汇
                    </div>
                    <div class="vip">
                        <img src="https://static.ripinhui.shop/static/i/2023/10/12/5524150316850748084.png" alt="">
                    </div>
                </div>
            </div>
            <div class="statistics">
                <div  @click="toOrderList(-2)">
                    订单 <span class="digit">{{userInfo.order_count}}</span>
                </div>
                <!-- <div>
                    优惠券 <span class="digit">0</span>
                </div> -->
                <div @click="toUrl('/myCollection')">
                    收藏 <span class="digit">{{userInfo.collection}}</span>
                </div>
                <div>
                    足迹 <span class="digit">0</span>
                </div>
            </div>
       </div>
       <!-- 余额 -->
       <div class="balance">
            <div class="top_ico">
                <div class="left_ico">
                    <img src="https://static.ripinhui.shop/static/i/2023/10/12/5524154828923605412.png" alt="">
                    <span class="title">余额</span>
                </div>
                <div class="right_ico">
                    <img src="https://static.ripinhui.shop/static/i/2023/10/12/5524246332480428602.jpg" alt="">
                </div>
            </div>
            <div class="balance_text_body" @click="toUrl('/sygl')">
                <span class="text1" >我的余额</span>
                <span class="text2">{{userInfo.money}}</span>
            </div>
       </div>
       <!-- 我的订单 -->
       <div class="order">
            <div class="order_top">
                <div class="left_text">我的订单</div>
                <div class="right_text" @click="toOrderList(-2)">查看全部 ></div>
            </div>
            <div class="fgx"></div>
            <div class="navbar_body">

           <van-badge :content="userInfo.order_count_arr[1]" >
                <div class="navbar" @click="toOrderList(0)">
                    <div class="navbar_ico"><img src="https://static.ripinhui.shop/static/i/2023/10/14/5524858492944389832.jpg" alt=""></div>
                    <div class="navbar_text">待付款</div>
                </div>
            </van-badge>
            <van-badge :content="userInfo.order_count_arr[2]" >
                <div class="navbar" @click="toOrderList(1)">
                    <div class="navbar_ico"><img src="https://static.ripinhui.shop/static/i/2023/10/14/5524862416950665057.jpg" alt=""></div>
                    <div class="navbar_text">待发货</div>
                </div>
            </van-badge>
            <van-badge :content="userInfo.order_count_arr[3]" >
                <div class="navbar" @click="toOrderList(2)">
                    <div class="navbar_ico"><img src="https://static.ripinhui.shop/static/i/2023/10/14/5524862510991154333.jpg" alt=""></div>
                    <div class="navbar_text">待收货</div>
                </div>
            </van-badge>
            <van-badge :content="userInfo.order_count_arr[4]" >
                <div class="navbar" @click="toOrderList(3)">
                    <div class="navbar_ico"><img src="https://static.ripinhui.shop/static/i/2023/10/14/5524862596903082754.jpg" alt=""></div>
                    <div class="navbar_text">待评价</div>
                </div>
            </van-badge>
          
                <div class="navbar" @click="toOrderList(4)">
                    <div class="navbar_ico"><img src="https://static.ripinhui.shop/static/i/2023/10/14/5524862671830130323.jpg" alt=""></div>
                    <div class="navbar_text">已完成</div>
                </div>
        
             </div>
       </div>
       <div class="serve">
         <div class="serve_top">
                <div class="left_text">我的服务</div>
            </div>
            <div class="fgx"></div>
            <div class="navbar_body">
                <div class="navbar">
                    <div class="navbar_ico" @click="toUrl('/myCollection')"><img src="https://static.ripinhui.shop/static/i/2023/10/14/5524864927409377577.jpg" alt=""></div>
                    <div class="navbar_text" @click="toUrl('/myCollection')">我的收藏</div>
                </div>
                <div class="navbar">
                    <div class="navbar_ico" @click="toUrl('/address_gl')"><img src="https://static.ripinhui.shop/static/i/2023/10/14/5524865209337912358.jpg" alt=""></div>
                    <div class="navbar_text" @click="toUrl('/address_gl')">地址信息</div>
                </div>
                <div class="navbar">
                    <div class="navbar_ico"  @click="toUrl('/member')"><img src="https://static.ripinhui.shop/static/i/2023/10/14/5524866066985000131.png" alt=""></div>
                    <div class="navbar_text" @click="toUrl('/member')">我的等级</div>
                </div>
                <div class="navbar">
                    <div class="navbar_ico"  @click="toUrl('/personal_information')"><img src="https://static.ripinhui.shop/static/i/2023/10/14/5524865312035444821.jpg" alt=""></div>
                    <div class="navbar_text"  @click="toUrl('/personal_information')">个人资料</div>
                </div>
            </div>
            
       </div>
       <div class="distribution">
         <div class="serve_top">
                <div class="left_text">分销管理</div>
            </div>
            <div class="fgx"></div>
            <div class="navbar_body">
                <div class="navbar">
                    <div class="navbar_ico" @click="toUrl('/sygl')"><img src="https://static.ripinhui.shop/static/i/2023/10/14/5524867894166425831.jpg" alt=""></div>
                    <div class="navbar_text" @click="toUrl('/sygl')">收益管理</div>
                </div>
                <div class="navbar">
                    <div class="navbar_ico" @click="toUrl('/tgtj')"><img src="https://static.ripinhui.shop/static/i/2023/10/14/5524867983928725753.jpg" alt=""></div>
                    <div class="navbar_text" @click="toUrl('/tgtj')">推广统计</div>
                </div>
                <div class="navbar">
                    <div class="navbar_ico" @click="qrShow=true"><img src="https://static.ripinhui.shop/static/i/2023/10/14/5524868044641279289.jpg" alt=""></div>
                    <div class="navbar_text" @click="qrShow=true">分销二维码</div>
                </div>
            </div>
            
       </div>
       <!-- 二维码弹出层 -->
        <van-overlay :show="qrShow" >
            <div class="wrapper" @click="qrShow=false" >
                <vue-qr  :text="qrtext" :size="260"></vue-qr>
            </div>
        </van-overlay>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import VueQr from 'vue-qr';
export default {
    components: {
        NavBar,
        VueQr
    },
    data(){
        return{
          qrShow:false,
          qrtext:'',
          userInfo:{}
        }
    },
    computed:{
        // getToken(){
        //     return this.$store.state.token
        // }
    },
    created(){
        this.qrtext = 'http://'+window.location.host+'/?aff='+this.$store.state.uid
        this.getUserInfo()
    },
    methods:{
        cloneQrShow(){
            this.qrShow = false
            
        },
        toUrl(link){
          this.$router.push(link);
        },
        toOrderList(orderStatus){
            localStorage.setItem('orderStatusChange',orderStatus);
            localStorage.setItem('previous_url', '-1') //订单详情判断返回上一页还是返回订单列表
            this.$router.push("/order");
        },
        getUserInfo(){
            this.$request('/api/getUserInfo', {
                token: this.$store.state.token
            })
            .then(res => {
                console.log(res.data.data)
                this.userInfo = res.data.data
            })
        },
    }
}
</script>

<style src="./my.css" scoped>
</style>
<style scoped>
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
/deep/.navbar_body .van-badge--fixed{

    top: 15px;
    right: 12px;

}
</style>