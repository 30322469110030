<template>
    <div>
        <nav-bar></nav-bar>
        <div class="head_top">
            <span class="title">购物车</span>
            <span class="right_btn" @click="update" v-if="!updateVal">管理</span>
            <span class="right_btn" @click="update" v-if="updateVal">取消</span>
        </div>
        <div class="head_top_ico">
            <div>
                <img class="shop_cart_ico" src="../../static/image/shop_cart_ico1.png" alt="" />
                <span>100%正品保证</span>
            </div>
            <div>
                <img class="shop_cart_ico" src="../../static/image/shop_cart_ico2.png" alt="" />
                <span>所有商品精挑细选</span>
            </div>
            <div>
                <img class="shop_cart_ico" src="../../static/image/shop_cart_ico3.png" alt="" />
                <span>售后无忧</span>
            </div>
        </div>
        <div class="goods" v-for="(item,index) in shopCartList">
            <van-row class="goods_body">
                <van-col span="3">
                    <van-checkbox v-model="item.click" checked-color="#4c83ff" v-if="item.sellOut==1"> </van-checkbox>
                </van-col>
                <van-col span="7" @click="toGoods(item.goods_id)">
                    <img class="goods_img" :src="$imgUrl+item.img_url" alt="" srcset="" v-if="item.specs_type==1" />
                    <img class="goods_img" :src="$imgUrl+item.sku_thumbnail" alt="" srcset="" v-if="item.specs_type==2" />
                    <img class="goods_img2" :src="$sellOut" alt="" srcset="" v-if="item.sellOut==2" />
                </van-col>
                <van-col span="14">
                    <div class="goods_right_top" @click="toGoods(item.goods_id)">
                        {{item.title}}
                    </div>
                    <div class="goods_right_middle" v-if="item.specs_type==1">&nbsp</div>
                    <div class="goods_right_middle" @click="toGoods(item.goods_id)" v-if="item.specs_type==2"> {{item.goods_specs_val}}</div>
                    <div class="goods_right_bottom">
                        <span class="price" v-if="item.specs_type==1">{{item.price}}日元</span>
                        <span class="price" v-if="item.specs_type==2">{{item.sku_goods_price}}日元</span>
                        <van-stepper @change="updateShopChatNumber(item.quantity,item.id)" v-model="item.quantity" />
                    </div>
                </van-col>
            </van-row>
        </div>
        <div class="gbottom"></div>
        <!-- 底部下单框 -->
        <div class="place_order">
            <van-row v-if="updateVal">
                <van-col span="15">
                    <van-checkbox class="box" v-model="checkedAll" checked-color="#4c83ff" @click="selectAll">全选</van-checkbox>
                    <!-- <img src="../../static/image/shop_cart.png" alt="" /> -->
                </van-col>
                <van-col span="4">
                    <!-- <van-button type="primary" size="small" color="#4c83ff" round plain class="btn">收藏</van-button> -->
                </van-col>
                <van-col span="5">
                    <van-button type="primary" size="small" color="#999999" round plain class="btn" @click="delShopCart">删除</van-button>
                </van-col>
            </van-row>
            <van-row v-if="!updateVal">
                <van-col span="13">
                    <van-checkbox class="box" v-model="checkedAll" checked-color="#4c83ff" @click="selectAll">全选</van-checkbox>
                    <!-- <img src="../../static/image/shop_cart.png" alt="" /> -->
                </van-col>
                <van-col span="5">
                    <span class="price">{{money}}日元</span>
                </van-col>
                <van-col span="6">
                    <van-button type="primary" size="small" color="#4c83ff" round class="btn" @click="placeOrder">立即下单</van-button>
                </van-col>
            </van-row>
        </div>
    </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";

export default {
    components: {
        NavBar,
    },
    data() {
        return {
            // checked: {
            //     checked1: false,
            //     checked2: true,
            // },
            checkedAll: false, //全选
            updateVal: false, //管理开关
            shopCartList: {},//商品列表
            money: 0,
            selectArr:[]//选中商品
        };
    },
    created() {
        // this.token = localStorage.getItem('token');
        this.token = this.$store.state.token
        this.getShopCartList()
        this.getUserInfo()
    },
    watch: {
        shopCartList: {
            handler(val) {
                //   console.log(val)
                this.updateShopCart(val)
            },
            deep: true
        },
    },
    methods: {  
        updateShopChatNumber(val,id){
            console.log(val);
            let that = this
            that.$request('/api/updateShopChatNumber', { 
                token: this.$store.state.token,
                 shop_cart_id:id,
                 number:val
                })
                .then(res => {
                    // console.log(res.data.data)
                    // for (let k in res.data.data) {
                    //     res.data.data[k]['click'] = false

                    // }
                    // that.shopCartList = res.data.data
                })
            // 
        },
        update() {
            this.updateVal = !this.updateVal;
        },
        toGoods(goodsId) {
            this.$router.push({path:"/goods","query":{goodsId}})
        },
        getShopCartList() {
            let that = this
            that.$request('/api/shopCartList', { token: this.$store.state.token })
                .then(res => {
                    // console.log(res.data.data)
                    for (let k in res.data.data) {
                        res.data.data[k]['click'] = false

                    }
                    that.shopCartList = res.data.data
                })
        },
        updateShopCart(val) {
            let money = 0;
            let selectArr = []
            for (let k in val) {
                if (val[k]['click'] == true) {
                    selectArr.push(val[k])
                    if (val[k]['specs_type'] == 1) {
                        money = money + val[k].price * val[k].quantity
                    }
                    if (val[k]['specs_type'] == 2) {
                        money = money + val[k].sku_goods_price * val[k].quantity
                    }
                }
            }
            this.money = money
            this.selectArr = selectArr
        },
        //全选
        selectAll() {
            // if(this.checkedAll = true)
            for (let k in this.shopCartList) {
                console.log(this.shopCartList[k])
                if(this.shopCartList[k].sellOut==1){
                    this.shopCartList[k]['click'] = this.checkedAll
                }  
                
            }
        },
        placeOrder(){
            // console.log(this.selectArr)
            // return;
            let data = []
            for (let k in this.selectArr) {
                // console.log(this.selectArr,'---');
                // return;
                if(this.selectArr[k].specs_type==2){
                    // console.log(JSON.parse(this.selectArr[k].goods_specs))
                    let sku = ''
                    let skuArr = JSON.parse(this.selectArr[k].goods_specs)
                    for(let item in skuArr)
                    {
                        sku = sku + ' ' + skuArr[item]
                    }
                    // console.log(sku);
                    // return;
                    data.push({
                        skuJson: this.selectArr[k].goods_specs,
                        sku,
                        quantity: this.selectArr[k].quantity,
                        goods_id: this.selectArr[k].goods_id,
                        goods_img: this.$imgUrl+this.selectArr[k].sku_thumbnail,
                        sku_price: this.selectArr[k].sku_goods_price,
                        goods_title: this.selectArr[k].title,
                        shop_cart:1,
                        shop_cart_id:this.selectArr[k].id,
                        weight:this.selectArr[k].weight
                    })
                }else{
                    data.push({
                        skuJson: false,
                        quantity: this.selectArr[k].quantity,
                        goods_id: this.selectArr[k].goods_id,
                        goods_img: this.$imgUrl+this.selectArr[k].img_url,
                        sku_price: this.selectArr[k].price,
                        goods_title: this.selectArr[k].title,
                        shop_cart:1,
                        shop_cart_id:this.selectArr[k].id,
                        weight:this.selectArr[k].weight
                    })
                }
                
            }
                 localStorage.setItem('place_order_data', JSON.stringify(data))
                this.$router.push("/placeOrder");
        },
        delShopCart(){
            let data = []
            for (let k in this.selectArr) {
                data.push(this.selectArr[k].id)
            }
            this.$dialog.confirm({
            title: '提示',
            message: '是否确认删除选中商品',
            })
            .then(() => {
               this.$request('/api/delShopCart', {
                shop_cart_id:data,
                token: this.$store.state.token
                })
                .then(res => {
                    this.$toast('删除成功')
                    this.updateVal=false
                    this.getShopCartList()

                })
            })
            .catch(() => {
                // on cancel
            });
            
            // console.log(data)
        },
        getUserInfo(){
            this.$request('/api/getUserInfo', {
                token: this.$store.state.token
            })
            .then(res => {
                localStorage.setItem('discount',res.data.data.discount)
            })
        }
    },
};
</script>
<style src="./shopCart.css" scoped>
</style>
<style  scoped>
.img-icon {
    height: 20px;
}
/deep/.van-checkbox__label {
    font-size: 28px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #000000;
}
</style>