import { render, staticRenderFns } from "./activity2.vue?vue&type=template&id=08bd8c20&scoped=true"
import script from "./activity2.vue?vue&type=script&lang=js"
export * from "./activity2.vue?vue&type=script&lang=js"
import style0 from "./activity2.css?vue&type=style&index=0&id=08bd8c20&prod&scoped=true&lang=css&external"
import style1 from "./activity2.vue?vue&type=style&index=1&id=08bd8c20&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08bd8c20",
  null
  
)

export default component.exports