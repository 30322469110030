<template>
    <div>
        <div class="menu">
            <div class="head">
                <img src="https://static.ripinhui.shop/static/i/2024/04/05/5588085874182069162.jpg" alt="">
                <img src="https://static.ripinhui.shop/static/i/2024/04/05/5588091314785751345.jpg" alt="">
            </div>
            <div class="menu_background">
                <!-- 茶分类 -->
                <div class="tea">
                    <div class="title">
                        <div class="title_left"><img src="https://static.ripinhui.shop/static/i/2024/04/06/5588421996867230563.png" alt=""></div>
                        <div class="title_right">茶類</div>
                    </div>
                    <div class="cate">
                        <img src="https://static.ripinhui.shop/static/i/2024/04/06/5588424558890720041.png" @click="toUrl('/goodsList?catagoryId=283')">
                        <img src="https://static.ripinhui.shop/static/i/2024/04/06/5588426603899133661.png" @click="toUrl('/goodsList?catagoryId=326')">
                        <img src="https://static.ripinhui.shop/static/i/2024/04/06/5588426664058033035.png" @click="toUrl('/goodsList?catagoryId=331')">
                        <img src="https://static.ripinhui.shop/static/i/2024/04/06/5588426753757418784.png" @click="toUrl('/goodsList?catagoryId=399')">
                    </div>

                </div>
                <div class="cate_bottom">
                    <img src="https://static.ripinhui.shop/static/i/2024/04/06/5588432838971103175.png" @click="toUrl('/goodsList?catagoryId=282')">
                </div>
                <!-- 蜂蜜分类 -->
                <div class="fm">
                    <div class="title">
                        <div class="title_left"><img src="https://static.ripinhui.shop/static/i/2024/04/06/5588433584986790145.png" alt=""></div>
                        <div class="title_right">はちみつ</div>
                    </div>
                    <div class="cate">
                        <img src="https://static.ripinhui.shop/static/i/2024/04/06/5588433463448441669.png" @click="toUrl('/goodsList?catagoryId=303')">
                    </div>

                </div>
                <div class="cate_bottom">
                    <img src="https://static.ripinhui.shop/static/i/2024/04/06/5588432838971103175.png" @click="toUrl('/goodsList?catagoryId=303')">
                </div>
                <!-- 粮油分类 -->
                <div class="ly">
                    <div class="title">
                        <div class="title_left"><img src="https://static.ripinhui.shop/static/i/2024/04/06/5588435699100553274.png" alt=""></div>
                        <div class="title_right">食糧と油 の副食</div>
                    </div>
                    <div class="cate1">
                        <img src="https://static.ripinhui.shop/static/i/2024/04/06/5588436634543593642.png" @click="toUrl('/goodsList?catagoryId=167')">
                        <img src="https://static.ripinhui.shop/static/i/2024/04/06/5588436704265507907.png" @click="toUrl('/goodsList?catagoryId=168')">
                        <img src="https://static.ripinhui.shop/static/i/2024/04/06/5588436745319358236.png" @click="toUrl('/goodsList?catagoryId=169')">
                        <img src="https://static.ripinhui.shop/static/i/2024/04/06/5588436985476816816.png" @click="toUrl('/goodsList?catagoryId=228')">
                        <img src="https://static.ripinhui.shop/static/i/2024/04/06/5588437031928732266.png" @click="toUrl('/goodsList?catagoryId=229')">
                        <img src="https://static.ripinhui.shop/static/i/2024/04/06/5588437085297053795.png" @click="toUrl('/goodsList?catagoryId=235')">
                        <img src="https://static.ripinhui.shop/static/i/2024/04/06/5588437128624217789.png" @click="toUrl('/goodsList?catagoryId=240')">
                        <img src="https://static.ripinhui.shop/static/i/2024/04/06/5588437161289454008.png" @click="toUrl('/goodsList?catagoryId=262')">
                        <img src="https://static.ripinhui.shop/static/i/2024/04/06/5588437192688013550.png" @click="toUrl('/goodsList?catagoryId=266')">
                        <img src="https://static.ripinhui.shop/static/i/2024/04/06/5588437234748493972.png" @click="toUrl('/goodsList?catagoryId=267')">
                        <img src="https://static.ripinhui.shop/static/i/2024/04/06/5588437272694364955.png" @click="toUrl('/goodsList?catagoryId=268')">
                        <img src="https://static.ripinhui.shop/static/i/2024/04/06/5588437314389940941.png" @click="toUrl('/goodsList?catagoryId=269')">
                        <img src="https://static.ripinhui.shop/static/i/2024/04/06/5588437356332982269.png" @click="toUrl('/goodsList?catagoryId=271')">
                        <img src="https://static.ripinhui.shop/static/i/2024/04/06/5588437393872002544.png" @click="toUrl('/goodsList?catagoryId=272')">
                        <img src="https://static.ripinhui.shop/static/i/2024/04/06/5588437431847230811.png" @click="toUrl('/goodsList?catagoryId=273')">
                        <img src="https://static.ripinhui.shop/static/i/2024/04/06/5588437458548168411.png" @click="toUrl('/goodsList?catagoryId=274')">
                        <img src="https://static.ripinhui.shop/static/i/2024/04/06/5588437496439509836.png" @click="toUrl('/goodsList?catagoryId=275')">
                        <img src="https://static.ripinhui.shop/static/i/2024/04/06/5588437546754381555.png" @click="toUrl('/goodsList?catagoryId=276')">

                    </div>
                    <div class="cate2">
                        <img src="https://static.ripinhui.shop/static/i/2024/04/06/5588437594024186377.png" @click="toUrl('/goodsList?catagoryId=392')">
                    </div>

                </div>
                <div class="cate_bottom">
                    <img src="https://static.ripinhui.shop/static/i/2024/04/06/5588432838971103175.png" @click="toUrl('/goodsList?catagoryId=153')">
                </div>
            </div>
        </div>
        <div class="goods_list">
            <div class="goods_bg">
                <div class="goods_body" v-for="(item,index) in goods.tea" @click="toUrl('/goods?goodsId='+item.id)">
                  <img class="goods_img" v-lazy="$imgUrl+item.img_url"   v-if="!item.thumbnail">
                  <img class="goods_img" v-lazy="$imgUrl+item.thumbnail" v-if="item.thumbnail">
                    <div class="goods_title">
                        {{item.title}}
                    </div>
                    <div class="goods_price">
                        ≈RMB:{{item.price_cny.money1}}
                    </div>
                </div>
                <div class="goods_footer" @click="toUrl('/goodsList?catagoryId=282')"></div>
            </div>
        </div>
        <div class="goods_list">
            <div class="goods_bg1">
                <div class="goods_body" v-for="(item,index) in goods.fm" @click="toUrl('/goods?goodsId='+item.id)">
                  <img class="goods_img" v-lazy="$imgUrl+item.img_url"   v-if="!item.thumbnail">
                  <img class="goods_img" v-lazy="$imgUrl+item.thumbnail" v-if="item.thumbnail">
                    <div class="goods_title">
                        {{item.title}}
                    </div>
                    <div class="goods_price">
                        ≈RMB:{{item.price_cny.money1}}
                    </div>
                </div>
                <div class="goods_footer" @click="toUrl('/goodsList?catagoryId=303')"></div>
            </div>
        </div>
        <div class="goods_list">
            <div class="goods_bg2">
                <div class="goods_body" v-for="(item,index) in goods.ly" @click="toUrl('/goods?goodsId='+item.id)">
                  <img class="goods_img" v-lazy="$imgUrl+item.img_url"   v-if="!item.thumbnail">
                  <img class="goods_img" v-lazy="$imgUrl+item.thumbnail" v-if="item.thumbnail">
                    <div class="goods_title">
                        {{item.title}}
                    </div>
                    <div class="goods_price">
                        ≈RMB:{{item.price_cny.money1}}
                    </div>
                </div>
                <div class="goods_footer" @click="toUrl('/goodsList?catagoryId=153')"></div>
            </div>
        </div>
        <div class="footer_goods">
            <div class="title_body">
                <div class="title">更多美食 尝鲜推荐</div>
                <div class="title2">その他のグルメ 初物を食べて推薦する.</div>
            </div>

        </div>
        <div class="goods_body_boottom"><img src="https://static.ripinhui.shop/static/i/2024/04/07/5588654737458205563.png" alt=""></div>
        <van-list
              v-model="loading"
              :finished="finished"
              offset="100"
              :immediate-check="false"
              @load="onLoad"
              >
        <activity-2-goods-list pricebg="price_bg17" titlecolor="goods_body_default" :goodslist="goodsList" />
        </van-list>
    </div>
</template>

<script>
import Activity2GoodsList from '../../components/Activity2GoodsList.vue';
export default {
  components: { Activity2GoodsList },
    data() {
        return {
          goods:{},
          goodsList:{},
          page: 1, //页码
          loading: false,//设置为 true，表示分页处于加载状态
          finished: false//	是否已加载完成，加载完成后不再触发load事件
        }
    },
    created() {
      this.getGoods()
      this.getGoodsList()
    },
    methods: {
      onLoad() {
            this.loading = true;
            this.getGoodsList()
    },
        toUrl(url) {
            this.$router.push(url);
        },
        getGoods() {
            let that = this
            that.$request('/api/hd4/getGoods', { token: this.$store.state.token })
                .then(res => {
                    that.goods = res.data.data
                })
        },
        getGoodsList() {
            let that = this
            that.$request('/api/hd4/getGoodsList', { token: this.$store.state.token })
                .then(res => {
                    if (that.page == 1) {
                        that.goodsList = res.data.data.data
                    }
                    this.loading = false;
                    that.goodsList = that.goodsList.concat(res.data.data.data)
                    if (res.data.data.data.length == 0) {
                        this.finished = true
                    }
                    that.page = that.page + 1
                })
        },
    }
}
</script>
<style src="./activity4.css" scoped>
</style>
<style>
</style>