<!--  -->
<template>
  <div>清除成功,点击左上角X关闭页面</div>
</template>

<script>
export default {
  data () {
    return {
    };
  },

  created() {
    localStorage.clear(); 
    window.close() 
  },

  methods: {}
}

</script>
<style  scoped>

</style>