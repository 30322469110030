<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <!-- <router-view/> -->
    <keep-alive>

      <router-view v-if="$route.meta.keepAlive"></router-view>

    </keep-alive>

     <router-view v-if="!$route.meta.keepAlive"></router-view>
     <!-- 右侧悬浮按钮 -->
     <move-div v-if="show"></move-div>
     <move-divs v-if="!show"></move-divs>
  </div>
</template>
<script>
import MoveDivs from './components/MoveDivs.vue'
export default {
    components: { MoveDivs },
    data(){
      return{
        show:true
      }
    },
    watch:{
      $route(to,from){
        // console.log(from.path);//从哪来
        ;//到哪去
        // if(to.path=='/calculator'){
        //   this.show=false
        // }else{
        //   this.show=true
        // }
        // if(to.path=='/register'){
        //   this.show=false
        // }
        if(to.path=='/login'||to.path=='/'){
          
          this.show=true
        }else{
          this.show=false
        }
        // if(to.path=='/'){
        //   this.show=false
        // }
        
      }
    },
    mounted(){
      this.$request('/api/numberOfVisits', {})
                .then(res => {
                  //  alert(1)
                })
    },
    created(){
      
      
      if(localStorage.getItem('aff')&&this.$store.state.uid){
    
        // this.$route.query.aff = this.$store.state.uid
      const query = JSON.parse(JSON.stringify(this.$route.query))
          // console.log(query)
          query.aff = this.$store.state.uid
        this.$router.push({path:this.$route.path,query});
          //  console.log(this.$route.query,'======')
      }
      // console.log(this.$route.query.aff,'----')
      // if(localStorage.getItem('aff')){

      // }
      // console.log(localStorage.getItem('aff'))
      // if(!localStorage.getItem('aff')){
      //   localStorage.setItem('aff',this.$route.query.aff)
      // }
      
      // if(this.$store.state.uid){
        
      // }
      // console.log(this.$route.path,'====')
      // if(this.$route.path=='/calculator'){
      //   this.show=false
      // }
      // if(this.$route.path=='/register'){
      //   this.show=false
      // }
      if(this.$route.path=='/login'||this.$route.path=='/'){
        this.show=true
      }else{
        this.show=false
      }
      // if(this.$route.path=='/'){
      //   this.show=false
      // }
    }
}
</script>
<style>

:-webkit-scrollbar {
     width: 0 !important;
   }
   ::-webkit-scrollbar {
     width: 0 !important;height: 0;
   }
/* @supports(bottom:env(safe-area-inset-bottom)){
  #app{
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }
} */
html{
  -webkit-text-size-adjust: none;
}
body {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50; */
  background-color: #f5f5f5;
  overflow-x: hidden;
  overflow-y: auto;
}
div{
  box-sizing: border-box;
}
/* 文字超出自动换行 */
.text_rows1{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.text_rows2{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.card_white{
  width: 690px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 20px 20px 20px 20px;
}
.mt30{
  margin-top: 30px!important;
}
.mt40{
  margin-top: 40px!important;
}
.mt20{
  margin-top: 20px!important;
}
.ml20{
  margin-left: 20px!important;
}
.mr20{
  margin-right: 20px!important;
}
.ml30{
  margin-left: 30px!important;
}
.mr30{
  margin-right: 30px!important;
}
.mb30{
  margin-bottom: 30px!important;
}
.van-list{
    display: flex;
    align-items: center;
    width: 100%;
    flex-flow: wrap;
    justify-content: space-between;
}
.van-list__finished-text{
    margin: 0 auto;
}
.van-list__loading{
    margin: 0 auto;
}
.gbottom{
  margin-bottom: 300px;
}
/* nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
