<template>
  <div class="tgtj">
     <head-top title="推广统计" left_ico="1" url="/my"></head-top>
     <div class="head">
       <div class="levl_one">
         <p class="title">1级用户：</p>
         <p class="count">{{userCount.level_one}}人</p>
       </div>
       <div class="levl_two">
         <p class="title">2级用户：</p>
         <p class="count">{{userCount.level_two}}人</p>
       </div>
     </div>
     <div class="list">
        <div class="list_head">
          <span>用户账号</span>
          <span>注册时间</span>
           <span>用户等级</span>
        </div>
        <div class="list_content van-hairline--bottom" v-for="(item,index) in userList">
          <div class="name">{{item.username}}</div>
          <div class="create_time">{{item.create_time}}</div>
          <div class="level1" v-if="item.level==1">{{item.level}}级</div>
          <div class="level2" v-if="item.level==2">{{item.level}}级</div>
        </div>
     </div>
  </div>
</template>

<script>
import HeadTop from "@/components/HeadTop.vue";
export default {
    components: {
        HeadTop,
    },
    data(){
      return{
        userCount:'',
        userList:[],
      }
    },
    created(){
      this.countSubuser()
      this.getSubUser()
    },
    methods:{
      countSubuser(){
        let that = this
        that.$request('/api/countSubuser', { token:this.$store.state.token })
          .then(res => {
            that.userCount = res.data.data

          })
      },
      getSubUser(){
        let that = this
        that.$request('/api/getSubUser', { token:this.$store.state.token })
          .then(res => {
            that.userList = res.data.data
          })
      }
    },

}
</script>
<style src="./tgtj.css" scoped>
</style>
<style scoped>
 .tgtj{
    background: #FFFFFF;
    height: 100vh;
    overflow:hidden;
    overflow: scroll;
  }
</style>