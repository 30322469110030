<template>
  <div>
      <div class="pay_body">
        <div class="head">
          <div class="logo">
            <img src="https://static.ripinhui.shop/static/i/2023/10/20/5527006265370218854.png" alt="">
          </div>
          <div v-if="pay==1" class="text1">帮我付一下这件商品吧，谢谢啦</div>
          <div v-if="pay==-1" class="text1">代付订单创建成功，将本页面发给好友帮你付款吧</div>
          
         
        </div>
        <div class="pay_content">
            <div class="text1">代付金额</div>
            <div class="text2"><span class="money_ico">￥</span>{{orderInfo.money}}</div>
             <van-button class="btn" color="#D01C1D" type="primary" block @click="friendPayment()" v-if="orderInfo.order_status==1">立即支付</van-button>
             <van-button class="btn" color="#D01C1D" type="primary" block @click="friendPayment()" v-if="orderInfo.order_status==-1">该订单已被支付</van-button>
             <div class="text3">付款前请务必与好友确认无误，避免受骗哦</div>
             <div v-if="pay==-1" class="text3">如果订单申请退款，已支付金额将原路退还给您的好友</div>
             <div v-if="pay==1" class="text3">如果订单申请退款，已支付金额将原路退还给您</div>
        </div>
      </div>
      <van-overlay :show="btnShow" >
                <div class="wrapper">
                    <van-loading size="24px" vertical>跳转支付过程会有短暂的空白页，请耐心等待一下</van-loading>
                </div>
      </van-overlay>
  </div>
</template>

<script>
export default {
    data() {
    return {
      orderInfo:{},
      btnShow: false, //提交后的遮罩层
      order_id:-1,
      pay:-1 //-1自己看的页面 1好友看的页面
    };
  },

  created() {
    this.pay = this.$route.query.pay
    this.getfriendPaymentOrderInfo()
  },

  methods: {
    friendPayment() {
       this.btnShow = true
      var agent = 'H5';
            var userAgent = window.navigator.userAgent.toLowerCase(); 
            if (userAgent.match(/MicroMessenger/i) == "micromessenger") {
                // console.log('微信');
                agent = 'weixin'
            }
            if (userAgent.match(/Alipay/i) == "alipay") {
                // agent = 'weixin'
            }
      var data = {
        agent,
        order_id:this.$route.query.order_id
      }
     
      this.$request("/api/friendPayment", data).then((res) => {

        if(res.data.code==1){
          window.location.href = res.data.data.url
        }
      });
    },
    getfriendPaymentOrderInfo()
    {
      
      var data = {
        order_id:this.$route.query.order_id
      }
      this.$request("/api/getfriendPaymentOrderInfo", data).then((res) => {
        this.orderInfo = res.data.data;
      });
    }
  }
}
</script>
<style src="./friendPayment.css" scoped>

</style>
<style>

</style>