import { render, staticRenderFns } from "./category.vue?vue&type=template&id=598fe636&scoped=true"
import script from "./category.vue?vue&type=script&lang=js"
export * from "./category.vue?vue&type=script&lang=js"
import style0 from "./category.css?vue&type=style&index=0&id=598fe636&prod&scoped=true&lang=css&external"
import style1 from "./category.vue?vue&type=style&index=1&id=598fe636&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "598fe636",
  null
  
)

export default component.exports