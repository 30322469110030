<template>
    <div>
        <head-top-white title="我的等级" left_ico="1" url="-1"></head-top-white>
        <div class="head">
            <van-swipe class="my-swipe" :initial-swipe="grade" :show-indicators="false" :loop="false"  indicator-color="white">
                <van-swipe-item>
                    <div class="member_cart1">
                        <div class="text1">商城购物享{{gradeList[0]['discount']}}折</div>
                        <div class="lock">
                            <div class="lock_left" v-if="grade==0">已解锁该等级</div>
                            <div class="lock_left" v-if="grade!=0"><img class="lock_ico" src="../../static/image/member_lock_ico1.png">暂未解锁该等级</div>
                            <div class="lock_right">{{gradeList[0]['grade_value']}}点</div>
                        </div>
                        <div>
                            <van-progress :show-pivot="false" color="#146D8E" :percentage="percentageVal.grade1" />
                        </div>
                        <div class="tips">
                            当前 {{grade_value}} 点，需要达到 {{gradeList[0]['grade_value']}} 点解锁该等级
                        </div>
                    </div>
                </van-swipe-item>
                <van-swipe-item>
                    <div class="member_cart2">
                        <div class="text1">商城购物享{{gradeList[1]['discount']}}折</div>
                        <div class="lock">
                            <div class="lock_left" v-if="grade==1">已解锁该等级</div>
                            <div class="lock_left" v-if="grade!=1"><img class="lock_ico" src="../../static/image/member_lock_ico2.png">暂未解锁该等级</div>
                            <div class="lock_right">{{gradeList[1]['grade_value']}}点</div>
                        </div>
                        <div>
                            <van-progress :show-pivot="false" color="#DE750A" :percentage="percentageVal.grade2" />
                        </div>
                        <div class="tips">
                            当前 {{grade_value}} 点，需要达到 {{gradeList[1]['grade_value']}} 点解锁该等级
                        </div>
                    </div>
                </van-swipe-item>
                <van-swipe-item>
                    <div class="member_cart3">
                        <div class="text1">商城购物享{{gradeList[2]['discount']}}折</div>
                        <div class="lock">
                            <div class="lock_left" v-if="grade==2">已解锁该等级</div>
                            <div class="lock_left" v-if="grade!=2"><img class="lock_ico" src="../../static/image/member_lock_ico3.png">暂未解锁该等级</div>
                            <div class="lock_right">{{gradeList[2]['grade_value']}}点</div>
                        </div>
                        <div>
                            <van-progress :show-pivot="false" color="#448EF9" :percentage="percentageVal.grade3" />
                        </div>
                        <div class="tips">
                            当前 {{grade_value}} 点，需要达到 {{gradeList[2]['grade_value']}} 点解锁该等级
                        </div>
                    </div>
                </van-swipe-item>
                <van-swipe-item>
                    <div class="member_cart4">
                        <div class="text1">商城购物享{{gradeList[3]['discount']}}折</div>
                        <div class="lock">
                            <div class="lock_left" v-if="grade==3">已解锁该等级</div>
                            <div class="lock_left" v-if="grade!=3"><img class="lock_ico" src="../../static/image/member_lock_ico4.png">暂未解锁该等级</div>
                            <div class="lock_right">{{gradeList[3]['grade_value']}}点</div>
                        </div>
                        <div>
                            <van-progress :show-pivot="false" color="#5A51F8" :percentage="percentageVal.grade4" />
                        </div>
                        <div class="tips">
                            当前 {{grade_value}} 点，需要达到 {{gradeList[3]['grade_value']}} 点解锁该等级
                        </div>
                    </div>
                </van-swipe-item>
            </van-swipe>

        </div>
        <div class="card_white explain">
            <div class="title">
                快速升级技巧
            </div>
            <div class="tips">
                <div class="tips_left">
                    <span>购买商品</span>
                    <span class="text">+1点经验/日元</span>
                </div>
                <div>
                    <van-button type="default" color="linear-gradient(90deg, #FFEAB5 0%, #E7B667 100%)" size="small" round class="toShop" @click="toShop">去购买</van-button>
                </div>

            </div>
            <div class="bottom">
                购买商品可获得对应的经验值
            </div>
        </div>
        <div class="goods card_white">
            <div class="top">
                <img src="../../static/image/member_goods_ico1.png" alt="">
                <span>热门推荐</span>
            </div>
            <div>
                <div class="goods_body">

                    <div class="goods_list" @click="toGoods(item2.id)" v-for="(item2,index2) in activityGoodsList.selected">
                        <img class="goods_img" :src="$imgUrl+item2.img_url" alt="" />
                        <div class="goods_title">
                            {{item2.title}}
                        </div>
                        <div class="goods_bottom">
                            <span class="money">{{item2.price_cny.money}}</span>
                            <!-- <span class="sales_volume">已售{{item2.sales}}</span> -->
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HeadTopWhite from '@/components/HeadTopWhite.vue'
export default {
    components: { HeadTopWhite },
    data() {
        return {
            activityGoodsList: {},//活动商品列表
            gradeList: {},
            grade_value:0,//等级值
            grade:0,//等级
            percentageVal:{}//等级进度条
        }
    },
    created() {
        this.token = localStorage.getItem('token')
        this.getActivityGoods()
        this.getGrade()

    },
    methods: {
        // 获取活动商品列表
        getActivityGoods() {
            let that = this
            that.$request('/api/activityGoodsList', { type: 1, number: 4 })
                .then(res => {
                    that.activityGoodsList = res.data.data
                })
        },
        toGoods(goodsId) {
            this.$router.push({ path: "/goods", "query": { goodsId } })
        },
        toShop() {
            this.$router.push("/");
        },
        getGrade() {
            let that = this
            that.$request('/api/getGradeDiscount', {token: that.token})
                .then(res => {
                    that.gradeList = res.data.data
                    console.log(res.data.data)
                })
            that.$request('/api/getUserInfo', { token: that.token })
                .then(res => {
                    that.grade_value = res.data.data.grade_value
                    that.grade = res.data.data.grade-1
                    that.percentageVal = {
                        grade1:res.data.data.grade_value/that.gradeList[0].grade_value*100,
                        grade2:res.data.data.grade_value/that.gradeList[1].grade_value*100,
                        grade3:res.data.data.grade_value/that.gradeList[2].grade_value*100,
                        grade4:res.data.data.grade_value/that.gradeList[3].grade_value*100
                    }
                    
                    for(let val in that.percentageVal)
                    {
                        // console.log(that.percentageVal[val])
                        if(that.percentageVal[val]>100){
                            that.percentageVal[val]=100
                        }

                    }
                    
                    console.log(that.percentageVal)
                })
        }
    }
}
</script>
<style src="./index.css" scoped>
</style>
<style scoped>
/deep/.my-swipe .van-swipe-item {
    height: 450px;
    /* color: #fff; */
    /* font-size: 20px; */
    /* line-height: 150px; */
    /* text-align: center; */
    /* background-color: #39a9ed; */
}
/deep/.van-button__content {
    font-weight: 400;
    color: #8a5f1e;
}
</style>