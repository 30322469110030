<template>
    <div class="goods_list_body">
        <!-- <div class="goods_list">
            <div class="goods_body" v-if="index % 2 == 0">
                 <img class="thumbnail" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/27/5518721492696701076.png'" alt="">
            </div>
            <div class="goods_body" v-if="index % 2 == 0">
                 <img class="thumbnail" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/27/5518721492696701076.png'" alt="">
            </div>
            <div class="goods_body3" v-if="index % 2 != 0">
                <img class="thumbnail" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/27/5518721492696701076.png'" alt="">
            </div>
        </div> -->
        <div class="goods_list">
            <!-- 商品详情 -->
            <div class="goods_lfet" v-if="index % 2 == 0" v-for="(item,index) in  goodslist" >
              <div :class="'goods_body '+titlecolor" @click="toUrl('/goods?goodsId='+item.id)">
                <div class="goods_img">
                    <img v-lazy="$imgUrl+item.img_url"   v-if="!item.thumbnail">
                    <img v-lazy="$imgUrl+item.thumbnail" v-if="item.thumbnail">
                </div>
                <div class="goods_title">{{item.title}}</div>
                
              </div>
              <div :class="'price '+pricebg" @click="toUrl('/goods?goodsId='+item.id)">
                <div class="left_ico">
                  <img src="https://static.ripinhui.shop/static/i/2023/09/27/5518811135702209757.png" alt="">
                </div>
                <div class="price_text">
                  RMB ≈{{item.price_cny.money1}}
                </div>
                <div class="right_ico">
                  <img src="https://static.ripinhui.shop/static/i/2023/09/27/5518816064743412275.gif" alt="">
                </div>
              </div>
            </div>

            <!-- 商品详情结束 -->
            <div <div v-if="index % 2 != 0" v-for="(item,index) in  goodslist" >
                <div class="goods_body3" @click="toUrl('/goods?goodsId='+item.id)">
                  <div :class="'goods_body '+titlecolor">
                      <div class="goods_img">
                          <img v-lazy="$imgUrl+item.img_url"   v-if="!item.thumbnail">
                         <img v-lazy="$imgUrl+item.thumbnail" v-if="item.thumbnail">
                      </div>
                      <div class="goods_title">{{item.title}}</div>
                  </div>
              </div>
              <div  :class="'price '+pricebg" @click="toUrl('/goods?goodsId='+item.id)">
                <div class="left_ico">
                  <img src="https://static.ripinhui.shop/static/i/2023/09/27/5518811135702209757.png" alt="">
                </div>
                <div class="price_text">
                  RMB ≈{{item.price_cny.money1}}
                </div>
                <div class="right_ico">
                  <img src="https://static.ripinhui.shop/static/i/2023/09/27/5518816064743412275.gif" alt="">
                </div>
              </div>
            </div>

        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {

        };
    },
    props: ["pricebg","titlecolor",'goodslist'],
    created(){
      

    },
    methods: {
      toUrl(url){
        this.$router.push(url);
      },
    },
};
</script>

<style scoped>

.goods_list_body {
    width: 100%;
    border-radius: 30px;
    padding-bottom: 10px;
}
.goods_list_body .goods_list {
    width: 100%;
    margin-top: 20px;
    column-count: 2;
    column-gap: 0;
    padding-left: 19px;
}

.goods_list .goods_img{
    width: 352px;
    /* height: 408px; */
}
.goods_list .goods_img img{
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}



.goods_list .goods_body {
    break-inside: avoid-column;
    width: 352px;
    margin-bottom: 10px;
    background: #fff;
    column-gap: 0;
    text-align: center;
    color: #fff;
    font-size: 40px;
    border-radius: 10px;
    padding-bottom: 20px;
    /* height: 477px; */
}
.goods_list .goods_body3 {
    padding-top: 35px;
}
.goods_body_fa{
  background: #f5f5f5!important;
}
.goods_body_default{
  background: #fff!important;
}  
.goods_list .goods_title {
    width: 340px;
    font-size: 23px;
    font-family: AlibabaPuHuiTi;
    font-weight:  lighter;
    color: #262626;
     margin: 0 auto;
    margin-top: 20px;
     overflow: hidden; /* 超出部分隐藏 */
  white-space: nowrap; /* 文本不换行 */
  text-overflow: ellipsis; /* 显示省略号 */

  
}
.goods_lfet{
  margin-bottom: 35px;
}
.goods_list .price{
  
  width: 336px;
  height: 56px;
  margin-left: 9px;
  margin-top: -12px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
}
/* 居家生活 */
.goods_list .price_bg1{
  background: #fdb0b8;
}
/* 洗发护发 */
.goods_list .price_bg2{
  background: #4e2d02;
}
/* 身体护理 */
.goods_list .price_bg3{
  background: #1d5ac2;
}
/* 女性用品 */
.goods_list .price_bg4{
  background: #fb4971;
}
/* 男士护理 */
.goods_list .price_bg5{
  background: #3a68f1;
}
/* 口腔专区 */
.goods_list .price_bg6{
  background: #3ea6eb;
}
/* 厨房用品 */
.goods_list .price_bg7{
  background: #60682b;
}
/* 卫浴用品 */
.goods_list .price_bg8{
  background: #8bc4ef;
}
/* 成人用品 */
.goods_list .price_bg9{
  background: #d42e30;
}
/* 清洁用品 */
.goods_list .price_bg10{
  background: #04b99e;
}
/* 居家用品 */
.goods_list .price_bg11{
  background: #4261bd;
}
/* 时尚饰品 */
.goods_list .price_bg12{
  /* background: #fdb0b8; */
  background: #ea273b;
  
}
/* 文具办公 */
.goods_list .price_bg13{
  background: #2e2e2e;
}
/* 卫浴用品 */
.goods_list .price_bg14{
  background: #11b6ec;
}
/* 驱蚊 */
.goods_list .price_bg15{
  background: #60682b;
}
/* 活动页3零食馆 */
.goods_list .price_bg16{
  background: #CBE758;
}
/* 活动页4美食馆 */
.goods_list .price_bg17{
  background: #4B8EF9;
}

.goods_list .price .left_ico{
  width: 21px;
  height: 21px;
  line-height: 60px;
  margin-left: 13px;
}
.goods_list .price .left_ico img{
  width: 100%;

}
.goods_list .price .price_text{
  width: 225px;
  margin-left: 20px;
  line-height: 56px;
  font-size: 29px;
  font-family: AlibabaPuHuiTi;
  color: #FFFFFF;
  text-align: center;
  
}
.goods_list .price .right_ico{
  width: 21px;
  height: 21px;
  line-height: 60px;
  margin-left: 14px;
}
.goods_list .price .right_ico img{
  width: 100%;

}
</style>