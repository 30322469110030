<template>
    <div>
        <van-divider v-if="!divider" />
        <div class="details" @click="toGoods(goods_id)">
            <div class="details_left">
                <img :src="img" alt="" />
            </div>
            <div class="details_right">
                <div class="goods_title text_rows1">{{title}}</div>
                <div class="quantity">
                    <span>{{specs}}</span>
                    <span>x{{number}}</span>
                </div>
                <div  :class="price_colour?'unit_price unit_price_color':'unit_price'">{{price}}日元</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["title","specs","number",'price_colour','divider','price','img','goods_id'],
    methods:{
        toGoods(goodsId) {
            this.$router.push({path:"/goods","query":{goodsId}})
        },
    }
};
</script>

<style scoped>
.details{
    display: flex;
    justify-content: flex-start;
    padding: 0 20px 20px 20px;
    
}
.details .details_left img{
    width: 140px;
    height: 140px;
    margin-right: 20px;
    border-radius: 10px 10px 10px 10px;
}
.details .goods_title{
    font-size: 28px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #000000;
}
.details .details_right{
    width: 100%;
}
.details .details_right .quantity{
    font-size: 24px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #999999;
    line-height: 40px;
    margin: 20px 0px 18px 0px;
    display: flex;
    justify-content: space-between;
}
.details .details_right .unit_price{
    font-size: 28px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #999999;
    display: flex;
    justify-content: flex-end;
}
.details .details_right .unit_price_color{
    color: #ca2d2d;
    font-weight: 600;
}


</style>