<template>
    <div class="banner">
        <van-swipe class="my-swipe" :autoplay="6000" indicator-color="white">
            <van-swipe-item v-for="(item,index) in bannerimg" @click="toUrl(item.to_url)">
                <img v-lazy="$imgUrl+item.img_url" alt="">
            </van-swipe-item>
        </van-swipe>
    </div>
</template>

<script>
export default {
    data() {
        return {
           
        };
    },
    props: ['bannerimg'],
    methods: {
        toUrl(url){
            this.$router.push(url);
        },
    },
};
</script>

<style scoped>
 .banner .my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    /* line-height: 150px; */
    text-align: center;
    

  }
 .banner .my-swipe .van-swipe-item img{
    width: 713px;
    height: 842px;
    border-radius: 20px;
  }
</style>