// template
<template>
<div>
    <div class="callback float" @click="onClick" @mousedown="down" @touchstart="down" @mousemove="move" @touchmove="move" @mouseup="end" @touchend="end" ref="fu">
        <!-- <p @click="callback">返回</p> -->
        <img @click="calculatorBtn" src="../static/image/calculator.png"  />
        <img  @click="kfBtn" src="../static/image/kf.png"  />

    </div>
                    <van-overlay :show="kfBtnValue" @click="kfBtn">
            <div class="wrapper" @click.stop>
                <div class="block">
                    <img
                        class="qrcode"
                        :src="kfData.kf_qrcode"
                        alt=""
                    />
                    <p class="tips">扫描二维码，添加客服微信</p>
                    <van-divider
                        :style="{
                            color: '#1989fa',
                            borderColor: '#eee',
                            padding: '0 16px',
                        }"
                    />
                    <div>
                        <p class="text">
                            <span>微信号：{{kfData.kf_wechat}}</span
                            ><van-button
                                class="btn"
                                round
                                type="info"
                                size="mini"
                                color="#4c83ff"
                                @click="seccendCopy(1)"
                                >复制</van-button
                            >
                        </p>
                        <p class="text">
                            电话号：{{kfData.kf_phone}}<van-button
                                class="btn"
                                round
                                type="info"
                                size="mini"
                                color="#4c83ff"
                                @click="seccendCopy(2)"
                                >复制</van-button
                            >
                        </p>
                    </div>
                </div>
            </div>
        </van-overlay>
</div>
    
</template>

<script>

export default {
    props:["kfbtnval"],
    data() {
        return {
            isLoading: false,
            flags: false, //控制使用
            position: {
                x: 0,
                y: 0,
            },
            nx: "",
            ny: "",
            dx: "",
            dy: "",
            xPum: "",
            yPum: "",
            kfBtnValue:false,
            kfData:{}
        };
    },
    created(){
        this.getKf()
    },
    methods: {
        calculatorBtn() {
            this.$router.push("/calculator");
        },
        kfBtn() {
            this.$router.push("/message");
            
            // this.kfBtnValue = !this.kfBtnValue;
        },
        // callback() {
        //     this.$router.go(-1);
        // },
        onRefresh() {
            // window.location.reload();
            setTimeout((res) => {
                console.log(res);
                this.isLoading = false;
            }, 1000);
        },
        down() {
            this.flags = true;
            var touch;
            if (event.touches) {
                touch = event.touches[0];
            } else {
                touch = event;
            }
            this.position.x = touch.clientX;
            this.position.y = touch.clientY;
            this.dx = this.$refs.fu.offsetLeft;
            this.dy = this.$refs.fu.offsetTop;
        },
        move() {
            if (this.flags) {
                var touch;
                if (event.touches) {
                    touch = event.touches[0];
                } else {
                    touch = event;
                }
                this.nx = touch.clientX - this.position.x;
                this.ny = touch.clientY - this.position.y;
                this.xPum = this.dx + this.nx;
                this.yPum = this.dy + this.ny;
                let width = window.innerWidth - this.$refs.fu.offsetWidth; //屏幕宽度减去自身控件宽度
                let height = window.innerHeight - this.$refs.fu.offsetHeight; //屏幕高度减去自身控件高度
                this.xPum < 0 && (this.xPum = 0);
                this.yPum < 0 && (this.yPum = 0);
                this.xPum > width && (this.xPum = width);
                this.yPum > height && (this.yPum = height);
                // if (this.xPum >= 0 && this.yPum >= 0 && this.xPum<= width &&this.yPum<= height) {
                this.$refs.fu.style.left = this.xPum + "px";
                this.$refs.fu.style.top = this.yPum + "px";
                // }
                //阻止页面的滑动默认事件
                document.addEventListener(
                    "touchmove",
                    function () {
                        event.preventDefault();
                    },
                    false
                );
            }
        },
        //鼠标释放时候的函数
        end() {
            this.flags = false;
        },
        onClick() {
            //在这里我是作为子组件来使用的
            this.$emit("click");
        },
        getKf(){
            this.$request('/api/getKf', {})
                .then(res => {
                    this.kfData = res.data.data
                })
        },
        //复制 type 1微信2电话
        seccendCopy(type) {
            let that = this
            let val = ''
            if(type==1){
                val = that.kfData.kf_wechat
            }
            if(type==2){
                val = that.kfData.kf_phone
            }
            this.$copyText(val).then(
                function (e) {
                    // console.log("copy arguments e:", e);
                    that.$toast('复制成功');
                },
                function (e) {
                    // console.log("copy arguments e:", e);
                    that.$toast('复制失败，请长按手动复制');
                }
            );
        },
    },
}
</script>
<style scoped>
.callback p {
    font-size: 16px;
    color: #fff;
    background: rgba(56, 57, 58, 0.5);
    border-radius: 50%;
    text-align: center;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-family: PingFang SC;
    font-weight: 600;
    box-shadow: 0 0 10px #fff;
}
.callback img {
    display: block;
    width: 70px;
    height: 70px;
    box-shadow: 0 0 10px rgb(133, 129, 129);
    border-radius: 50%;
    background: #fff;
    margin-bottom: 20px;
}
.callback {
    position: fixed;
    top: 40px;
    left: 20px;
    z-index: 99999;
}
.float {
    position: fixed;
    /* right: 20px; */
    left: 87%;
    top: 70%;
    touch-action: none;
    text-align: center;
    width: 50px;
    height: 50px;
    border-radius: 24px;
    line-height: 48px;
    color: white;
}
.right_ico .calculator {
    width: 80px;
    height: 80px;
    position: fixed;
    right: 43px;
    top: 900px;
}
.right_ico .kf {
    width: 80px;
    height: 80px;
    position: fixed;
    right: 43px;
    top: 1010px;
}
.wrapper {
    width: 500px;
    margin:0 auto;
    /* display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; */
}

.block {
    width: 500px;
    height: 750px;
    background: #ffffff;
    border-radius: 20px 20px 20px 20px;
    margin: 260px auto;
    text-align: center;
    padding-top: 25px;
}
.block .qrcode {
    width: 450px;
    height: 450px;
}
.block .tips {
    font-size: 28px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #000000;
}
.block .text {
    font-size: 28px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.block .text .btn {
    margin-left: 35px;
}
/deep/ .van-button {
    padding: 0 20px;
}
</style>