<template>
  <div style="background-color:#fff;">
    <div class="head">
        <img @click="toUrl('/goodsList?catagoryId=151')" src="https://static.ripinhui.shop/static/i/2023/12/17/5548222237103887502.png" alt="">
    </div>
    <div class="haobao">
        <img :src="$imgUrl+getImgData.big_img.img_url1" @click="toUrl(getImgData.big_img.to_url1)" alt="">
    </div>
    <div class="nav_img">
        <img src="https://static.ripinhui.shop/static/i/2023/12/17/5548224242983641041.png" alt="">
    </div>
    <div class="category">
      <div class="cate_body" v-for="(item,index) in getImgData.category">
        <div class="cate_info" v-if="item.img_url1">
          <img :src="$imgUrl+item.img_url1" alt="" @click="toUrl(item.to_url1)">
          <div class="bottom_ico">
          </div>
        </div>
        <div class="cate_info" v-if="item.img_url2">
          <img :src="$imgUrl+item.img_url2" alt="" @click="toUrl(item.to_url2)">
          <div class="bottom_ico">
          </div>
        </div>
        <div class="cate_info" v-if="item.img_url3">
          <img :src="$imgUrl+item.img_url3" alt="" @click="toUrl(item.to_url3)">
          <div class="bottom_ico">
          </div>
        </div>
      </div>
    </div>
    <div class="cate2">
      <img src="https://static.ripinhui.shop/static/i/2023/12/18/5548554900851397405.png" @click="toUrl('/goodsList?catagoryId=177')" >
      <img src="https://static.ripinhui.shop/static/i/2023/12/18/5548555013254550740.png" @click="toUrl('/goodsList?catagoryId=397')" >
    </div>
    <div class="hot">
      <img class="top_img" src="https://static.ripinhui.shop/static/i/2023/12/18/5548559072409688378.png" alt="">
      <van-tabs type="card" swipeable>
      <van-tab title="热门必买">
        <div class="goods_body">
          <div class="goods_info" v-for="(item,index) in getImgData.goods.activity1" @click="toUrl('/goods?goodsId='+item.id)">
            <img class="goods_img" v-lazy="$imgUrl+item.img_url"   v-if="!item.thumbnail">
            <img class="goods_img" v-lazy="$imgUrl+item.thumbnail" v-if="item.thumbnail">
            <div class="goods_title">
              {{item.title}}
            </div>
            <div class="goods_price">
              ≈RMB:{{item.price.money1}}
            </div>
          </div>

        </div>
           <div class="goods_bottom">
          向右滑动查看更多活动>
        </div>
      </van-tab>
   
      <van-tab title="新品尝鲜">
        <div class="goods_body">
          <div class="goods_info" v-for="(item,index) in getImgData.goods.activity2" @click="toUrl('/goods?goodsId='+item.id)">
            <img class="goods_img" v-lazy="$imgUrl+item.img_url"   v-if="!item.thumbnail">
            <img class="goods_img" v-lazy="$imgUrl+item.thumbnail" v-if="item.thumbnail">
            <div class="goods_title">
              {{item.title}}
            </div>
            <div class="goods_price">
              ≈RMB:{{item.price.money1}}
            </div>
          </div>

        </div>
         <div class="goods_bottom">
          向右滑动查看更多活动>
        </div>
      </van-tab>
      <van-tab title="天诚专属">
        <div class="goods_body">
          <div class="goods_info" v-for="(item,index) in getImgData.goods.activity3" @click="toUrl('/goods?goodsId='+item.id)">
            <img class="goods_img" v-lazy="$imgUrl+item.img_url"   v-if="!item.thumbnail">
            <img class="goods_img" v-lazy="$imgUrl+item.thumbnail" v-if="item.thumbnail">
            <div class="goods_title">
              {{item.title}}
            </div>
            <div class="goods_price">
              ≈RMB:{{item.price.money1}}
            </div>
          </div>

        </div>
      </van-tab>
    </van-tabs>
    </div>
    <activity-3-goods-list topimg="bg1" bgimg="ls" :goodslist="goods.xxls" />
    <activity-3-goods-list topimg="bg2" bgimg="st" :goodslist="goods.syst" />
    <activity-3-goods-list topimg="bg3" bgimg="ts" :goodslist="goods.tsyp" />
    <activity-3-goods-list topimg="bg4" bgimg="zg" :goodslist="goods.rbzg" />
    <div class="all_goods_List">
      <img class="head_img" src="https://static.ripinhui.shop/static/i/2023/12/19/5548921790954216079.png" alt="">
    </div>
    <van-list
              v-model="loading"
              :finished="finished"
              offset="100"
              :immediate-check="false"
              @load="onLoad"
              >
        <activity-2-goods-list pricebg="price_bg16" titlecolor="goods_body_default" :goodslist="goodsList" />
        </van-list>
    <div class="bottom"></div>
  </div>
</template>

<script>


import Activity3GoodsList from '../../components/Activity3GoodsList.vue';
import Activity2GoodsList from '../../components/Activity2GoodsList.vue';

export default {
    components: { Activity3GoodsList,Activity2GoodsList },
    data(){
      return{
        goods:{},
        goodsList:{},
        getImgData:{},
        page: 1, //页码
        loading: false,//设置为 true，表示分页处于加载状态
        finished: false//	是否已加载完成，加载完成后不再触发load事件
      }
    },
  created() {
    this.getImg()
    this.getGoods()
    this.getGoodsList()
  },
  methods: {
    getImg(){
      let that = this
      that.$request('/api/hd3/getImg', {token: this.$store.state.token})
          .then(res => {
              that.getImgData = res.data.data
          })
    },
    getGoods(){
      let that = this
      that.$request('/api/hd3/getGoods', {token: this.$store.state.token})
          .then(res => {
              that.goods = res.data.data
          })
    },
    onLoad() {
            this.loading = true;
            this.getGoodsList()
    },
    toUrl(url){
            this.$router.push(url);
    },
    getGoodsList(){
      let that = this
      that.$request('/api/hd3/getGoodsList', {token: this.$store.state.token})
          .then(res => {
            if(that.page==1){
              that.goodsList = res.data.data.data
            }
              this.loading = false;
              that.goodsList = that.goodsList.concat(res.data.data.data)
                if(res.data.data.data.length==0){
                    this.finished = true
                }
                that.page = that.page+1
          })
    },
  }
}
</script>
<style src="./activity3.css" scoped>
</style>
<style scoped>

/deep/ .hot .van-tabs__nav {
  background: #fff;
  border-radius: 30px;
}
/deep/ .hot .van-tab {
  border-radius: 30px;
}
/deep/ .hot .van-tabs__nav--card .van-tab {
  color: #EF0000;
}
/deep/ .hot .van-tabs__nav--card .van-tab.van-tab--active {
  color: #fff;
  background: #EF0000;
  border: none;
  font-family: AlibabaPuHuiTi;
  font-weight: 600;
}
/deep/ .hot .van-tabs__nav--card .van-tab{
  border-right:none;
}
/deep/ .hot .van-tab__text--ellipsis{
  font-size: 21px;
  color: #373635;
  font-family: AlibabaPuHuiTi;
  font-weight: 400;
}
/deep/ .hot .van-tab--active .van-tab__text--ellipsis{
  font-size: 24px;
  font-weight: bold;
  color:#fff;

	-webkit-background-clip: text;
}
/deep/ .hot .van-tabs__nav--card{
  border:0.5px solid #FFFFFF;
}
</style>