<!--  -->
<template>
  <div>
    <head-top-white title="商品评价" left_ico="1" :url='-1'></head-top-white>
    <div class="goods" v-for="(item,index) in goodsList">
        <div class="left_body">
            <img :src="$imgUrl+item.img_url" alt="">
        </div>
        <div class="right_body">
            <div class="head">
                <div class="title text_rows2">{{item.goods_title}}</div>
            </div>
            <div class="bottom">
                <van-button class="btn1" plain round size="mini" color="#4c83ff" v-if="item.feedback==1" type="primary" @click="btnFeedback(item.id)">评价</van-button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import HeadTopWhite from '../../components/HeadTopWhite.vue';
export default {
  components: { HeadTopWhite },
  data () {
    return {
      goodsList:[]
    };
  },

  created() {
    this.orderId = this.$route.query.orderId
    this.getOrderDetail()
  },

  methods: {
    getOrderDetail(){
      let that= this
      that.$request('/api/getOrderDetail', {
              token:this.$store.state.token,
              number:this.orderId
          })
          .then(res => {
              // console.log(res.data.data.goods)
              that.goodsList = res.data.data.goods
              // that.orderDetail = res.data.data.goods[0]

          })
    },
    btnFeedback(goodsId){
      this.$router.push({path:"/GoodsFeedback","query":{orderId:this.orderId,goodsId}})
    }
  }
}

</script>
<style src="./multiGoods.css" scoped>
</style>
<style  scoped>
.bottom .btn1{
    padding: 0 20px;
}
</style>