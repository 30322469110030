<template>
    <div class="login">
        <div class="logo">
            <img src="../../static/image/login_logo.png" alt="">
        </div>
        <div class="login_input">
            <div class="input_body">
                <img src="../../static/image/login_input_ico1.png" alt="">
                <van-field v-model="username" type="digit" placeholder="请输入手机号码" />
            </div>
            <div class="input_body">
                <img src="../../static/image/login_input_ico2.png" alt="">
                <van-field v-model="password" type="password" placeholder="请输入密码" />
            </div>
            <div class="verification">
                <div class="input_body_verification">
                    <img src="../../static/image/login_ico_check.png" alt="">
                    <van-field v-model="code" type="text" placeholder="请输验证码" />
                </div>
                <div>
                    <van-button type="primary " v-if="!sendMsgDisabled" color="#4c83ff" class="get_code" @click="getCode">获取验证码</van-button>
                    <van-button type="primary " v-if="sendMsgDisabled" color="#4c83ff" class="get_code" @click="getCode">{{ sendCodeTime+'秒后获取' }}</van-button>

                </div>
            </div>

            <div>
                <van-button type="primary" class="input_btn" color="#4c83ff" block round @click="loginBtn">注册</van-button>
                <van-button type="primary" class="input_btn" block round plain color="#4c83ff" @click="toLogin">已有账号</van-button>
            </div>

        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            username: '',
            password: '',
            imgUrl:'',
            code:'',
            key:'',
            sendCodeTime: 60, // 发送验证码倒计时
            sendMsgDisabled: false //发送验证码按钮状态
        }
    },
    created(){
        this.getVerify()
    },
    methods: {
        getCode(){
            const that = this
            if(that.sendMsgDisabled==true){
                return
            }
              that.sendMsgDisabled = true
            that.$request('/api/getSmsCode', {
                phone: that.username,
            })
                .then(res => {
                     if (res.data.code == -1) {
                        that.$toast(res.data.msg)
                    }else{
                        that.$toast('验证码发送成功');
                    }
                })
              const interval = window.setInterval(function() {
                if ((that.sendCodeTime--) <= 0) {
                  that.sendCodeTime = 60
                  that.sendMsgDisabled = false
                  window.clearInterval(interval)
                }
              }, 1000)

        },
        loginBtn() {
            let that = this
            if(this.username==''){
                this.$toast('手机号不能为空')
                return
            }
            if(this.password==''){
                this.$toast('密码不能为空')
                return
            }
            if(this.code==''){
                this.$toast('验证码不能为空')
                return
            }

            var myreg=/^[1][2,3,4,5,6,7,8,9][0-9]{9}$/;  
          if (!myreg.test(this.username)) {  
              this.$toast('请正确输入手机号')
              return
          } 
            this.$request('/api/register2', {
                username: that.username,
                password: that.password,
                code:that.code,
                key:that.key,
                aff:localStorage.getItem('aff')
            })
                .then(res => {
                     if (res.data.code == 1) {
                        this.$toast('注册成功')
                        this.$router.push("/login");
                    }
                })
            this.getVerify()
        },
        getVerify(){
            this.$request('/api/verify', {})
                .then(res => {
                    this.imgUrl = res.data.base64
                    this.key = res.data.key
                })
        },
        toRegister(){
            this.$router.push("/coupon");
        },
        toLogin(){
            this.$router.push("/login");
        }
    }
}
</script>
<style src="./register.css" scoped>
</style>
<style scoped>
/deep/ .van-cell {
    background-color: transparent;
}
</style>