<template>
  <div>
    <img class="imgs" src="https://static.ripinhui.shop/static/web_img/index/VIP.gif" alt="">
    <img class="imgs" src="https://static.ripinhui.shop/static/web_img/index/7b974a510a9151c855f7ae5b284c36c1.jpg" alt="">
    <div>
      <div class="bottom">
        <img @click="toLink('/')" src="https://static.ripinhui.shop/static/web_img/index/15_07.jpg" >
        <div class="line"></div>
         <img @click="toTop()" src="https://static.ripinhui.shop/static/web_img/index/15_09.jpg" >
      </div>
      <div style="width:100%; text-align: center;">
        <img style="width:88%;margin:0 auto;" src="https://static.ripinhui.shop/static/web_img/index/17.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toLink(val){
            console.log(val)
            if(val){
                  window.location = val
            }
    },
    toTop(){
        window.scrollTo( 0, 100 );
    }
  }
}
</script>

<style scoped>
.imgs{
    width: 100%;
}
.bottom{
  width: 721px;
  height: 133px;
  background: #E2E2E2;
  border-radius: 10px;
  margin:0 auto;
  display: flex;
  justify-content: space-around;
  
}
.bottom img{
  width: 182px;
  height: 98px;
  margin-top: 16px;
}
.bottom .line{
  height: 36px;
  width: 1px;
  background: #6E6E6E;
  margin-top: 50px;
}
</style>