<template>
  <div>
    <head-top-white title="消息详情" left_ico="1"></head-top-white>
    <div class="content">
        <div class="title">确认收货通知</div>
        <div class="con">亲，您的订单wx541564545145415，商品日本FANAL满点野菜 150粒已确认收货，感谢您的光临！</div>
        <div class="time">2022-7-7 20:59:13</div>
    </div>
  </div>
</template>

<script>
import HeadTopWhite from "@/components/HeadTopWhite.vue";
export default {
  components: {
    HeadTopWhite,
  },
};
</script>

<style src="./news_detail.css" scoped>
</style>

<style scoped>

</style>