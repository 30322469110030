<template>
    <div>
        <head-top title="个人资料" left_ico="1"></head-top>
        <div class="user_avatar">
            <div class="left">头像</div>
            <img src="../../static/image/my_head_portrait.png" alt="" />
        </div>
        <div class="nick_name">
            <div class="left">用户名</div>
            <div class="right">{{userInfo.phone}}</div>
        </div>
        <div class="phone_number">
            <div class="left">手机号</div>
            <div class="right">
                <span>{{userInfo.phone}}</span
                ><img src="../../static/image/lock_icon.png" alt="" />
            </div>
        </div>
        <!-- <div class="id_number">
        <div class="left">ID号</div>
        <div class="right">126</div>
        <img src="../../static/image/lock_icon.png" alt="">
    </div> -->
        <!-- <van-button
            type="primary"
            size="small"
            color="#4c83ff"
            round
            block
            class="btn1"
            >保存修改
        </van-button> -->
        <van-button
            type="primary"
            size="small"
            color="#4c83ff"
            round
            plain
            block
            @click="loginOut()"
            class="btn2"
            >退出登录
        </van-button>
    </div>
</template>

<script>
import HeadTop from "@/components/HeadTop.vue";
export default {
    data(){
        return{
            userInfo:{}
        }
    },
    created(){
        this.getUserInfo()
    },
    components: {
        HeadTop,
    },
    methods:{
        getUserInfo(){
            this.$request('/api/getUserInfo', {
                token: this.$store.state.token
            })
            .then(res => {
                console.log(res.data.data)
                this.userInfo = res.data.data
            })
        },
        loginOut(){
            localStorage.clear(); 
            this.$router.push("/login");
        }
    }
};
</script>

<style src="./personal_information.css" scoped>
</style>

<style scoped>
</style>