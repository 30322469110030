<template>
  <div class="content">
    <div class="head">
      <img class="top_img_gif" src="https://static.ripinhui.shop/static/i/2023/09/27/5518814207904059546.gif" alt="">
        <img class="top_img" src="https://static.ripinhui.shop/static/i/2023/09/26/5518329939817928959.jpg" alt="">
    </div>
    <!-- 分类导航 -->
    <div class="fldh">
        <img class="head_img" src="https://static.ripinhui.shop/static/i/2023/09/26/5518331952148518070.jpg" alt="">
        <div class="cate_list_big">
           <img @click="toUrl('/goodsList?catagoryId=328')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/26/5518336071877795034.jpg'" alt="">
            <img @click="toUrl('/goodsList?catagoryId=77')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/26/5518337705903135931.jpg'" alt="">
             <img @click="toUrl('/goodsList?catagoryId=333')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/26/5518337779857103470.jpg'" alt="">
              <img @click="toUrl('/goodsList?catagoryId=78')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/26/5518338602896989696.jpg'" alt="">
              <img @click="toUrl('/goodsList?catagoryId=334')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/26/5518338666189034766.jpg'" alt="">
             <img @click="toUrl('/goodsList?catagoryId=79')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/26/5518338709377782503.jpg'" alt="">
              <img @click="toUrl('/goodsList?catagoryId=80')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/26/5518338743267760102.jpg'" alt="">
              <img @click="toUrl('/goodsList?catagoryId=82')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/26/5518338789556097197.jpg'" alt="">
        </div>
        <div class="cate_list_small">
            <img @click="toUrl('/goodsList?catagoryId=83')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/26/5518339581486832629.jpg'" >
            <img @click="toUrl('/goodsList?catagoryId=84')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/26/5518340674321125480.jpg'" >
            <img @click="toUrl('/goodsList?catagoryId=85')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/26/5518340712837422833.jpg'" >
            <img @click="toUrl('/goodsList?catagoryId=86')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/26/5518340747952135265.jpg'" >
            <img @click="toUrl('/goodsList?catagoryId=87')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/26/5518340808685655661.jpg'" >
            <img @click="toUrl('/goodsList?catagoryId=370')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/26/5518340844735698139.jpg'" >
            <img @click="toUrl('/goodsList?catagoryId=124')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/26/5518340880869626452.jpg'" >
            <img @click="toUrl('/goodsList?catagoryId=383')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/26/5518340912859585555.jpg'" >
        </div>
    </div>
    <!-- 品牌大赏 -->
    <div class="ppds">
        <img class="head_img" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/26/5518341666576014397.jpg'" alt="">
        <!-- 品牌故事 -->
        <div class="logo_btn">
          <div :class="{'logo_body':logo_btn_id!=1,'logo_body_select logo_body':logo_btn_id==1}" @click="logoBtn(1)">
            <img v-lazy="'https://static.ripinhui.shop/static/i/2023/09/27/5518694623557458483.png'" alt="">
          </div>
          <div :class="{'logo_body':logo_btn_id!=2,'logo_body_select logo_body':logo_btn_id==2}" @click="logoBtn(2)">
            <img v-lazy="'https://static.ripinhui.shop/static/i/2023/09/27/5518699053606510499.png'" alt="">
          </div>
          <div :class="{'logo_body':logo_btn_id!=3,'logo_body_select logo_body':logo_btn_id==3}" @click="logoBtn(3)">
            <img v-lazy="'https://static.ripinhui.shop/static/i/2023/09/27/5518699148481663300.png'" alt="">
          </div>
          <div :class="{'logo_body':logo_btn_id!=4,'logo_body_select logo_body':logo_btn_id==4}" @click="logoBtn(4)">
            <img v-lazy="'https://static.ripinhui.shop/static/i/2023/09/27/5518699216056098621.png'" alt="">
          </div>
        </div>
        <div>
          <img class="ppgs_img" v-lazy="$imgUrl+ppgs_img[0]" v-if="logo_btn_id==1" alt="">
          <img class="ppgs_img" v-lazy="$imgUrl+ppgs_img[1]" v-if="logo_btn_id==2" alt="">
          <img class="ppgs_img" v-lazy="$imgUrl+ppgs_img[2]" v-if="logo_btn_id==3" alt="">
          <img class="ppgs_img" v-lazy="$imgUrl+ppgs_img[3]" v-if="logo_btn_id==4" alt="">
        </div>
        <!-- 品牌故事结束 -->
    </div>
    <!-- 1居家生活馆 -->
    <div class="jjsh">
      <img class="head_img" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/27/5518715923608900943.jpg'" alt="">
      <activity-2-banner :bannerimg="bannerList.jjsh" />
      <!-- 分类 -->
      <div class="cate_list_big">
          <img @click="toUrl('/goodsList?catagoryId=329')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/27/5518717461001671073.jpg'" alt="">
          <img @click="toUrl('/goodsList?catagoryId=364')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/27/5518717611895951444.jpg'" alt="">
      </div>
      <!-- 商品列表 -->
      <activity-2-goods-list pricebg="price_bg1" titlecolor="goods_body_default" :goodslist="goodsList.jjsh" />
      <div class="goods_more">
        <img @click="toUrl('/goodsList?catagoryId=328')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/27/5518873609419887923.gif'" alt="">
      </div>
    </div>
    <!-- 2洗发护发专区 -->
    <div class="xhzq">
      <img class="head_img" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519137274618451874.jpg'" alt="">
      <activity-2-banner :bannerimg="bannerList.xhzq" />
      <!-- 分类 -->
      <div class="cate_list_big">
          <img @click="toUrl('/goodsList?catagoryId=91')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/27/5518875853846484599.jpg'" alt="">
          <img @click="toUrl('/goodsList?catagoryId=94')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/27/5518875908867365235.jpg'" alt="">
          <img @click="toUrl('/goodsList?catagoryId=95')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/27/5518876214904754964.jpg'" alt="">
          <img @click="toUrl('/goodsList?catagoryId=96')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/27/5518876279459288016.jpg'" alt="">
          <img @click="toUrl('/goodsList?catagoryId=97')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/27/5518876403027679711.jpg'" alt="">
          <img @click="toUrl('/goodsList?catagoryId=98')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/27/5518876442508664347.jpg'" alt="">
          <img @click="toUrl('/goodsList?catagoryId=294')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/27/5518876524041736917.jpg'" alt="">
          <img @click="toUrl('/goodsList?catagoryId=379')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/27/5518876553406059894.jpg'" alt="">
      </div>
      <!-- 商品列表 -->
      <activity-2-goods-list pricebg="price_bg2" titlecolor="goods_body_default" :goodslist="goodsList.xhzq" />
      <div class="goods_more">
        <img @click="toUrl('/goodsList?catagoryId=77')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/27/5518876641540968997.gif'" alt="">
      </div>
    </div>
    <!-- 洗发护发结束 -->
    <!-- 3身体护理 -->
    <div class="sthl">
      <img class="head_img" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519137128254017555.jpg'" alt="">
      <activity-2-banner :bannerimg="bannerList.sthl" />
      <!-- 分类 -->
      <div class="cate_list_big1">
          <img @click="toUrl('/goodsList?catagoryId=99')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/27/5518880159815441082.jpg'" alt="">
          <img @click="toUrl('/goodsList?catagoryId=100')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/27/5518880233714881906.jpg'" alt="">
      </div>
      <div class="cate_list_big">
          <img @click="toUrl('/goodsList?catagoryId=102')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/27/5518881130062814473.jpg'" alt="">
          <img @click="toUrl('/goodsList?catagoryId=103')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/27/5518881269758301671.jpg'" alt="">
          <img @click="toUrl('/goodsList?catagoryId=104')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/27/5518881310250113347.jpg'" alt="">
          <img @click="toUrl('/goodsList?catagoryId=105')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/27/5518881354105755705.jpg'" alt="">
          <img @click="toUrl('/goodsList?catagoryId=106')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/27/5518881396493391122.jpg'" alt="">
          <img @click="toUrl('/goodsList?catagoryId=107')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/27/5518881428470764791.jpg'" alt="">
          <img @click="toUrl('/goodsList?catagoryId=354')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/27/5518881467029001885.jpg'" alt="">
          <img @click="toUrl('/goodsList?catagoryId=383')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/27/5518881506853917767.jpg'" alt="">
      </div>
      <!-- 商品列表 -->
      <activity-2-goods-list pricebg="price_bg3" titlecolor="goods_body_default" :goodslist="goodsList.sthl" />
      <div class="goods_more">
        <img @click="toUrl('/goodsList?catagoryId=78')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/27/5518879254189708348.gif'" alt="">
      </div>
    </div>
    <!-- 身体护理结束 -->
    <!-- 4女性用品 -->
    <div class="nxyp">
      <img class="head_img" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519143098417946328.jpg'" alt="">
      <activity-2-banner :bannerimg="bannerList.nxyp" />
      <!-- 分类 -->
      <div class="cate_list">
        <div class="cate_left">
          <img @click="toUrl('/goodsList?catagoryId=115')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519144504344450805.jpg'" alt="">
        </div>
        <div class="cate_right">
          <img @click="toUrl('/goodsList?catagoryId=116')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519144761014884129.jpg'" alt="">
          <img @click="toUrl('/goodsList?catagoryId=117')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519145839521437687.jpg'" alt="">
          <img @click="toUrl('/goodsList?catagoryId=118')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519145921570414227.jpg'" alt="">
          <img @click="toUrl('/goodsList?catagoryId=365')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519154771023369585.jpg'" alt="">
        </div>  
          
      </div>

      <!-- 商品列表 -->
      <activity-2-goods-list pricebg="price_bg4" titlecolor="goods_body_default" :goodslist="goodsList.nxyp" />
      <div class="goods_more">
        <img @click="toUrl('/goodsList?catagoryId=80')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519143197961362211.gif'" alt="">
      </div>
    </div>
    <!-- 女性用品结束 -->
    <!-- 5男士护理 -->
    <div class="nshl">
      <img class="head_img" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519191318338737976.jpg'" alt="">
      <activity-2-banner :bannerimg="bannerList.nshl" />
     
      <!-- 商品列表 -->
      <activity-2-goods-list pricebg="price_bg5" titlecolor="goods_body_default" :goodslist="goodsList.nshl" />
      <div class="goods_more">
        <img @click="toUrl('/goodsList?catagoryId=124')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519191399091671084.gif'" alt="">
      </div>
    </div>
    <!-- 男士护理结束 -->
    <!-- 6口腔专区 -->
    <div class="kqzq">
      <img class="head_img" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519232467929668856.jpg'" alt="">
      <activity-2-banner :bannerimg="bannerList.kqzq" />
      <div class="cate_list">
        <img @click="toUrl('/goodsList?catagoryId=109')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519198062376917112.jpg'" alt="">
        <img @click="toUrl('/goodsList?catagoryId=110')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519198973128089599.jpg'" alt="">
        <img @click="toUrl('/goodsList?catagoryId=111')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519199088710522739.jpg'" alt="">
        <img @click="toUrl('/goodsList?catagoryId=112')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519199124005590088.jpg'" alt="">
      </div>
      <!-- 商品列表 -->
      <activity-2-goods-list pricebg="price_bg6" titlecolor="goods_body_fa" :goodslist="goodsList.kqzq" />
      <div class="goods_more">
        <img @click="toUrl('/goodsList?catagoryId=79')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519192982571457911.gif'" alt="">
      </div>
    </div>
    <!-- 口腔专区结束 -->
    <!-- 7厨房用品 -->
    <div class="cfyp">
      <img class="head_img" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519232341739838169.jpg'" alt="">
      <activity-2-banner :bannerimg="bannerList.cfyp" />
      <div class="cate_list">
        <img @click="toUrl('/goodsList?catagoryId=140')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519213386509848900.jpg'" alt="">
        <img @click="toUrl('/goodsList?catagoryId=351')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519213459448798839.jpg'" alt="">
        <img @click="toUrl('/goodsList?catagoryId=143')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519213536061954803.jpg'" alt="">
        <img @click="toUrl('/goodsList?catagoryId=84')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519213825305352215.jpg'" alt="">
        
      </div>
      <!-- 商品列表 -->
      <activity-2-goods-list pricebg="price_bg7" titlecolor="goods_body_default" :goodslist="goodsList.cfyp"  />
      <div class="goods_more">
        <img @click="toUrl('/goodsList?catagoryId=84')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519212992685675425.gif'" alt="">
      </div>
    </div>  
    <!-- 厨房用品结束 -->
    <!-- 8卫浴用品 -->
    <div class="wyyp">
      <img class="head_img" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519214915811809477.jpg'" alt="">
      <activity-2-banner :bannerimg="bannerList.wyyp" />
   
      <!-- 商品列表 -->
      <activity-2-goods-list pricebg="price_bg8" titlecolor="goods_body_default" :goodslist="goodsList.wyyp" />
      <div class="goods_more">
        <img @click="toUrl('/goodsList?catagoryId=85')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519248004625731832.gif'" alt="">
      </div>
    </div> 
    <!-- 卫浴用品结束 -->
    <!-- 9成人用品专区 -->
    <div class="cryp">
      <img class="head_img" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519226868856788026.jpg'" alt="">
      <activity-2-banner :bannerimg="bannerList.cryp" />
      <div class="cate_list">
        <img @click="toUrl('/goodsList?catagoryId=88')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519227277243585085.jpg'" alt="">
        <img @click="toUrl('/goodsList?catagoryId=89')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519227480914794141.jpg'" alt="">
        <img @click="toUrl('/goodsList?catagoryId=332')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519227529547751076.jpg'" alt="">
        <img @click="toUrl('/goodsList?catagoryId=87')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519227595004056806.jpg'" alt="">
        
      </div>
      <!-- 商品列表 -->
      <activity-2-goods-list pricebg="price_bg9" titlecolor="goods_body_default" :goodslist="goodsList.cryp" />
      <div class="goods_more">
        <img @click="toUrl('/goodsList?catagoryId=87')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519227039187474475.gif'" alt="">
      </div>
    </div>
    <!-- 成人用品专区结束 -->
    <!-- 10衣服清洁 -->
      <div class="yfqj">
        <img class="head_img" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519229461339312478.jpg'" alt="">
        <activity-2-banner :bannerimg="bannerList.yfqj" />
        <div class="cate_list">
          <img @click="toUrl('/goodsList?catagoryId=130')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519229993881705972.jpg'" alt="">
          <img @click="toUrl('/goodsList?catagoryId=131')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519230067705650999.jpg'" alt="">
          <img @click="toUrl('/goodsList?catagoryId=132')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519230155060418776.jpg'" alt="">
          <img @click="toUrl('/goodsList?catagoryId=133')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519230192515553358.jpg'" alt="">
          <img @click="toUrl('/goodsList?catagoryId=134')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519230224207713270.jpg'" alt="">
          <img @click="toUrl('/goodsList?catagoryId=291')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519230266096227786.jpg'" alt="">
          <img @click="toUrl('/goodsList?catagoryId=344')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519230310736205354.jpg'" alt="">
          <img @click="toUrl('/goodsList?catagoryId=403')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519230344991087282.jpg'" alt="">
          
        </div>
        <!-- 商品列表 -->
        <activity-2-goods-list pricebg="price_bg10" titlecolor="goods_body_default" :goodslist="goodsList.yfqj" />
        <div class="goods_more">
          <img @click="toUrl('/goodsList?catagoryId=82')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519229623818263417.gif'" alt="">
        </div>
      </div>
    <!-- 衣服清洁结束 -->
    <!-- 11居家用品 -->
      <div class="jjyp">
        <img class="head_img" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519230682804523273.jpg'" alt="">
        <activity-2-banner :bannerimg="bannerList.jjyp" />
        <div class="cate_list">
          <img @click="toUrl('/goodsList?catagoryId=135')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519231805598734798.jpg'" alt="">
          <img @click="toUrl('/goodsList?catagoryId=136')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519231862062455738.jpg'" alt="">
          <img @click="toUrl('/goodsList?catagoryId=137')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519231951673761190.jpg'" alt="">
          <img @click="toUrl('/goodsList?catagoryId=83')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519237065838959634.jpg'" alt="">
          
          
        </div>
        <!-- 商品列表 -->
        <activity-2-goods-list pricebg="price_bg11" titlecolor="goods_body_default" :goodslist="goodsList.jjyp" />
        <div class="goods_more">
          <img @click="toUrl('/goodsList?catagoryId=83')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519231741996307488.gif'" alt="">
        </div>
      </div> 
    <!-- 居家用品结束 -->
    <!-- 12时尚饰品 -->
      <div class="shipin">
        <img class="head_img" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519240277316869441.jpg'" alt="">
        <activity-2-banner :bannerimg="bannerList.shipin" />
        <!-- 商品列表 -->
        <activity-2-goods-list pricebg="price_bg12" titlecolor="goods_body_default" :goodslist="goodsList.shipin" />
        <div class="goods_more">
          <img @click="toUrl('/goodsList?catagoryId=333')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519233293012177526.gif'" alt="">
        </div>
      </div> 
    <!-- 时尚饰品结束 -->
    <!-- 13文具办公 -->
    <div class="wjbg">
        <img class="head_img" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519234595234518546.jpg'" alt="">
        <activity-2-banner :bannerimg="bannerList.wjbg" />
        <div class="cate_list">
          <img @click="toUrl('/goodsList?catagoryId=335')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519236580490877008.jpg'" alt="">
         <img @click="toUrl('/goodsList?catagoryId=337')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519236842165116054.jpg'" alt="">
          <img @click="toUrl('/goodsList?catagoryId=336')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519236626959571367.jpg'" alt="">
          <img @click="toUrl('/goodsList?catagoryId=334')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519236987950731664.jpg'" alt="">
        </div>
        <!-- 商品列表 -->
        <activity-2-goods-list pricebg="price_bg13" titlecolor="goods_body_default" :goodslist="goodsList.wjbg"  />
        <div class="goods_more">
          <img @click="toUrl('/goodsList?catagoryId=334')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519234636464526946.gif'" alt="">
        </div>
    </div>
    <!-- 文具办公结束 -->
    <!-- 14卫浴用品 -->
      <div class="wyyp">
        <img class="head_img" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519237535760391563.jpg'" alt="">
        <activity-2-banner :bannerimg="bannerList.wyyp" />
        <!-- 商品列表 -->
        <activity-2-goods-list pricebg="price_bg14" titlecolor="goods_body_default" :goodslist="goodsList.wyyp2" />
        <div class="goods_more">
          <img @click="toUrl('/goodsList?catagoryId=85')" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519237598528150555.gif'" alt="">
        </div>
      </div> 
    <!-- 卫浴用品结束 -->
    <!-- 驱蚊驱虫专区 -->
      <div class="quwen">
        <img class="head_img" v-lazy="'https://static.ripinhui.shop/static/i/2023/09/28/5519239692102408177.jpg'" alt="">
        <img class="imgs" @click="toUrl('/goodsList?catagoryId=333')" src="https://static.ripinhui.shop/static/i/2023/09/28/5519239256733652312.jpg" alt="">
        <img class="imgs" @click="toUrl('/goodsList?catagoryId=333')" src="https://static.ripinhui.shop/static/i/2023/09/28/5519239460052538571.jpg" alt="">
        <img class="imgs" @click="toUrl('/goodsList?catagoryId=333')" src="https://static.ripinhui.shop/static/i/2023/09/28/5519239532865656858.jpg" alt="">
        <img class="imgs" @click="toUrl('/goodsList?catagoryId=333')" src="https://static.ripinhui.shop/static/i/2023/09/28/5519239567837763798.jpg" alt="">
        <img class="imgs" @click="toUrl('/goodsList?catagoryId=333')" src="https://static.ripinhui.shop/static/i/2023/09/28/5519239638910246263.jpg" alt="">
        <van-list
              v-model="loading"
              :finished="finished"
              offset="100"
              :immediate-check="false"
              @load="onLoad"
              >
        <img class="bottom_img" v-lazy="'https://static.ripinhui.shop/static/i/2023/10/11/5523794771857903660.png'" alt="">
        
        <activity-2-goods-list pricebg="price_bg15" titlecolor="goods_body_default" :goodslist="goodsList.quwen" />
        </van-list>
      </div> 
    <!-- 驱蚊驱虫专区结束 -->
  </div>
</template>

<script>
import activity2Banner from '../../components/Activity2Banner.vue';
import Activity2GoodsList from '../../components/Activity2GoodsList.vue';

export default {
    components: { activity2Banner, Activity2GoodsList },
  data(){
    return{
      logo_btn_id:1,
      ppgs_img:['/static/i/2023/09/27/5518702013862387093.jpg',
      '/static/i/2023/09/27/5518702709231849508.jpg',
      '/static/i/2023/09/27/5518702787757608965.jpg',
      '/static/i/2023/09/27/5518702873778590365.jpg'],
      goodsList:{},
      bannerList:{},
      page: 2, //页码
      loading: false,//设置为 true，表示分页处于加载状态
      finished: false//	是否已加载完成，加载完成后不再触发load事件
    }
  },
  created() {
    this.getGoodsList()
    this.getBannerList()
    let timer = setInterval(() => {
      this.logo_btn_id = this.logo_btn_id+1
      if(this.logo_btn_id+1==6){
        this.logo_btn_id = 2
      }
     //需要定时执行的代码
    },4000)
  },
  methods: {
    onLoad() {
            this.loading = true;
            this.getALLgoodsList()
    },
    logoBtn(id){
      this.logo_btn_id = id
    },
    toUrl(url){
      this.$router.push(url);
    },
    getGoodsList(){
      let that = this
      that.$request('/api/hd2/getGoodsList', {})
          .then(res => {
              that.goodsList = res.data.data
          })
    },
    getBannerList(){
      let that = this
      that.$request('/api/hd2/getImgList', {})
          .then(res => {
              that.bannerList = res.data.data
          })
    },
    getALLgoodsList()
    {
      let res = {
                page: this.page,
                token: this.$store.state.token,
                category_id:28
            }
      let that = this
      that.$request('/api/goodsList', res)
          .then(res => {
            // console.log(res.data.data,'===')
            //         return
             this.loading = false;
            //     if (Object.keys(that.bottomGoodsListAll).length===0) {
            //         that.bottomGoodsListAll = res.data.data
            //     }else{
                    that.goodsList.quwen = that.goodsList.quwen.concat(res.data.data.data)
            //     }
                if(res.data.data.data.length==0){
                    this.finished = true
                }
                that.page = that.page+1

          })
    },
  }
}
</script>
<style src="./activity2.css" scoped>
</style>
<style  scoped>
html,body {
  overflow-x: hidden;
}
@font-face {
  font-family: 'AlibabaPuHuiTi';
  src: url('https://static.ripinhui.shop/static/font/AlibabaPuHuiTi-2-75-SemiBold.otf') format('truetype');

}
.content .head {
  line-height: 0 !important; /* 将行高设置为0 */
  
}
</style>