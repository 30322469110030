<template>
    <div class="head_top">
        
        <img
            class="top_left_icon"
            @click="goOff()"
            :src="search_top_left_icon"
            alt=""
            v-if="left_ico==1"
            
        />
        <span :class="left_ico==2?'mleft30 title':'title'">{{ title }}</span>
    </div>
</template>

<script>
export default {
    props: ["title","left_ico",'url'],
    data() {
        return {
            search_top_left_icon: require("../static/image/search_top_left_icon.png"),
        };
    },
    watch: {},

    methods: {
        goOff() {
            // console.log(typeof(this.url)=='undefined')
            if(typeof(this.url)!='undefined'){
             
                this.$router.push(this.url)
                return;
            }
            // this.$router.go(-1);
            history.back(-1)
        },
    },
};
</script>

<style scoped>
.head_top {
    background: linear-gradient(90deg, #2866ff 0%, #5488ff 100%);
    display: flex;
    align-items: center;
    padding-bottom: 20px;
}
.top_left_icon {
    width: 44px;
    height: 44px;
    margin: 20px 0 0 20px;
}
.title {
    font-size: 44px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
    margin-top: 24px;
}
.mleft30{
    margin-left:30px;
}
</style>