<template>
  <div>
    <head-top-white title="消息中心" left_ico="1"></head-top-white>
    <div class="station_msg">
      <div class="station_msg_color_block">站内消息</div>
    </div>
    <div class="list" @click="toDetail">
      <div class="left_icon">
        <img src="../../static/image/message_icon.png" alt="">
      </div>
      <div class="right_content">
        <div class="top">
          <span class="title">确认收货通知</span>
          <span class="time">2022-7-7 18:32:44</span>
        </div>
        <div class="bottom">
          <span>亲,您的订单wx215454451521545,商品日本SK-2大红瓶</span>
        </div>
      </div>
    </div>
    <div class="list">
      <div class="left_icon">
        <img src="../../static/image/message_icon.png" alt="">
      </div>
      <div class="right_content">
        <div class="top">
          <span class="title">确认收货通知</span>
          <span class="time">2022-7-7 18:32:44</span>
        </div>
        <div class="bottom">
          <span>亲,您的订单wx215454451521545,商品日本SK-2大红瓶</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeadTopWhite from "@/components/HeadTopWhite.vue";
export default {
  components: {
    HeadTopWhite,
  },
  methods:{
        toDetail(){
            this.$router.push("/news_detail");
        },
  }
};
</script>

<style src="./news.css" scoped>
</style>

<style scoped>

</style>
