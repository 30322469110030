<template>
    <div>
        <head-top title="企业介绍" left_ico="1" url="/" />
        <!-- <div class="title" >
            企业介绍
        </div> -->
        <div  v-html="qyjsText">
        </div>
    </div>

</template>

<script>
import HeadTop from '@/components/HeadTop.vue'

export default {
    components: { HeadTop },
    data() {
        return {
            qyjsText:''
        }
    },
    created(){
        this.getQyjsText()
    },
    methods:{
        getQyjsText(){
             let that = this
            that.$request('/api/articleDetails', { article_id: 36 })
                .then(res => {
                    console.log(res.data.data)
                    that.qyjsText = res.data.data.content
                    
                })
        }
    }
}
</script>
<style src="./qyjs.css" scoped>
</style>
<style scoped>
</style>