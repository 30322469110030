<template>
    <div>
        <van-search
            shape="round"
            background="linear-gradient(90deg, #2866ff 0%, #5488ff 100%)"
            placeholder="搜索商品"
            :left-icon="left_icon_img"
            @focus="btn_search"
        />
    </div>
</template>

<script>
export default {
    data() {
        return {
            left_icon_img: require("../static/image/search_left_icon.png"),
        };
    },
    methods: {
        btn_search() {
            this.$router.push("/search");
        },
    },
};
</script>

<style scoped>
/deep/.van-field__left-icon {
    margin: auto;
    padding-right: 10px;
}
/deep/ .van-search__content {
    background-color: #fff !important;
}
</style>