<template>
    <div :class="'hot_body '+topimg">
      <img class="top_img" :src="headImg[bgimg]" alt="">
   
        <div class="goods_body">

          <div class="goods_info" v-for="(item,index) in goodslist" @click="toUrl('/goods?goodsId='+item.id)">
            <img class="goods_img" v-lazy="$imgUrl+item.img_url"   v-if="item.thumbnail==null">
            <img class="goods_img"  v-lazy="$imgUrl+item.thumbnail" v-if="item.thumbnail">
            <div class="goods_title">
              {{item.title}}
            </div>
            <div class="goods_price">
              ≈RMB:{{item.price_cny.money1}}
            </div>
          </div>

          
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
           headImg:{
                'ls':'https://static.ripinhui.shop/static/i/2023/12/25/5551122693224404738.png',
                'st':'https://static.ripinhui.shop/static/i/2023/12/19/5548913486672696631.png',
                'ts':'https://static.ripinhui.shop/static/i/2023/12/25/5551122866407218519.png',
                'zg':'https://static.ripinhui.shop/static/i/2023/12/19/5548913665631065388.png',
        }
        };
    },
    props: ["bgimg","topimg",'goodslist'],
    methods: {
        toUrl(url){
            this.$router.push(url);
        },
    },
};
</script>

<style scoped>
.hot_body{
    width: 728px;
    /* height: 1115px; */
   padding-bottom: 36px;
    background: #F6F6F6;
    border-radius: 50px;
    margin: 80px auto 0 auto;

    
}
.bg1{
    background-image:url('https://static.ripinhui.shop/static/i/2023/12/19/5548915058249045088.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
}
.bg2{
    background-image:url('https://static.ripinhui.shop/static/i/2023/12/19/5548915124317723105.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
}
.bg3{
    background-image:url('https://static.ripinhui.shop/static/i/2023/12/19/5548917557060178774.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
}
.bg4{
    background-image:url('https://static.ripinhui.shop/static/i/2023/12/19/5548917681937192096.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;

}
.hot_body .top_img{
    width: 100%;
    margin-top: -20px;
    margin-bottom: 35px;
}
.hot_body .goods_body{
    padding:25px 25px 10px 25px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.hot_body .goods_body .goods_info{
    width: 219px;
    height: 326px;
    background: #FFFFFF;
    border-radius: 20px;
    margin-bottom: 25px;
}
.hot_body .goods_body .goods_info .goods_img{
    width: 219px;
    height: 219px;
    border-radius: 20px 20px 0 0;
}
.hot_body .goods_body .goods_info .goods_title{
    overflow: hidden;
    text-overflow: ellipsis; /* 用省略号表示截断的文本 */
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 设置显示的行数为2 */
    -webkit-box-orient: vertical;
    /* 其他样式属性，如字体大小、行高等，根据需要添加 */
    line-height: 1.5; /* 设置行高，根据需要调整 */
    padding: 0 20px;
    font-size: 14px;
}
.hot_body .goods_body .goods_info .goods_price{
    width: 180px;
    height: 32px;
    border-radius: 20px;
    background-color: #EF0000;
    margin: 10px auto 0 auto;
    color: #fff;
    line-height: 32px;
    text-align: center;
}
.hot_body .goods_bottom{
    width: 588px;
    height: 37px;
    border-radius: 30px;
    background-color: #fff;
    margin: 0 auto;
    line-height: 37px;
    text-align: center;
    color: #000;
    font-weight: 600;
}
</style>