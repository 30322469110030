import Vue from "vue"
import Vuex from "vuex"
Vue.use(Vuex);
export const store =  new Vuex.Store({
    state:{
        token:'',
        uid:''

    },
    mutations: {
        changeToken (data,token) {
            // console.log(token)
            store.state.token = token
        },
        getToken() {
            store.state.token = localStorage.getItem('token')
        },
        getUid() {
            store.state.uid = localStorage.getItem('uid')
        }
    }
})
