<template>
  <div class="sygl">
     <head-top title="收益管理" left_ico="1"></head-top>
    <van-notice-bar
  left-icon="volume-o"
  text="提现需审核通过后才能到到账，单次提现最少10元"
/>
    <div class="balance">
        <div class="title">收益</div>
        <div class="money">￥{{userInfo.money}} RMB</div>
    </div>
    <div class="btn">
        <van-button type="primary"  size="normal" block color="#4c83ff" round @click="btnShow=true">立即提现</van-button>
    </div>
    <van-tabs class="lst" v-model="active">
        <van-tab title="收益记录">
            <div class="sy_list van-hairline--bottom"  v-for="(item,index) in amountRecord">
                <div class="title">下级购物提成</div>
                <div class="time">{{item.create_time}}</div>
                <div class="money">+{{item.money}}</div>
            </div>
        </van-tab>
        <van-tab title="提现记录">
            <div class="tx_list van-hairline--bottom" v-for="(item,index) in withdrawalRecord">
                <div class="title">提现至支付宝{{item.money}}</div>
                <div class="time">{{item.create_time}}</div>
                <div class="status1" v-if="item.status==1">审核中</div>
                <div class="status2" v-if="item.status==2">已打款</div>
            </div>
        </van-tab>
    </van-tabs>
    <van-popup v-model="btnShow" position="bottom" class="btnPopup" :style="{ height: '46%' }" >
        <div class="head">
            <div class="title">可提现金额</div>
            <div class="money">￥{{userInfo.money}}</div>
        </div>
        <div class="tx_input">
            <div class="input_list van-hairline--bottom">
                <div class="text_left">账号类型</div>
                <div class="text_right">支付宝</div>
            </div>
            <div class="input_list van-hairline--bottom">
                <div class="text_left">真实姓名</div>
                <div class="text_input">
                    <van-field v-model="name" input-align="right" placeholder="请输入真实姓名" />
                </div>
            </div>
            <div class="input_list van-hairline--bottom">
                <div class="text_left">支付宝账号</div>
                <div class="text_input">
                    <van-field v-model="accountNumber" input-align="right" placeholder="请输入支付宝账号" />
                </div>
                
            </div>
            <div class="tx_btn">
                    <van-button type="primary" v-if="tx_btn==false" color="#4c83ff"  @click="withdrawal()">确认</van-button>
                     <van-button type="primary" v-if="tx_btn==true" disabled  color="#4c83ff"  >确认</van-button>
                    <van-button type="primary" plain  color="#4c83ff" @click="btnShow=false">取消</van-button>
            </div>
        </div>
    </van-popup>
  </div>
</template>

<script>
import HeadTop from "@/components/HeadTop.vue";
export default {
    components: {
        HeadTop,
    },
    data(){
      return{
          amountRecord:[],
          withdrawalRecord:[],
          btnShow:false,
          name:'',//真实姓名
          accountNumber:'',//详细账号
          tx_btn:false,//点击提现按钮禁用
          userInfo:[]

      }
    },
    created(){
        this.getAmountRecord()
        this.getWithdrawalRecord()
        this.getUserInfo()
    },

    methods:{
       
      getAmountRecord(){
        let that = this
        that.$request('/api/getAmountRecord', { token:this.$store.state.token })
          .then(res => {
            that.amountRecord = res.data.data
            // console.log(res.data.data)

          })
      },
    //   提现记录
      getWithdrawalRecord(){
        let that = this
        that.$request('/api/getWithdrawalRecord', { token:this.$store.state.token })
          .then(res => {
            that.withdrawalRecord = res.data.data
          })
      },
      withdrawal(){
          this.tx_btn = true
        //   console.log(this.name,this.accountNumber)
          this.$request('/api/withdrawal', { 
              token:this.$store.state.token,
              name:this.name,
              account_number:this.accountNumber
               })
          .then(res => {
             
            this.withdrawalRecord = res.data.data
            this.tx_btn = false
            if(res.data.code==1){
                 this.$toast('提现申请提交成功');
                this.btnShow = false
                this.userInfo.money=0;
                this.getWithdrawalRecord()
            }
          })
      },
      getUserInfo(){
            this.$request('/api/getUserInfo', {
                token: this.$store.state.token
            })
            .then(res => {
                this.userInfo = res.data.data
            })
        },
    },
    
}
</script>
<style src="./sygl.css" scoped>
</style>
<style scoped>
.sygl{
    background: #FFFFFF;
    height: 100vh;
    overflow:hidden;
    overflow: scroll;
}
/deep/.van-tabs__line{
    background-color: #4c83ff !important;
          
  }
/deep/.van-cell{
    padding: 0 !important;
}
/deep/ .van-field__control{
    width: 280px;
}
</style>