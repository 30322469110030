<template>
    <div>
        <head-top title="商品分类" left_ico="1"></head-top>
        <head-search />
        <nav-bar />
        <div class="content">
            <div class="left_body">
                <!-- <van-sidebar v-model="activeKey">
                    <van-sidebar-item title="全部" @click="clickTopCate(-1)" />
                    <van-sidebar-item :title="item.category_name" v-for="(item,index) in categoryList" v-if="item.pid==0" @click="clickTopCate(item,index)" />
                </van-sidebar> -->
                <div :class="isActive==-1?'cate_left_border cate':'cate'" @click="leftCateClick(-1)">
                    <div @click="clickTopCate(-1)">
                        <div :class="isActive==-1? 'cate_name_click cate_name1':'cate_name1' ">全部</div>
                    <div class="cate_name2">すべて</div>
                    </div>
                </div>
                 <div   
                 v-for="(item,index) in categoryList" 
                 v-if="item.pid==0" 
                 @click="leftCateClick(index)">
                    <!-- <div :class="isActive==index? 'cate_name_click cate':'cate' "> -->
                        <div :class="isActive==index? 'cate_left_border cate':'cate' " @click="clickTopCate(item,index)">
                            <div :class="isActive==index? 'cate_name_click cate_name1':'cate_name1' " >{{item.category_name}}</div>
                        <div class="cate_name2">{{item.jp_category_name2}}</div>
                    </div>
                    
                </div>
            </div>
            <div class="right_body" >
                <van-row :class="more ? 'cate_nav' : ''" v-if="activeKey!=0" >
                    <van-col span="22" v-if="more">
                        <div class="head">
                            <div class="tag_body" @click="toGoodsList(-2)" >全部</div>
                            <div class="tag_body" @click="toGoodsList(item)" v-for="(item,index) in subCategory">{{item.category_name}}</div>
                        </div>
                    </van-col>
                    <van-col span="22" v-if="!more">
                        <div class="head">
                            <div class="tag_body" @click="toGoodsList(-2)" >全部</div>
                            <!-- <van-sidebar-item title="全部" @click="clickTopCate(-1)" /> -->
                            <div class="tag_body" @click="toGoodsList(item)" v-for="(item,index) in subCategory" v-if="index<=4">{{item.category_name}}</div>
                        </div>
                    </van-col>
                    <van-col span="2">
                        <span class="cate_all_text" v-if="!more">全部</span>
                        <span class="cate_all_text" v-if="more">关闭</span>
                        <!-- 全部分类> -->
                        <img class="lower" @click="lower" src="../../static/image/cate_all_ico.png" alt="" />
                        
                    </van-col>
                </van-row>

                <div>
                    <div :class="more ? 'mask' : 'mask2'"></div>
                    <div class="goods" id="goods" @scroll="scrollbottom($event)">
                        <!-- 商品 -->
                        <van-list
                            v-model="loading"
                            :finished="finished"
                            :immediate-check="false"
                            @load="onLoad"
                            >
                        <div class="goods_body" v-for="(item,index) in goodsList.data">
                            <div @click="toGoods(item.id)">
                                <van-image class="goods_img" :src="$imgUrl+item.thumbnail" v-if="item.thumbnail"/>
                                <van-image class="goods_img" :src="$imgUrl+item.img_url" v-if="!item.thumbnail"/>
                                <van-image class="goods_img2" :src="$sellOut" v-if="item.sellOut==2"/>
                            </div>
                            <div class="goods_info">
                                <div class="goods_top" @click="toGoods(item.id)">
                                    <div class="goods_title">{{item.title}}</div>
                                </div>
                                <div class="goods_bottom">
                                    <div class="goods_info_left">
                                        <span class="original_price" v-if="item.original_price_cny.money!='0人民币'">{{item.original_price_cny.money}}</span>
                                        <span class="price">{{item.price_cny.money}}</span>
                                    </div>
                                    <div class="goods_info_right">
                                        <img @click="addShopCart(item.id)" src="../../static/image/shop_cart.png" v-if="item.specs_type==1" />
                                        <van-button round type="primary" size="small" color="#4c83ff" v-if="item.specs_type==2&&item.sellOut==1" @click="getGoodsInfo(item.id)">选规格</van-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </van-list>
                    </div>
                </div>
            </div>
        </div>
        <!-- 购物车框 -->
        <div class="shop_cart" @click="toShopCart">
            <van-row>
                <van-col span="14">
                    <img src="../../static/image/shop_cart.png" alt="" />
                </van-col>
                <van-col span="5">
                    <span class="price">{{shopCartPrice}}日元</span>
                </van-col>
                <van-col span="5">
                    <van-button type="primary" size="small" color="#ca2d2d" round class="btn">去结算</van-button>
                </van-col>
            </van-row>
        </div>
        <van-popup v-model="skuShow" position="bottom" closeable :style="{ height: '70%' }">
            <div class="goods_sku">
                <div class="goods_sku_head">
                    <div class="sku_top_left">
                        <img :src="skuGoodsImg" alt="">
                    </div>
                    <div class="sku_top_right">
                        <div class="sku_title text_rows1">{{goods.title}}</div>
                        <div class="sku_price">{{skuGoodsPrice}}日元</div>
                        <div class="sku_stock">库存：{{skuGoodsStock}}</div>
                    </div>
                </div>
                <div class="sku_body">

                    <div class="sku_list" v-for="(item,index) in skuList">
                        <div class="sku_list_title">{{index}}</div>
                        <div>
                            <ul class="suk_btn">
                                <li :class="subIndex[index]==index2?'productActive':''" @click="selectSkuBtn(item2,index,index2)" v-for="(item2,index2) in item">{{item2}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="sku_bottom">
                    <div class="number">
                        <span class="title">数量</span>
                        <van-stepper v-model="orderQuantity" />
                    </div>
                    <van-button type="primary" block round color="#4c83ff" @click="addShopCart(-1)">确定</van-button>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import HeadTop from "@/components/HeadTop.vue";
import HeadSearch from "@/components/HeadSearch.vue";
import NavBar from "@/components/NavBar.vue";
export default {
    components: {
        HeadSearch,
        HeadTop,
        NavBar,
    },
    data() {
        return {
            isActive: -1,//侧边栏选中
            activeKey: 1,
            more: false,
            categoryList: {}, //分类列表
            subCategory: {}, //子分类
            page: 1, //页码
            goodsList: {},
            pid: "",
            category_id:'',
            goods: [],
            skuShow: false,
            skuList: [],
            orderQuantity: 1, //选中商品数量
            selectArr: [], //存放被选中的值
            subIndex: [], //是否选中 因为不确定是多规格还是但规格，所以这里定义数组来判断
            selectSkuInfo: {}, //多规格选中sku商品详情
            shopCartPrice:0,
            skuGoodsImg:'',
            skuGoodsPrice:'',
            skuGoodsStock:'',
            loading: false,//设置为 true，表示分页处于加载状态
            finished: false,//	是否已加载完成，加载完成后不再触发load事件
            homeTop : 0,
        };
    },
    created() {
        localStorage.setItem('category_scrollTop',0)
        this.token = localStorage.getItem('token');
        this.getCategoryList()
        this.countCartAmount()
        this.getGoods()
        this.getUserInfo()
    },
    activated(){
        setTimeout(()=>{
            document.getElementById('goods').scrollTop = localStorage.getItem('category_scrollTop')
        },20)
    },
    // deactivated(){
    //     console.log('销毁页面')
    // },
    beforeRouteLeave (to, from, next) {
        // console.log('leave')
        // let app = document.getElementById('goods')
        // this.homeTop = app.scrollTop || 0
        next()
    },

    methods: {
        scrollbottom(event){
            let el = event.target;
            this.homeTop = el.scrollTop
            localStorage.setItem('category_scrollTop',el.scrollTop)
            // console.log(el.scrollTop)
        },
        onLoad() {
            this.getGoods()
        },
        leftCateClick(index){
            this.finished = false
            // console.log(index)
                this.isActive = index
        },
        lower() {
            this.more = !this.more;
        },
        getCategoryList() {
            let that = this
            that.$request('/api/categoryList', {})
                .then(res => {
                    that.categoryList = res.data.data
                    this.selectSubCategory(res.data.data[0].id)
                })
        },
        // 点击左侧顶级分类
        clickTopCate(data, index) {
            this.more = false;
            this.selectSubCategory(data.id)
            this.goodsList = {}
            this.category_id = data.id
            this.page = 1
            this.getGoods()


        },
        // 计算购物车金额
        countCartAmount() {
            this.$request('/api/countCartAmount', { "token": this.token })
                .then(res => {
                    this.shopCartPrice = res.data.data.data.price
                })
        },
        selectSubCategory(cateId) {
            let cateArr = []
            this.categoryList.forEach(item => {
                if (item.pid == cateId) {
                    cateArr.push(item)
                }
            });
            this.subCategory = cateArr
        },
        toGoodsList(goods) {
            
            let data = {

            }
            if(goods!=-1){
                data.catagoryId = goods.id
            }
            if(goods==-2){
                data.catagoryId = this.category_id
                if(this.category_id==''){
                    data.catagoryId = -1
                }
                // console.log( this.category_id)
                
            }
            this.$router.push({ path: "/goodsList", "query": data})
        },
        toGoods(goodsId) {
            this.$router.push({ path: "/goods", "query": { goodsId } })
        },
        toShopCart(){
            this.$router.push("/shopCart");
        },
        getGoods() {
            
            let that = this
            let res = {
                page: this.page
            }
            if (that.category_id) {
                res.category_id = that.category_id
            }
            // return console.log(res)
            this.$request('/api/goodsList', res)
                .then(res => {
                    this.loading = false;
                    if (Object.keys(that.goodsList).length === 0) {
                        that.goodsList = res.data.data
                    } else {
                        that.goodsList.data = that.goodsList.data.concat(res.data.data.data)
                    }
                    if(res.data.data.data.length==0){
                        this.finished = true
                    }
                    this.page = res.data.data.current_page+1
                })
        },
        //获取商品详情
        getGoodsInfo(goodsId) {
            let that = this
            that.orderQuantity = 1
            this.$request('/api/getGoodsDetail', {
                goods_id: goodsId,
                token:this.$store.state.token
            })
                .then(res => {
                    // console.log(res.data.data.goods.img_url)
                    that.goods = res.data.data.goods
                    that.skuList = JSON.parse(res.data.data.goods.attribute_list);
                    that.skuGoodsImg = this.$imgUrl + this.goods.img_url[0]
                    that.skuGoodsPrice = this.goods.price
                    that.skuGoodsStock = this.goods.goods_stock;
                    that.skuShow = true
                })
        },

        selectSkuBtn(item, index, index2) {
            if (this.selectArr[index] != item) {
                this.selectArr = []
                this.selectArr[index] = item
                this.subIndex[index] = index2

            } else {
                this.selectArr[index] = ""
                this.subIndex[index] = -1
            }
             this.getSkuDetail(this.selectArr)
            // console.log(123)
            this.$forceUpdate();
            // if (Object.keys(this.skuList).length == 1) {
            //     this.getSkuDetail(this.selectArr)
            // } else {
            //     this.getSkuDetail(this.selectArr)
            // }
        },
        getSkuDetail(skuValue) {
            // console.log(skuValue)
            let that = this
            this.$request('/api/getSkuDetail', {
                sku_value: skuValue,
                goods_id: this.goods.id
            })
                .then(res => {
                    if (!res.data.data) {
                        return;
                    }
                    let data = res.data.data
                    this.skuGoodsImg = this.$imgUrl + data.thumbnail
                    this.skuGoodsStock = data.goods_stock
                    this.selectSkuInfo = res.data.data
                    // console.log(this.selectSkuInfo)
                    // console.log(res.data.data,'接收数据')
                })
        },
        addShopCart(id) {
            // console.log(this.selectSkuInfo)
            // return;
            let that = this
            let data = {}

            if (id == -1) {
                if (Object.keys(this.selectArr).length == 0) {
                    this.$toast('请正确选择规格')
                    return
                }
                if (Object.keys(this.skuList).length == 2) {
                    if (Object.keys(this.selectArr).length == 1) {
                        this.$toast('请正确选择规格')
                        return
                    }
                }
                data = {
                    quantity: this.orderQuantity,
                    sku_id: this.selectSkuInfo.id,
                    goods_id: this.selectSkuInfo.goods_id
                }
            } else {
                data = {
                    quantity: 1,
                    sku_id: -1,
                    goods_id: id
                }
            }
            // console.log(data)
            // return;
            data.token = this.$store.state.token
            that.$request('/api/addShopCart', data)
                .then(res => {
                    this.$toast('已成功加入到购物车')
                })
            this.countCartAmount()
        },
        getUserInfo(){
            this.$request('/api/getUserInfo', {
                token: this.$store.state.token
            })
            .then(res => {
                localStorage.setItem('discount',res.data.data.discount)
            })
        }
    },
};
</script>
<style src="./category.css" scoped>
</style>
<style scoped>
/deep/.van-sidebar-item--select::before {
    background-color: #4c83ff !important;
}
/deep/.van-button {
    padding: 0 30px !important;
}
/deep/.van-sidebar-item__text {
    font-size: 28px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #000000;
}
/deep/.van-sidebar-item {
    padding: 30px 20px;
}
/deep/.van-sidebar {
    /* width:180px; */
}
/deep/.right_body .cate_nav{
    width: 79%;
}
</style>
