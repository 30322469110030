<template>
    <div class="" >
        <nav-bar></nav-bar>
        <div>
            <div class="top_head">
                <div class="head_body">
                    <div class="top">
                    <div class="logo">
                        <img src="../../static/image/top_logo.png" alt="" srcset="" />
                    </div>
                    <span class="top_tips">精选日货 只做正品</span>
                    </div>
                    <head-search></head-search>
                </div>
                <van-swipe :autoplay="6000"  class="banner" :lazy-render="true" indicator-color="#fff"  >
                    <van-swipe-item v-for="(item, index) in bannerList" :key="index" @click="bannerChange(index)">
                        <!-- <van-image
                        class="banner_img"
                        :src="image"
                    /> -->
                    <!-- <div class="banner_body" >

                    </div> -->
                        <img class="banner_img" :src="$host+item.img_url"  />
                    </van-swipe-item>
                </van-swipe>
            </div>
            <div class="nav_bar">
                <div @click="toCategory">
                    <div class="nav_img">
                        <img src="../../static/image/nav1.png" />
                    </div>
                    <span>商品分类</span>
                </div>
                <div  >
                    <div class="nav_img">
                        <img src="../../static/image/nav2.png" />
                    </div>
                    <span>日本代购</span>
                </div>
                <div @click="toTczy">
                    <div class="nav_img">
                        <img src="../../static/image/nav3.png" />
                    </div>
                    <span>天诚转运</span>
                </div>
                <div @click="toQyjs">
                    <div class="nav_img">
                        <img src="../../static/image/nav4.png" />
                    </div>
                    <span>企业介绍</span>
                </div>
                <div @click="toMember">
                    <div class="nav_img">
                        <img src="../../static/image/nav5.png" />
                    </div>
                    <span>会员中心</span>
                </div>
            </div>
            <!-- 通知 -->
            <van-notice-bar :left-icon="notice_icon" :scrollable="false" background="#fff" color="#000">
                <van-swipe vertical class="notice-swipe" :autoplay="3000" :show-indicators="false">
                    <van-swipe-item v-for="(item,index) in indexNotice" @click="toArticle(item.id)">{{item.title}}</van-swipe-item>
                </van-swipe>
            </van-notice-bar>
            <!-- 精选单品 -->
            <!-- <div class="selected">
                <div class="top">
                    <van-row>
                        <van-col span="6">
                            <span class="title">精选单品</span>
                        </van-col>
                        <van-col span="14">
                            <img class="btzn" src="../../static/image/btzn.gif" />
                        </van-col>
                        <van-col span="4">
                            <span class="more" @click="toGoodsList(1)">更多</span>
                            <img class="more_ico" src="../../static/image/index_more.png" alt="" />
                        </van-col>
                    </van-row>
                </div>
                <div class="goods_body">
                    <div class="goods" @click="toGoods(item.id)" v-for="(item,index) in activityGoodsList.selected">
                        <img :src="$host+item.img_url" alt="" />
                        <div class="money">{{item.price}}日元</div>
                    </div>

                </div>
            </div> -->
            <!-- 精选单品 -->
            <div class="jxdp">
                <div class="top">
                    <div class="top_left">
                        <img class="title" src="../../static/image/index_jxdp_ico1.png" alt="">
                        <van-tag color="#FFB431">必屯指南</van-tag>
                        <!-- <div class="tag">必屯指南</div> -->
                    </div>
                    <div class="top_right">
                        <div @click="toGoodsList(1)">更多</div>
                        <img src="../../static/image/more_icon.png" alt="">
                    </div>
                </div>
                <div class="goods">
                    <div @click="toGoods(item.id)" v-for="(item,index) in activityGoodsList.selected">
                        <div><img class="goods_img" :src="$host+item.img_url" alt=""></div>
                        <div class="money">¥{{item.price_cny.money1}}</div>
                    </div>
                    <!-- <div>
                        <div><img class="goods_img" src="http://tcshopapi.ay1.cc/storage/topic/20220727/0565a8daf9c71c71b02c1aad7c34b542.jpg" alt=""></div>
                        <div class="money">¥123</div>
                    </div>
                    <div>
                        <div><img class="goods_img" src="http://tcshopapi.ay1.cc/storage/topic/20220727/0565a8daf9c71c71b02c1aad7c34b542.jpg" alt=""></div>
                        <div class="money">¥123</div>
                    </div>
                    <div>
                        <div><img class="goods_img" src="http://tcshopapi.ay1.cc/storage/topic/20220727/0565a8daf9c71c71b02c1aad7c34b542.jpg" alt=""></div>
                        <div class="money">¥123</div>
                    </div> -->
                </div>
            </div>
            <!-- 促销精品 -->
            <div class="cxjp">
                <div class="top">
                    <div class="top_left">
                        <img class="title" src="../../static/image/index_jxdp_ico2.png" alt="">
                        <van-tag color="#4c83ff">博主力荐</van-tag>
                        <!-- <div class="tag">博主力荐</div> -->
                    </div>
                    <div class="top_right">
                        <div @click="toGoodsList(2)">更多</div>
                        <img src="../../static/image/more_icon.png" alt="">
                    </div>
                </div>
                <div class="goods" >
                    <div class="goods_body"  @click="toGoods(item.id)" v-for="(item,index) in activityGoodsList.discount">
                        <div class="tag">博主力推</div>
                        <div class="goods_list">
                            <div><img class="left" v-lazy="$host+item.img_url" alt=""></div>
                            <div class="right">
                                <div class="title text_rows2">{{item.activity_title}}</div>
                                <div class="bottom"> 
                                    <div class="money">￥{{item.price_cny.money1}}</div>
                                    <div class="money_tag">折后价</div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <!-- <div class="index_hd2">
                <van-row>
                <van-col span="12">
                    <div class="banner">
                        <van-swipe :autoplay="3000">
                            <van-swipe-item v-for="(image, index) in hd2_images" :key="index">
                                <img v-lazy="image" />
                            </van-swipe-item>
                        </van-swipe>
                        <div class="left_bottom">
                            <div class="title">
                                <div>更多精选好物限时抢购>></div>
                                <div class="goods_list">
                                    <div class="goods_body">
                                        <div class="goods_img">
                                            <img src="http://static.555519.xyz/ripinhui/cf9b5147cd96667cd5389cd94bcb0d3a27dfd7f828488-Nzzie9@2x.png" alt="">
                                        </div>
                                        <div class="title">爆款抢购</div>
                                    </div>
                                    <div class="goods_body">
                                        <div class="goods_img">
                                            <img src="http://static.555519.xyz/ripinhui/2@2x.png" alt="">
                                        </div>
                                        <div class="title">明星大牌</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </van-col>
                <van-col span="12">
                    <div class="right_body">
                        <div class="top">
                            <div class="top_title">
                                <div class="title_text">全场满999减50</div>
                                <div class="jump">
                                    去看看<img src="../../static/image/index_hd2_ico_jump.png" alt="">
                                </div>
                               
                            </div>
                            <div class="goods">
                                <div class="goods_body">
                                        <div class="goods_img">
                                            <img src="http://static.555519.xyz/ripinhui/cf9b5147cd96667cd5389cd94bcb0d3a27dfd7f828488-Nzzie9@2x.png" alt="">
                                        </div>
                                        <div class="title">专享价469元</div>
                                </div>
                                <div class="goods_body">
                                        <div class="goods_img">
                                            <img src="http://static.555519.xyz/ripinhui/2@2x.png" alt="">
                                        </div>
                                        <div class="title">专享价1499元</div>
                                </div>
                            </div>
                        </div>
                        <div class="bottom">
                            <div class="head">
                                <div class="title">更多好物限时抢购!</div>
                                <div class="jump">
                                    去看看<img src="../../static/image/index_hd2_ico_jump.png" alt="">
                                </div>
                            </div>
                            <div class="goods">
                                <div class="goods_body">
                                    <div class="goods_img">
                                            <img src="http://static.555519.xyz/ripinhui/2@2x.png" alt="">
                                    </div>
                                    <div class="title">专享价1499元</div>
                                </div>
                                <div class="goods_body">
                                    <div class="goods_img">
                                            <img src="http://static.555519.xyz/ripinhui/2@2x.png" alt="">
                                    </div>
                                    <div class="title">专享价1499元</div>
                                </div>
                                <div class="goods_body">
                                    <div class="goods_img">
                                            <img src="http://static.555519.xyz/ripinhui/2@2x.png" alt="">
                                    </div>
                                    <div class="title">专享价1499元</div>
                                </div>
                                <div class="goods_body">
                                    <div class="goods_img">
                                            <img src="http://static.555519.xyz/ripinhui/2@2x.png" alt="">
                                    </div>
                                    <div class="title">专享价1499元</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </van-col>
                </van-row>
            </div> -->
            <!-- 促销精品 -->
            <!-- <div class="promotion">
                <div class="top">
                    <van-row>
                        <van-col span="6">
                            <span class="title">促销精品</span>
                        </van-col>
                        <van-col span="14">
                            <img class="btzn" src="../../static/image/dzlj.gif" />
                        </van-col>
                        <van-col span="4">
                            <span class="more" @click="toGoodsList(2)">更多</span>
                            <img class="more_ico" src="../../static/image/index_more.png" alt="" />
                        </van-col>
                    </van-row>
                </div>
                <div class="goods_body" >
                    <div class="goods" @click="toGoods(item.id)" v-for="(item,index) in activityGoodsList.discount">
                        <img :src="$host+item.img_url" alt="" />
                        <div class="goods_title text_rows1">{{item.activity_title}}</div>
                        <div class="price_body">
                            <div class="original_price">原价：{{item.original_price}}</div>
                            <div class="discount">活动价：{{item.price}}</div>
                            <img class="reduce" src="../../static/image/reduce.png" alt="" />
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- <div class="activity2">

            </div> -->
            <!-- 活动图片 -->
            <div class="activity" >
                <div class="top" >
                    <img v-for="(item,index) in activityJumpList" @click="toLink(item.link)" v-if="item.id==4||item.id==5||item.id==6" v-lazy="$host+item.img"   />
                </div>
                <div class="middle" >
                    <div class="hd_left">
                        <img v-for="(item,index)  in activityJumpList"  @click="toLink(item.link)" v-if="item.id==8||item.id==9" v-lazy="$host+item.img"   />
                    </div>
                    <div class="hd_right">
                        <img v-for="(item,index) in activityJumpList" @click="toLink(item.link)" v-if="item.id==7" v-lazy="$host+item.img"   />
                    </div>
                </div>
            </div>
            <!-- 分类标签栏 -->
            <div class="tabs">
                <van-tabs background="#f5f5f5" title-active-color="#4c83ff" color="#f5f5f5" class="vant_tabs" @click="checkCate" v-model="tabActive">
                    <van-tab v-for="(item,index) in cateList" :title="item.category_name" >
                          <van-list
                            v-model="loading"
                            :finished="finished"
                            offset="100"
                            :immediate-check="false"
                            @load="onLoad"
                            >
                        <div class="goods_body">
                          
                            <!-- <van-cell v-for="item in list" :key="item" :title="item" /> -->
                                <div :class="[index2%2 == 0 ?'goods goodsl':'goods']" @click="toGoods(item2.id)" v-for="(item2,index2) in goodsList.data">
                                <!-- <div class="goods" @click="toGoods(item2.id)" v-for="(item2,index2) in goodsList.data"> -->
                                    
                                    <img class="goods_img"  v-lazy="$imgUrl+item2.img_url" alt="" />
                                    <div class="goods_title">
                                        {{item2.title}}
                                    </div>
                                    <div class="goods_bottom">
                                        <span class="money">{{item2.price_cny.money}}</span>
                                        <span class="sales_volume">已售{{item2.sales}}</span>
                                    </div>
                                </div>
                         
                        </div>
                            </van-list>
                        <!-- <div v-if="pageEnd" style="width:100%;margin-top:20px;color:#999;display: flex;justify-content: center;">
                                <div>- 没有更多了 -</div>
                        </div> -->
                    </van-tab>
                </van-tabs>
            </div>

            <div style="height: 100px"></div>
        </div>
    </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";

import HeadSearch from '@/components/HeadSearch.vue';
// import { debounce  } from '@/plugin/common.js'
// import Vue from 'vue';

// Vue.use(Button);
export default {
    name: "HomeView",
    components: {
        NavBar,
        HeadSearch
    },
    data() {
        return {
            
            hd2_images:[
                
                require("../../static/image/index_hd2_ico.png"),
                require("../../static/image/index_hd2_ico.png"),
            ],
            left_icon_img: require("../../static/image/search_left_icon.png"),
            notice_icon:require("../../static/image/notice2.gif"),
            cateList: [],
            goodsList: {},
            page: 1, //页码
            pid:'', //选中顶级分类id
            activityGoodsList:{},//活动商品列表
            // pageEnd:false,
            tabActive:-1,
            loading: false,//设置为 true，表示分页处于加载状态
            finished: false,//	是否已加载完成，加载完成后不再触发load事件
            bannerList:[]//轮播列表
            ,indexNotice:[]//首页公告列表
            ,activityJumpList:[] //活动图片列表
        };
    },
    created() {
        window.addEventListener('scroll', this.Scrollbottom, true);  //页面加载时监听滚动事件
        this.getCategory()
        
        this.getActivityGoods()
        this.getBannerList()
        this.getIndexNotice()
        this.getActivityJumpList()
    },
    destroyed() {
        window.removeEventListener('scroll', this.Scrollbottom, true) //页面离开后销毁监听事件
    },
    methods: {
        //获取首页活动图片跳转列表
        getActivityJumpList(){
            let that = this
            this.$request('/api/activityJumpList', {
                token: this.$store.state.token,
                type:1
            })
                .then(res => {
                    that.activityJumpList = res.data.data
                })
        },
        toLink(val){
            if(val){
                  window.location = val
            }
        },
        bannerChange(index){
            if(this.bannerList[index].to_link){
                window.location = this.bannerList[index].to_link
            }
            
        },
        toArticle(id){
            this.$router.push({
                path:"/article"
                ,query:{
                    articleId:id
                }
            });
        },
        // 获取首页公告
        getIndexNotice(){
            let that = this
            this.$request('/api/getIndexNotice', {})
                .then(res => {
                    that.indexNotice = res.data.data
                })
        },
        btnSearch() {
            this.$router.push("/search");
        },
        toCategory() {
            this.$router.push("/category");
        },
        toMember() {
            this.$router.push("/member");
        },
        toQyjs() {
            this.$router.push("/qyjs");
        },
        toTczy() {
            this.$router.push("/tczy");
        },
        toGoods(goodsId) {
            this.$router.push({path:"/goods","query":{goodsId}})
        },
        toGoodsList(val) {
            this.$router.push({path:"/goodsList","query":{type:val}})
        },
        toJpGoods(){
            // this.$router.push("/jpGoods");
        },
        getCategory() {
            let that = this
            this.$request('/api/categoryList', { pid: -1 })
                .then(res => {
                     console.log(res.data.data)
                     res.data.data.unshift({category_name:'全部'})
                    that.cateList = res.data.data
                })
        },
        // 获取活动商品列表
        getActivityGoods(){
            let that= this
            that.$request('/api/activityGoodsList', {type:1})
                .then(res => {
                    that.activityGoodsList = res.data.data
                    this.getGoods()
                })
        },
        //获取轮播列表
        getBannerList(){
            let that= this
            that.$request('/api/getBannerList', {type:1})
                .then(res => {
                    that.bannerList = res.data.data
                })
        },
        getGoods() {
            let that = this
            
        //      let load = that.$toast.loading({
        //     message: "加载中...",
        //     forbidClick: true,
        //     duration:0
        //   });
            let res = {
                page: this.page 
            }
            if(that.pid){
                res.category_id=that.pid
            }

            this.$request('/api/goodsList', res)
                .then(res => {
                    this.loading = false;
                    if (Object.keys(that.goodsList).length===0) {
                        that.goodsList = res.data.data
                    }else{
                        that.goodsList.data = that.goodsList.data.concat(res.data.data.data)
                    }
                    if(res.data.data.data.length==0){
                        this.finished = true
                    }
                    this.page = res.data.data.current_page+1
                })

        },
        onLoad() {
            this.loading = true;
                this.getGoods()
        },
        //触底事件
        // Scrollbottom:debounce(function () {
           
        //     let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        //     let clientHeight = document.documentElement.clientHeight;
        //     let scrollHeight = document.documentElement.scrollHeight;
        //     //  console.log(scrollTop,clientHeight,scrollHeight)
        //     if (scrollTop+300 + clientHeight >= scrollHeight) {
        //         this.page = this.page + 1
        //         this.getGoods()
        //     }

        // },700),
        checkCate(index){
            this.pid = this.cateList[index].id
            this.page=1
            this.goodsList = {}
            this.getGoods()
        }
    },
};
</script>
<style src="./homeView.css" scoped>
</style>
<style  scoped>
/* @import'homeView.css'; */
/deep/ .van-tab {
    font-size: 34px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #333333;
}
/deep/ .van-notice-bar {
    height: 90px;
}
/deep/ .notice-swipe {
    height: 40px;
    line-height: 40px;
}

/deep/ .van-tabs__nav--line.van-tabs__nav--complete {
    padding-left: 0px;
}
/deep/.van-search{
    background:transparent !important;
}
/deep/.van-icon__image{
    width: 68px;
    height: 42px;
    margin-right: 10px;
}
</style>