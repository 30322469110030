<template>
  <div ref="contentData">
    <nav-bar />
        <div class="logo">
            <img src="https://static.ripinhui.shop/static/web_img/index/3.gif" alt="">
        </div>
        <div class="banner">
               <van-search
               class="searchs"
                v-model="value"
                shape="round"
                background="#none"
                placeholder="搜索产品"
                @focus="btn_search"
                />
            <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
            <van-swipe-item v-for="(item,index) in bannerList">
                 <a :href="item.to_link"><img  :src="$imgUrl+item.img_url" alt=""></a>
            </van-swipe-item>
            <!-- <van-swipe-item>
                 <a :href="bannerList[1].to_link"><img  :src="$imgUrl+bannerList[1].img_url" alt=""></a>
            </van-swipe-item> -->
            </van-swipe>
           
        </div>
        <div class="vip">
            <img @click="toLink('/hygz')" :src="$imgUrl+'/static/web_img/index/2.gif'" alt="">
        </div>
        <div class="goods_menu">
            <img class="title" src="https://static.ripinhui.shop/static/web_img/index/05_1.png" alt="">
            <div class="head">
                <img  v-for="(item,index) in activityJumpList" @click="toLink(item.link)" v-if="item.id==21||item.id==22" v-lazy="$imgUrl+item.img"   />
            </div>
            <div class="category">
                <img v-for="(item,index) in activityJumpList" @click="toLink(item.link)" v-if="item.id==10||item.id==11||item.id==12||item.id==13||item.id==14||item.id==15||item.id==17||item.id==18||item.id==19||item.id==20" v-lazy="$imgUrl+item.img"   />
            </div>
        </div>
        <div class="goods">
            <div class="head">
                <img src="https://static.ripinhui.shop/static/web_img/index/1.gif" alt="">
                <img src="https://static.ripinhui.shop/static/web_img/index/2023081303.png" alt="">
            </div>
         <van-tabs v-model="active" :key="timer" @click="handleload" swipeable>
            <van-tab title="闭眼下单">
                <index-1-goods-list-card :activityId="1" :goodsList="goodsList[1]"></index-1-goods-list-card>
            </van-tab>
            <van-tab title="平价人气">
                <index-1-goods-list-card :activityId="2" :goodsList="goodsList[2]"></index-1-goods-list-card>
            </van-tab>
            <van-tab title="明星同款">
                <index-1-goods-list-card :activityId="3" :goodsList="goodsList[3]"></index-1-goods-list-card>
            </van-tab>
            <van-tab title="平台专属">
                <index-1-goods-list-card :activityId="4" :goodsList="goodsList[4]"></index-1-goods-list-card>
            </van-tab>
        </van-tabs>   
        </div>
        <div class="bottom">
            <div class="bottom_img">
                <img @click="toTop()" src="https://static.ripinhui.shop/static/web_img/index/16.png" alt="">
                <img style="width:88%;margin:0 auto;" src="https://static.ripinhui.shop/static/web_img/index/17.png" alt="">
            </div>
        </div>
  </div>
</template>

<script>
import Index1GoodsListCard from '@/components/Index1GoodsListCard.vue';
import NavBar from '../../components/NavBar.vue';
export default {
    data(){
        return {
            timer:'',
            activityJumpList:[], //活动图片列表
            bannerList:[],
            goodsList:[],
            active:0,
        }
    },
    components: {
        Index1GoodsListCard,
        NavBar
    },
    created() {
        this.getActivityJumpList()
        this.getBannerList()
        this.getActivityGoodsList()
        setInterval(() => {
            this.count++ // 每次间隔1秒自增1
        }, 1000)
    },
    methods: {
        
        //获取轮播列表
        getBannerList(){
            let that= this
            that.$request('/api/getBannerList', {type:2})
                .then(res => {
                    that.bannerList = res.data.data
                })
        },
        btn_search() {
            this.$router.push("/search");
        },
        handleload(){
            this.timer = new Date().getTime()
        },
        //获取首页活动图片跳转列表
        getActivityJumpList(){
            let that = this
            this.$request('/api/activityJumpList', {
                token: this.$store.state.token,
                type:1
            })
                .then(res => {
                    that.activityJumpList = res.data.data
                })
        },
        toLink(val){
            // console.log(val)
            if(val){
                  window.location = val
            }
        },
        toTop(){
            window.scrollTo( 0, 100 );
        },
        getActivityGoodsList(){
            this.$request('/api/getActivityGoodsList', {activity_id:[1,2,3,4]})
            .then(res => {
                this.goodsList = res.data.data
            })
        
        },
    },
}
</script>
<style src="./index1.css" scoped>
</style>
<style scoped>
/deep/.goods .van-tabs__nav{
    background: none;
}
/deep/.goods .van-tabs__line{
    height: 4px;
    background-color: #D7343A;
}
/deep/ .van-tab--active {
    color: #323233;
    font-weight: 800;
}

/deep/ .van-search{
    z-index:999;
}
</style>