<template>
    <div class="address_list">
        
        <head-top-white title="地址管理" left_ico="1" url="-1"></head-top-white>
        <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o" v-if="active==0">
        点击下方文字切换国际和国内收货地址表单
        </van-notice-bar>
        <van-notice-bar color="#ca2d2d"  wrapable   left-icon="info-o" v-if="active==1">
        国际快递请在下方框内输入英文字母和数字。收件人姓名、地址仅限寄送中国大陆、港澳台地区的情况下，可以使用中文（简体字/繁体字）。
        </van-notice-bar>
        <van-tabs @click="tabClick()" v-model="active" >
            <van-tab title="添加国内收货地址" :disabled="disabled1">
                <van-cell-group class="input_body">
                    <div class="input_title">
                        收货人
                    </div>
                    <van-field v-model="form.name" placeholder="请输入收货人姓名" />

                </van-cell-group>
                <van-cell-group class="input_body">
                    <div class="input_title">
                        手机号码
                    </div>
                    <van-field v-model="form.phone" placeholder="请输入手机号码" />
                </van-cell-group>
                <van-cell-group class="input_body">
                    <div class="input_title">
                        所在地区
                    </div>
                    <van-field v-model="form.region" @click="show=true" readonly placeholder="请输入所在地区" />
                </van-cell-group>
                <van-cell-group class="input_body">
                    <div class="input_title">
                        详细地址
                    </div>
                    <van-field v-model="form.address" rows="2" autosize type="textarea" maxlength="50" placeholder="请输入详细地址" />
                </van-cell-group>

                <div class="set_default_switch">
                    <div class="switeh_title">
                        设置为默认收货地址
                    </div>
                    <div>
                        <van-switch v-model="checked" active-color="#4c83ff" inactive-color="#dcdee0" />
                    </div>
                </div>
                <van-button type="primary" class="addrss_btn" color="#4c83ff" block round @click="addressBtn">保存</van-button>
                <van-popup v-model="show" position="bottom" :style="{ height: '50%' }">
                    <van-area title="选择地区"  :area-list="areaList" @confirm="areaBtn" @cancel="show=false"/>
                </van-popup>
            </van-tab>
            <van-tab title="添加国际收货地址" :disabled="disabled2">
                <van-cell-group class="input_body">
                    <div class="input_title">
                        收货人
                    </div>
                    <van-field v-model="form.name" placeholder="James" />
                </van-cell-group>
                <van-cell-group class="input_body">
                    <div class="input_title">
                        联系电话
                    </div>
                    <van-field v-model="form.phone" placeholder="+1 8039032xxx" />
                </van-cell-group>
                <van-cell-group class="input_body">
                    <div class="input_title">
                        选择国家
                    </div>
                    <van-field
                        readonly
                        clickable
                        :value="value"
                        placeholder="选择国家"
                        @click="showPicker = true"
                        />
 
                </van-cell-group>
                <van-cell-group class="input_body">
                    <div class="input_title">
                        省
                    </div>
                    <van-field v-model="form.city" placeholder="NEW YORK" />

                </van-cell-group>
                <van-cell-group class="input_body">
                    <div class="input_title">
                        市,区
                    </div>
                    <van-field v-model="form.area" placeholder="Long laland City" />

                </van-cell-group>
                
                <van-cell-group class="input_body">
                    <div class="input_title">
                        邮政编码
                    </div>
                    <van-field v-model="form.post_code" placeholder="" />

                </van-cell-group>
                <van-cell-group class="input_body">
                    <div class="input_title">
                        详细地址
                    </div>
                    <van-field v-model="form.address" placeholder="25-15 M.G.Peterosn Ave" />

                </van-cell-group>
                <div class="set_default_switch">
                    <div class="switeh_title">
                        设置为默认收货地址
                    </div>
                    <div>
                        <van-switch v-model="checked" active-color="#4c83ff" inactive-color="#dcdee0" />
                    </div>
                </div>
                <van-button type="primary" class="addrss_btn" color="#4c83ff" block round @click="addressBtn2">保存</van-button>
                <!-- 地区弹窗 -->
               <van-popup v-model="showPicker" round position="bottom">
                <van-picker
                    show-toolbar
                    :columns="countryList"
                    @cancel="showPicker = false"
                    @confirm="onConfirm"
                />
                </van-popup>
            </van-tab>
        </van-tabs>

    </div>
</template>

<script>
import { areaList } from '@vant/area-data';
import HeadTopWhite from '@/components/HeadTopWhite.vue'
export default {
    components: { HeadTopWhite },
    data() {
        return {
            checked: false, //设置为默认收货地址
            areaList,
            show:false, //地区弹窗
            region:'', //选中地区
            form:{
                province:'',
                city:'',
                area:'', //地区
                address:'', //详细地址
                phone:'', //手机号
                name:'', //姓名
                default:'',
                post_code:'' //邮编
            },
            disabled1:false,
            disabled2:false,
            type:1,
            value: '', // 国际订单选中城市
            showPicker: false,
            // columns: ['Afghanistan', 'Albania', 'Algeria', 'Austria', '湖州', '嘉兴', '金华', '衢州'],
            active:0,
            countryList:[]
        }
        
    },
    created(){
        this.token = localStorage.getItem('token');
        // let address_id = this.$route.query.address_id
        if(this.$route.query.address_id){
            this.readAddress(this.$route.query.address_id)
            
        }
        this.getCountryList()
        
        // console.log(address_id)
    },
    methods:{
        tabClick(){
            if(this.form.address_id){
                return
            }
            this.form={
                province:'',
                city:'',
                area:'', //地区
                address:'', //详细地址
                phone:'', //手机号
                name:'', //姓名
                
            }
            
            this.checked = false
            this.value = ''
        },
         onConfirm(value) {
            this.value = value;
            this.showPicker = false;
        },
        areaBtn(val){
            console.log(val)
            this.form.region = val[0].name+' '+val[1].name+' '+val[2].name
            this.form.province = val[0].name
            this.form.city = val[1].name
            this.form.area = val[2].name
            this.show = false
        },
        readAddress(address_id)
        {
            this.$request('/api/readAddress', {address_id,token:this.$store.state.token})
            .then(res => {
               console.log(res.data.data)
               let data = res.data.data
               this.form.region = data['province']+" "+data['city']+" "+data['area']
                this.form.province = data['province']
                this.form.city = data['city']
                this.form.area = data['area']
                this.form.address = data['address']
                this.form.name = data['name']
                this.form.phone = data['phone']
                this.form.address_id = data['id']
                this.value = data['province'],
                this.form.post_code = data['post_code']
                if(data.default==2){
                    this.checked = true
                }
                if(data.type==2){
                    this.active= 1
                    this.disabled1 = true
                }
                if(data.type==1){
                    this.active= 0
                    this.disabled2 = true
                }
                this.type = data['type']
            })
        },
        addressBtn(){
            if(this.checked){
                this.form.default = 2
            }
            if(!this.form.name){
                this.$toast('姓名不能为空')
                return
            }
            if(!this.form.phone){
                this.$toast('手机号不能为空')
                return
            }
            if(!this.form.region){
                this.$toast('请选择地区')
                return
            }
            if(!this.form.address){
                this.$toast('详细地址不能为空')
                return
            }
            this.form.type = 1
            this.form.token = this.$store.state.token
            this.$request('/api/addAddress', this.form)
            .then(res => {
               this.$toast('操作成功')
               this.$router.push({ path: "/address_gl", replace: true });
            })
        },
        getCountryList(){
            this.$request('/api/getCountryList', {token:this.$store.state.token})
            .then(res => {
                this.countryList = res.data.data;
            //    console.log(res.data.data)
            })
        },
        // 国际地址
        addressBtn2(){
           
            if(!this.form.name){
                this.$toast('姓名不能为空')
                return
            }
            if(!this.form.phone){
                this.$toast('手机号不能为空')
                return
            }
             if(!this.value){
                this.$toast('请选择国家')
                return
            }
            if(!this.form.city){
                this.$toast('市，区不能为空')
                return
            }
            if(!this.form.area){
                this.$toast('市，区不能为空')
                return
            }
            if(!this.form.address){
                this.$toast('详细地址不能为空')
                return
            }
            if(!this.form.post_code){
                this.$toast('邮编不能为空')
                return
            }
           
            let data = {
                name:this.form.name,
                city:this.form.city,
                address:this.form.address,
                area:this.form.area,
                type:2,
                province:this.value,
                phone:this.form.phone,
                default:'',
                post_code:this.form.post_code
              
            }
            data.token = this.$store.state.token
            if(this.form.address_id){
                data.address_id = this.form.address_id
            }
            if(this.checked){
                data.default = 2
            }
            this.$request('/api/addAddress', data)
            .then(res => {
               this.$toast('操作成功')
               this.$router.push({ path: "/address_gl", replace: true });
            })
            console.log(data)
        }
    }

}
</script>

<style src="./addressSave.css" scoped>
</style>
<style scoped>
/deep/.van-field__control {
    width: 530px;
    height: 80px;
    background: #f5f5f5;
    border-radius: 10px 10px 10px 10px;
}
/deep/.van-field__control{
    padding-left:10px;
}
/deep/ .van-tabs__line{
    background-color: #4c83ff;
}
</style>