/*
 * @Author: zhaoxingyu
 * @Date: 2020-11-19 11:32:41
 * @email: 308163697@qq.com
 * @LastEditTime: 2022-11-10 20:43:17
 * @Description: 
 */
import axios from "axios"
import qs from "qs"
import apiURL from './apiUrl.js'
import Vue from 'vue'
import { Toast } from 'vant';
Vue.use(Toast);
export function request(config,data,method="post") {
    // 创建axios实例
    const service = axios.create({
        baseURL: apiURL,
        method, 
        timeout: 10000,
        
    })
    var load = null;
    let that = this
    // 请求拦截器
    service.interceptors.request.use(
      
        config => {
         
            // data.token = this.$store.state.user.token
            config.data=qs.stringify(data)
            return config
        },

        err => {
            console.log(err)
        }
    )
   
    //响应拦截器
    service.interceptors.response.use(
        response => {
          let res = {}
          // load = Toast.clear
        //   console.log(response.data.code,'响应拦截')
          if(response.data.code==-1){
            Toast(response.data.msg);
          }
          if(response.data.code==1001){
            Toast('登陆失效，请重新登陆');
            let aff = localStorage.getItem('aff')
            localStorage.clear(); 
            // console.log(this.$route.query.aff,'======')
            localStorage.setItem('aff',aff)
            this.$router.push("/login");
            
          }
          res.status = response.status
          res.data = response.data
          return res
        },
        err => {
          console.log(err)
          Toast.clear
        }
      )
    return service(config)
}

