<template>
  <div class="coupon">
    <head-top-white title="优惠券" left_ico="1" url="-1"></head-top-white>
    <van-tabs v-model="active">
    <van-tab title="未使用">
        <div class="coupon_bg">
            <div class="content">
                <div class="left_con">
                    <span class="number">10</span>
                    <span class="unit">元</span>
                </div>
                <div class="middle_con">
                    <div class="coupon_name">新人优惠券</div>
                    <div class="coupon_type">通用券</div>
                </div>
                <div class="right_con">
                    <div class="button">
                        <van-button
                        type="primary"
                        size="small"
                        color="#ED4231"
                        plain
                        class="btn"
                        >去使用
                        </van-button>
                    </div>
                    <div class="time">12月31日过期</div>
                </div>
            </div>
        </div>
         <div class="coupon_bg">
            <div class="content">
                <div class="left_con">
                    <span class="number">10</span>
                    <span class="unit">元</span>
                </div>
                <div class="middle_con">
                    <div class="coupon_name">新人优惠券</div>
                    <div class="coupon_type">通用券</div>
                </div>
                <div class="right_con">
                    <div class="button">
                        <van-button
                        type="primary"
                        size="small"
                        color="#ED4231"
                        plain
                        class="btn"
                        >去使用
                        </van-button>
                    </div>
                    <div class="time">12月31日过期</div>
                </div>
            </div>
        </div>
    </van-tab>
    <van-tab title="已使用/过期">
        <div class="coupon_bg_grey">
            <div class="content">
                <div class="left_con">
                    <span class="number_grey">10</span>
                    <span class="unit_grey">元</span>
                </div>
                <div class="middle_con">
                    <div class="coupon_name_grey">新人优惠券</div>
                    <div class="coupon_type_grey">通用券</div>
                </div>
                <div class="right_con">
                    <div class="time_grey">12月31日过期</div>
                </div>
            </div>
        </div>
        <div class="coupon_bg_grey">
            <div class="content">
                <div class="left_con">
                    <span class="number_grey">10</span>
                    <span class="unit_grey">元</span>
                </div>
                <div class="middle_con">
                    <div class="coupon_name_grey">新人优惠券</div>
                    <div class="coupon_type_grey">通用券</div>
                </div>
                <div class="right_con">
                    <div class="time_grey">12月31日过期</div>
                </div>
            </div>
        </div>
    </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import HeadTopWhite from "@/components/HeadTopWhite.vue";
export default {
  components: {
    HeadTopWhite,
  },
};
</script>

<style src="./coupon.css" scoped>
</style>

<style scoped>
/deep/.van-tabs__line {
  width: 40px;
  height: 4px;
  background-color: #4c83ff;
}
/deep/.van-tabs__nav--line {
  width: 690px;
  margin: 0 auto;
  border-radius: 20px 20px 0px 0px;
}
/deep/.van-tab--active {
  font-size: 28px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #4c83ff;
}
/deep/.van-tab {
  font-size: 28px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
}
/deep/.van-divider {
  margin: 0 auto;
  border-color: #eee;
}
/deep/.van-button::before{
    border-radius: 6px;
}
/deep/.van-button--small{
    /* width: 120px; */
    height: 50px;
    font-size: 28px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #ED4231;
}
</style>