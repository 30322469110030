<template>
    <div class="login">
        <div class="logo">
            <img src="../../static/image/login_logo.png" alt="">
        </div>
        <div class="login_input">
            <div class="input_body">
                <img src="../../static/image/login_input_ico1.png" alt="">
                <van-field v-model="username" type="digit" placeholder="请输入手机号" />
            </div>
            <div class="input_body" v-if="loginType==1">
                <img src="../../static/image/login_input_ico2.png" alt="">
                <van-field v-model="password" type="password" placeholder="请输入密码" />
            </div>
            <div class="verification" v-if="loginType==2">
                <div class="input_body_verification">
                    <img src="../../static/image/login_ico_check.png" alt="">
                    <van-field v-model="code" type="text" placeholder="请输验证码" />
                </div>
                <div>
                    <van-button type="primary " v-if="!sendMsgDisabled" color="#4c83ff" class="get_code" @click="getCode">获取验证码</van-button>
                    <van-button type="primary " v-if="sendMsgDisabled" color="#4c83ff" class="get_code" @click="getCode">{{ sendCodeTime+'秒后获取' }}</van-button>

                </div>
            </div>
            <div class="sms_body">
                <p class="clickSmsCode" v-if="loginType==1" @click="selectLogin(2)">使用验证码登录</p>
                <p class="clickSmsCode" v-if="loginType==2" @click="selectLogin(1)">使用密码登录</p>
            </div>
            
            <div>
                <van-button type="primary" class="input_btn" color="#4c83ff" block round @click="loginBtn">登录</van-button>
                <van-button type="primary" class="input_btn" block round plain color="#4c83ff" @click="toRegister">注册新用户</van-button>
               
            </div>
             
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            username: '',
            password: '',
            sendCodeTime: 60, // 发送验证码倒计时
            sendMsgDisabled: false, //发送验证码按钮状态
            loginType:1, //1密码登录2验证码登录
            code:'' //短信验证码
        }
    },
    methods: {
        loginBtn() {
            // console.log(this.loginType)
            // return;
            
            let that = this
            if(this.loginType==1){
                that.$request('/api/login', {
                    username: that.username,
                    password: that.password
                })
                .then(res => {
                     if (res.data.code == -1) {
                        return;
                    }
                    if (res.data.code == 1) {
                        localStorage.setItem('token', res.data.data.token)
                        localStorage.setItem('uid', res.data.data.uid)
                        this.$store.commit('changeToken',res.data.data.token)
                        this.$router.push("/");
                    }

                })
            }
            if(this.loginType==2){
                that.$request('/api/login2', {
                    username: that.username,
                    code: that.code
                })
                .then(res => {
                     if (res.data.code == -1) {
                        return;
                    }
                    if (res.data.code == 1) {
                        localStorage.setItem('token', res.data.data.token)
                        localStorage.setItem('uid', res.data.data.uid)
                        this.$store.commit('changeToken',res.data.data.token)
                        this.$router.push("/");
                    }

                })
            }
            
        },
        
        toRegister(){
            this.$router.push("/register");
        },
        getCode(){
            const that = this
            if(that.sendMsgDisabled==true){
                return
            }
              that.sendMsgDisabled = true
            that.$request('/api/getSmsCode', {
                phone: that.username,
            })
                .then(res => {
                     if (res.data.code == -1) {
                        that.$toast(res.data.msg)
                        that.sendMsgDisabled = false
                    }else{
                        that.$toast('验证码发送成功');
                         const interval = window.setInterval(function() {
                        if ((that.sendCodeTime--) <= 0) {
                            that.sendCodeTime = 60
                            that.sendMsgDisabled = false
                            window.clearInterval(interval)
                            }
                        }, 1000)
                    }
                })
        },
        selectLogin(type){
            this.loginType = type
            this.password = ''
            this.code = ''
        }
    }
}
</script>
<style src="./login.css" scoped>
</style>
<style scoped>
/deep/ .van-cell {
    background-color: transparent;
}
</style>