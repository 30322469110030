<template>
    <van-tabbar
        v-model="active"
        :safe-area-inset-bottom="false"
        active-color="#ca2d2d"
        route
    >
        <van-tabbar-item to="/">
            <span>首页</span>
            <template #icon="props">
                <img :src="props.active ? icon1.active : icon1.inactive" />
            </template>
        </van-tabbar-item>
        <van-tabbar-item to="/category">
            <span>分类</span>
            <template #icon="props">
                <img :src="props.active ? icon2.active : icon2.inactive" />
            </template>
        </van-tabbar-item>
        <van-tabbar-item to="/shopCart">
            <span>购物车</span>
            <template #icon="props">
                <img :src="props.active ? icon3.active : icon3.inactive" />
            </template>
        </van-tabbar-item>
        <van-tabbar-item to="/my">
            <span>我的</span>
            <template #icon="props">
                <img :src="props.active ? icon4.active : icon4.inactive" />
            </template>
        </van-tabbar-item>
    </van-tabbar>
</template>

<script>
export default {
    name: "NavBar",
    data() {
        return {
            active: 0,
            icon1: {
                active: require("../static/image/index_ico1_select.png"),
                inactive: require("../static/image/index_ico1.png"),
            },
            icon2: {
                active: require("../static/image/index_icon2_select.png"),
                inactive: require("../static/image/index_ico2.png"),
            },
            icon3: {
                active: require("../static/image/index_ico3_select.png"),
                inactive: require("../static/image/index_ico3.png"),
            },
            icon4: {
                active: require("../static/image/index_ico4_select.png"),
                inactive: require("../static/image/index_ico4.png"),
            },
        };
    },
    methods: {

    },
};
</script>