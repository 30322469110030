<template>
    <div>
        <head-top-white title="客服" left_ico="1" url="-1"></head-top-white>
        <div class="content" id="content" >
            <div class="msg_list" v-for="(item,index) in listData">
                <div class="msg1" >
                    <div class="head"></div>
                    <div v-if="item.type==1&&item.send_uid==-1" class="msg_text_body1">
                        <div class="arrow"></div>
                        <div class="title van-hairline--bottom">
                            <div>{{item.content.title}}</div>
                            <!-- <van-divider /> -->
                            <!-- <div>点击下方字体，获取热门问题答案</div> -->
                        </div>
                        <div class="text_list" v-for="(item2,index) in item.content.subtitle">
                            <div  @click="btnTitle(item2.value)" class="sublist van-hairline--bottom">
                                <div >{{item2.title}}</div>
                                <img v-if="item2.value!=false" class="right_ico" src="../../static/image/more_icon.png" alt="">
                            </div>
                            <!-- <div class="sublist van-hairline--bottom">
                                <div>点我→包裹发货时效和发货地点</div>
                                <img class="right_ico" src="../../static/image/more_icon.png" alt="">
                            </div> -->
                        </div>
                    </div>
                    <div v-if="item.type==2&&item.send_uid==-1" class="msg_text_body1">
                        <div class="arrow"></div>
                        <div class="title van-hairline--bottom">
                            <div v-html="item.content" @click="enlargePicture($event)"></div>
                        </div>
                    </div>
                    
                </div>
                <div v-if="item.type==2&&item.send_uid==$store.state.uid" class="msg2">
                    <div class="msg_text_body2">
                        <div class="arrow"></div>
                        <div class="title">
                            <div v-html="item.content" @click="enlargePicture($event)"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="send_msg">
                <van-field v-model="sendMsgText"  />
            <van-button type="primary" round size="small" @click="sendMsg" color="#6E9DEF">发送</van-button>
        </div>
    </div>
</template>

<script>
import HeadTopWhite from "@/components/HeadTopWhite.vue";
import { ImagePreview } from 'vant';
export default {
    components: {
        HeadTopWhite,
    },
    data(){
        return{
            sendMsgText:'',
            //type 1多行文章内容 2html内容 3普通文字内容
            listData:[
                // {
                //     uid:-1,
                //     type:1,
                //     title:'您好，现在是机器人客服为您服务，请问有什么可以帮助您的？',
                //     subtitle:[
                //         {
                //             'title':'点击下方字体，获取热门问题答案',
                //             'value':false
                //         },
                //         {
                //             'title':'点我→什么时候清关',
                //             'value':'什么时候清关'
                //         },
                //         {
                //             'title':'点我→包裹发货时效和发货地点',
                //             'value':'发货时效'
                //         }
                //     ],
                //     create_time:'10-27 19:50'
                // },
                {
                    uid:-1,
                    type:2,
                    content:'<p>测试发送内容1</p>',
                    create_time:'10-27 19:50'
                },
                {
                    uid:1,
                    type:3,
                    content:'包裹发货时效',
                    create_time:'10-27 19:50'
                },
            ],
            intervalId:null //聊天定时器
        }
    },
    mounted() {
    
    },
    created(){
        this.getChatList()
        this.dataRefreh()
    },
    updated(){
       
		   this.scrollToBottom()

    },
    watch:{
        listData:()=>{
            // console.log(123)
            


        }
    },
    methods:{
        scrollToBottom(){

            this.$nextTick(()=>{
                let ele = document.getElementById('content');
                // document.body.scrollTop = ele.scrollHeight+10000
                document.documentElement.scrollTop = ele.scrollHeight+10000
                document.documentElement.scrollTop = ele.scrollHeight+10000
                document.body.scrollTop = ele.scrollHeight+10000
            })
       },

        // 预览图片
        enlargePicture(e){
            if(e.target.currentSrc){
                ImagePreview([e.target.currentSrc])
            }
            
        },
        getChatList(){
            let that = this
            that.$request('/api/getChatList', { 
                token:this.$store.state.token,
                content:this.sendMsgText,
                type:1
             })
            .then(res => {
                console.log(res.data.data.length)
                if(res.data.data.length==0){
                    let that = this
                    that.$request('/api/getContent', { 
                        token:this.$store.state.token,
                        content:'-',
                        type:2
                    })
                    .then(res => {
                        res.data.data.send_uid = -1
                        this.listData.push(res.data.data)
                    })
                }
                res.data.data.send_uid = -1
                this.listData = res.data.data

                //  let ele = document.getElementById('content');
                // ele.scrollTop = 0;
                // console.log(res.data.data)

            })
        },
        //点击发送消息按钮
        sendMsg(){
            if(!this.sendMsgText){
                return
            }
            this.addMsgList(this.sendMsgText)
            //请求服务端数据
            let that = this
            that.$request('/api/getContent', { 
                token:this.$store.state.token,
                content:this.sendMsgText,
                type:1
             })
            .then(res => {
                res.data.data.send_uid = -1
                this.listData.push(res.data.data)
            })
            this.sendMsgText = ''
        },

        //添加消息到列表
        addMsgList(text,type=3){
            let data = {
                send_uid:this.$store.state.uid,
                uid:this.$store.state.uid,
                type:2,
                content:text
            }
            this.listData.push(data)
        },
        btnTitle(value){
            if(!value){
                return
            }
            this.addMsgList(value)
            let that = this
            that.$request('/api/getContent', { 
                token:this.$store.state.token,
                content:value,
                type:1
             })
            .then(res => {
                res.data.data.send_uid = -1
                console.log(this.listData)
                this.listData.push(res.data.data)
                
                // let ele = document.getElementById('content');
            // console.log(ele.scrollHeight)

           
                // console.log(res.data.data)

            })
        },
        dataRefreh() {
            // 计时器正在进行中，退出函数
            if (this.intervalId != null) {
                return;
            }
            // 计时器为空，操作
            this.intervalId = setInterval(() => {
            this.getUnreadMessage()

            }, 4000);
        }, 
        // 停止定时器
        clear() {
            clearInterval(this.intervalId); //清除计时器
            this.intervalId = null; //设置为null
        },
        destroyed(){
            // 在页面销毁后，清除计时器
            this.clear();
        },
        
        getUnreadMessage(){
                        let that = this
            that.$request('/api/getUnreadMessage', { 
                token:this.$store.state.token,
             })
            .then(res => {
            if(res.data.code==201){
                return;
            }
            let data = {
                send_uid:-1,
                uid:this.$store.state.uid,
                type:2,
                content:res.data.data.content
            }
            this.listData.push(data)

            })
        }
  
    },
      beforeDestroy() {
        console.log('清除定时器')
            this.clear();
     
      }
}
</script>
<style src="./message.css" scoped>
</style>
<style scoped>
/deep/.send_msg .van-field__control{
    width: 530px;
    height: 80px;
    background: #FFFFFF;
    border-radius: 40px 40px 40px 40px;
    border: 1px solid #CDCDCD;
    padding-left: 30px;
}
/deep/.send_msg .van-field__body{
    
}
/deep/.send_msg .van-cell{
    padding: 0px 0 0 30px !important;
    width: 570px !important;
    
}
/deep/.van-button{
    width: 140px;
    height: 80px;
    margin-left: 20px;
}
/deep/ img{
    max-width: 480px;
}
/deep/ p{
    margin: 0px;
}
/deep/ img{
    margin: 10px 0px;
}


</style>