import { render, staticRenderFns } from "./activity3.vue?vue&type=template&id=976e529e&scoped=true"
import script from "./activity3.vue?vue&type=script&lang=js"
export * from "./activity3.vue?vue&type=script&lang=js"
import style0 from "./activity3.css?vue&type=style&index=0&id=976e529e&prod&scoped=true&lang=css&external"
import style1 from "./activity3.vue?vue&type=style&index=1&id=976e529e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "976e529e",
  null
  
)

export default component.exports