<template>
    <div>
        <head-top title="天成转运" left_ico="1" url="/" />
        <div class="card_white content">
            <div class="input">
                <van-cell-group>
                    <van-field class="border_radius" value="中国" :readonly="true" label="目的地：" placeholder="请输入兑换金额" />
                </van-cell-group>
                <van-cell-group>
                    <van-field class="border_radius" v-model="weight" label="货物重量：" placeholder="请输入货物重量（单位g）" />
                </van-cell-group>
                <van-cell-group>
                    <van-field class="border_radius" v-model="money" label="计算结果："  readonly="true"  />
                </van-cell-group>
                <van-button class="btn" type="primary" block color="#4c83ff" @click="jpyToCny">计算</van-button>
            </div>

            <!-- <div class="calculation">
                <van-row class="head">
                    <van-col span="6">渠道</van-col>
                    <van-col span="6">运费</van-col>
                    <van-col span="6">手续费</van-col>
                    <van-col span="6">合计</van-col>
                </van-row>
                <van-row class="text">
                    <van-col span="6">渠道一</van-col>
                    <van-col span="6">100</van-col>
                    <van-col span="6">0</van-col>
                    <van-col span="6">100</van-col>
                </van-row>
            </div> -->
        </div>

        <div class="note card_white">
            <div class="title">
                各种运输渠道说明
            </div>
        </div>
    </div>
</template>

<script>
import HeadTop from '@/components/HeadTop.vue'

export default {
    components: { HeadTop },
    data() {
        return {
            weight: '',
            money:'',
        }
    },
    methods:{
        jpyToCny(){
            let that = this
            that.$request('/api/freight', { weight: this.weight })
                .then(res => {
                    this.money = res.data.data+'RMB'
                })
        }
    }
}
</script>
<style src="./tczy.css" scoped>
</style>
<style scoped>
/deep/.van-field__control {
    height: 90px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    opacity: 0.9;
    border: 1px solid #dddddd;
    padding-left: 20px;
    font-size: 30px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #000000;
}
/deep/.van-cell__title {
    line-height: 90px;
    font-size: 30px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #000000;
}
/deep/.van-button {
    width: 91%;
    margin: 0 auto;
}
</style>