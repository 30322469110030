<template>
  <div>
    <head-top title="积分" left_ico="1"></head-top>
    <div class="jf_bg">
      <div class="above_integral">
        <div class="now_integral">当前积分</div>
        <div class="now_integral_number">0</div>
      </div>
      <div class="below_integral">
        <div>
          <div class="integral_number">1100</div>
          <div class="integral">累计积分</div>
        </div>
        <div>
          <div class="integral_number">20000</div>
          <div class="integral">累计消费</div>
        </div>
        <div>
          <div class="integral_number">0</div>
          <div class="integral">冻结积分</div>
        </div>
      </div>
    </div>
    <van-tabs v-model="active" color="#4c83ff">
      <van-tab title="积分明细">
        <van-divider />
        <div class="list">
          <!-- <div class="list_list">
            <div class="content">下单赠送10积分</div>
            <div class="number_jia">+10</div>
          </div> -->
          <div class="list_list">
            <div class="content">购买商品使用100积分抵扣100元</div>
            <div class="number_jian">-100</div>
          </div>
          <div class="list_list">
            <div class="content">下单赠送10积分</div>
            <div class="number_jia">+100</div>
          </div>
        </div>
      </van-tab>
      <van-tab title="提升积分">
        <van-divider />
        <div class="bg">
          <div class="ts_jf_bg">
            <img src="../../static/image/ts_jf_bg.png" />
            <van-button
              type="primary"
              size="small"
              color="#C8A86B"
              round
              plain
              class="btn"
              >赚积分</van-button
            >
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import HeadTop from "@/components/HeadTop.vue";
export default {
  components: {
    HeadTop,
  },
};
</script>
<style src="./integral.css" scoped>
</style>
<style scoped>
/deep/.van-tabs__line {
  width: 40px;
  height: 4px;
}
/deep/.van-tabs__nav--line {
  width: 690px;
  margin: 0 auto;
  border-radius: 20px 20px 0px 0px;
}
/deep/.van-tab--active {
  font-size: 28px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #4c83ff;
}
/deep/.van-tab {
  font-size: 28px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
}
/deep/.van-divider {
  margin: 0 auto;
  border-color: #eee;
}
/deep/.van-button--small {
  width: 110px;
  height: 50px;
  font-size: 24px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #c8a86b;
  position: absolute;
  right: 80px;
  top: 190px;
}
/deep/.van-divider {
  width: 690px;
}
</style>