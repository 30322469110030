<!--  -->
<template>
  <div>
    <head-top-white title="日品汇运费、税费规则" left_ico="1" url="-1"></head-top-white>
    <div class="content" v-html="content"></div>
  </div>
</template>

<script>
import HeadTopWhite from "@/components/HeadTopWhite.vue";
export default {
  components: {
    HeadTopWhite,
  },
  data() {
    return {
      content: "",
    };
  },

  created() {
    this.articleDetails();
  },

  methods: {
    articleDetails() {
      this.$request("/api/articleDetails", { article_id: 31 }).then((res) => {
        this.content = res.data.data.content;
        console.log(res.data.data);
      });
    },
  },
};
</script>
<style  scoped>
.content {
  padding: 30px;
  margin-top: 100px;
  margin-bottom: 100px;
}
</style>