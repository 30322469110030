<!--  -->
<template>
  <div class="feed_list_body">
    <head-top-white title="商品评价" left_ico="1" :url="-1"></head-top-white>
    <div class="list_body ">
      <div class="mt20 feedList"  v-for="(item,index) in goodsFeedback">
        <div class="user_info">
        <div class="user_name">{{item.username}}</div>
        <div class="time">{{item.create_time}}</div>
      </div>
      <div class="text text_rows2">
        <span>{{item.content}}</span>
      </div>
      <div :class="item.img_count>1?'evaluate_img':'evaluate_one_img'">
        <!-- <img
          v-for="(item2, index) in item.img_url"
          v-if="item2"
          v-lazy="$host + item2"
          alt=""
        /> -->
        <van-image
          @click="imagePreview(item.img_url)"
          v-for="(item2, index) in item.img_url"
          v-if="item2"
          fit="cover"
          lazy-load
          :src="$imgUrl + item2"
        />
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeadTopWhite from "../../components/HeadTopWhite.vue";
import { ImagePreview } from 'vant';
export default {
  components: { HeadTopWhite },
  data() {
    return {
      goodsFeedback:[]//评价列表
    };
  },

  created() {
    this.getGoodsFeedback()
  },

  methods: {
    imagePreview(imgList){
      let img = [];
     
      imgList.forEach(e => {
        img.push(this.$imgUrl+e)
      });
       console.log(img)
      ImagePreview(img);
    },
    getGoodsFeedback(){
      let that = this
      this.$request('/api/getGoodsFeedback', {
          goods_id: this.$route.query.goodsId,
      })
      .then(res => {
          this.goodsFeedback = res.data.data.list
      })
    },
  },
};
</script>
<style src="./goodsFeedList.css" scoped>
</style>
<style scoped>

</style>