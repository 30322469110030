import { render, staticRenderFns } from "./message.vue?vue&type=template&id=321c11b7&scoped=true"
import script from "./message.vue?vue&type=script&lang=js"
export * from "./message.vue?vue&type=script&lang=js"
import style0 from "./message.css?vue&type=style&index=0&id=321c11b7&prod&scoped=true&lang=css&external"
import style1 from "./message.vue?vue&type=style&index=1&id=321c11b7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "321c11b7",
  null
  
)

export default component.exports