<!--  -->
<template>
  <div>
    <head-top-white :title="article.title" left_ico="1" url="-1"></head-top-white>
    <div class="content" v-html="article.content"></div>
  </div>
</template>

<script>
import HeadTopWhite from "@/components/HeadTopWhite.vue";
export default {
  components: {
    HeadTopWhite,
  },
  data() {
    return {
      article: "",
    };
  },

  created() {
    this.articleDetails();
  },

  methods: {
    articleDetails() {
      this.$request("/api/articleDetails", { article_id: this.$route.query.articleId }).then((res) => {
        this.article = res.data.data;
      });
    },
  },
};
</script>
<style  scoped>
.content {
  /* padding: 30px; */
  /* margin-top: 100px; */
  margin-bottom: 100px;
}
</style>