import Vue from 'vue';
import { Button,Tabbar, TabbarItem,Search,Swipe, SwipeItem,Lazyload,NoticeBar,Tag,Col
    , Row ,Tab, Tabs,Divider,Field,Overlay,Sidebar, SidebarItem,Checkbox, CheckboxGroup,Stepper
    ,Step, Steps,RadioGroup, Radio,GoodsAction, GoodsActionIcon, GoodsActionButton,Image as VanImage,Popup,Toast,Switch
,Area,Dialog,Picker,Progress,Loading,List,Collapse,CollapseItem,Icon,Uploader,Rate,Badge} from 'vant';
Vue.use(Button).use(Tabbar).use(TabbarItem).use(Search).use(Swipe).use(SwipeItem).use(Lazyload)
.use(NoticeBar).use(Tag).use(Col).use(Row).use(Tab).use(Tabs).use(Divider).use(Field).use(Overlay)
.use(Sidebar).use(SidebarItem).use(Checkbox).use(CheckboxGroup).use(Stepper).use(Step).use(Steps)
.use(RadioGroup).use(Radio).use(GoodsAction).use(GoodsActionIcon).use(GoodsActionButton).use(VanImage)
.use(Popup).use(Toast).use(Switch).use(Area).use(Dialog).use(Picker).use(Progress).use(Loading).use(List)
.use(Collapse).use(CollapseItem).use(Icon).use(Uploader).use(Rate).use(Badge);




