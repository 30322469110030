<template>
    <div>
        <head-top title="我的收藏" left_ico="1"></head-top>
        <div class="top card_white mt30">
            <span class="left_text">当前共<span class="number">{{collectionList.count}}</span>件商品</span>
            <span class="right_text" @click="operation()">管理</span>
        </div>
        <div v-for="(item,index) in collectionList.list" class="card_white details mt20">
            <van-row>
                <van-col span="3" v-if="updateVal">
                    <van-checkbox class="checkbox" v-model="item.click" checked-color="#4c83ff"></van-checkbox>
                </van-col>
                <van-col span="21" class="goods_body" @click="toGoods(item.id)">
                    <div class="details_left">
                        <img :src="$imgUrl+item.img_url" alt="" />
                    </div>
                    <div class="details_right">
                        <div class="goods_title text_rows2">{{item.title}}</div>
                        <div class="unit_price">{{item.price}}日元</div>
                    </div>
                </van-col>
            </van-row>

        </div>
        <!-- 底部操作按钮 -->
        <div class="place_order" v-if="updateVal">
            <van-row>
                <van-col span="15">
                    <van-checkbox class="box" v-model="checkedAll" checked-color="#4c83ff" @click="selectAll">全选</van-checkbox>
                    <!-- <img src="../../static/image/shop_cart.png" alt="" /> -->
                </van-col>
                <van-col span="4">
                    <!-- <van-button type="primary" size="small" color="#4c83ff" round plain class="btn">收藏</van-button> -->
                </van-col>
                <van-col span="5">
                    <van-button type="primary" size="small" color="#999999" round plain class="btn" @click="delShopCart">删除</van-button>
                </van-col>
            </van-row>
        </div>

    </div>
</template>

<script>
import HeadTop from "@/components/HeadTop";


export default {
    components: {
        HeadTop
    },
    data() {
        return {
            collectionList: {},
            checkedAll: false,//全选
            updateVal: false, //管理开关
        }
    },
    created() {
        this.token = localStorage.getItem('token');
        this.getList()
    },
    watch: {
        collectionList: {
            handler(val) {
                //   console.log(val)
                // // this.updateShopCart(val)
            },
            deep: true
        },
    },
    methods: {
        getList() {
            let that = this
            that.$request('/api/collectionList', { token: this.$store.state.token })
                .then(res => {
                    for (let k in res.data.data.list) {
                        res.data.data.list[k]['click'] = false
                    }
                    that.collectionList = res.data.data
                })

        },
        operation(){
            this.updateVal = !this.updateVal
        },
        toGoods(goodsId) {
            this.$router.push({path:"/goods","query":{goodsId}})
        },
        //全选
        selectAll() {
            for (let k in this.collectionList['list']) {
                this.collectionList['list'][k]['click'] = this.checkedAll
            }
        },
        delShopCart() {
            let idArr = []
            for (let k in this.collectionList['list']) {
                if (this.collectionList['list'][k]['click'] == true) {
                    idArr.push(this.collectionList['list'][k].id)
                }

            }
            this.$request('/api/delCollection', {
                token: this.$store.state.token,
                idArr
            })
            .then(res => {
                this.$toast('删除成功')
                this.getList()
            })


        }
    }
}
</script>

<style src="./myCollection.css" scoped>
</style>
<style scoped>
</style>